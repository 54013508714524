import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {ForgotPassService} from './forgot-pass.service';
import {FormBuilder, FormControl} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {baseUrl} from '../../../url.model';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
	selector: 'kt-forgot-pass',
	templateUrl: './forgot-pass.component.html',
	styleUrls: ['./forgot-pass.component.scss']
})
export class ForgotPassComponent implements OnInit {
	success: boolean = false;
	email = new FormControl();


	constructor(private spinner: NgxSpinnerService,private http: HttpClient,private toastr: ToastrService, private fb: FormBuilder,public activeModal: NgbActiveModal, private service: ForgotPassService,private modalService: NgbModal) {
	}

	ngOnInit() {
	}

	forgotPass() {
		this.spinner.show();
		const formData = {
			email: this.email.value
		};
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type':  'application/json',
			})
		};
		this.http.post<any>(baseUrl + 'forgottenPassword', formData, {headers: new HttpHeaders({'Content-Type': 'application/json'})}).subscribe((res) => {
				this.success = true;
			this.spinner.hide();
			}, error => {
			this.spinner.hide();
				this.toastr.error(error, '');
			}
		);

	}
}
