import {Component, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {FormGroup} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'kt-invites-f',
	templateUrl: './invites-f.component.html',
	styleUrls: ['./invites-f.component.scss']
})
export class InvitesFComponent implements OnInit {
	form: FormGroup;
	uploadResponse$ = new BehaviorSubject<{ status: string, message: string }>(null);

	constructor(public activeModal: NgbActiveModal) {
	}

	ngOnInit() {
	}

	onFileChange(event) {
		if (event.target.files.length > 0) {
			const file = event.target.files[0];
			this.form.get('avatar').setValue(file);
		}
	}
}
