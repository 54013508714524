import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {baseUrl} from '../url.model';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class OrganisationService {

	constructor(private http: HttpClient) {

	}

	getOrganisations(params) {
		return this.http.get<any>(baseUrl + 'organisation', {params: params});
	}

	setCurrentOrganisation(formdata): Observable<any>{
		return this.http.post<any>(baseUrl + 'users/setCurrentOrganisation ', formdata);
	}

	getCurrentOrganisation(): Observable<any>{
		return this.http.get<any>(baseUrl + 'users/getCurrentOrganisation');
	}
	getavailableOrgs(): Observable<any>{
		return this.http.get<any>(baseUrl+'organisation/availableOrgs');
	}

	getOrganisation(id): Observable<any> {
		return this.http.get<any>(baseUrl + 'organisation/' + id);
	}

	newOrganisation(formdata): Observable<any> {
		return this.http.post<any>(baseUrl + 'organisation ', formdata);
	}

	deleteOrganisation(id): Observable<any> {
		return this.http.delete<any>(baseUrl + 'organisation/' + id, {observe: 'response'});
	}

	modifyOrganisation(id: number, formdata: any) {
		return this.http.put<any>(baseUrl + 'organisation/' + id, formdata, {observe: 'response'});
	}

}
