import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog, MatTableDataSource } from "@angular/material";
import { ToastrService } from "ngx-toastr";
import { ConfirmationDialog } from "../confirmation-dialog/confirmation-dialog.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { InvitedService } from "./invited.service";
import { ActivateModalComponent } from "./activate-modal/activate-modal.component";
import { Router } from "@angular/router";
import { NicknameComponent } from "./nickname/nickname.component";
import { NewVoucherComponent } from "../vouchers/new-voucher/new-voucher.component";
import { SelectionModel } from "@angular/cdk/collections";
import {HttpParams} from '@angular/common/http';
import {debounceTime} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

@Component({
	selector: "kt-invited",
	templateUrl: "./invited.component.html",
	styleUrls: ["./invited.component.scss"]
})
export class InvitedComponent implements OnInit {
	search = new FormControl();
	dataSource = new MatTableDataSource<any>();
	displayedColumns = [
		"select",
		"name",
		"userrole",
		"firstname",
		"lastname",
		"eventName",
		"inviterPerson",
		"nickName",
		"actions"
	];
	pageSize = 10;
	page = 1;
	nev = "";
	collection: number;
	rendezesirany = "asc";
	selection: SelectionModel<any>;

	constructor(
		private router: Router,
		private dialog: MatDialog,
		private translateService: TranslateService,
		private toastr: ToastrService,
		private confirmationDialogService: ConfirmationDialog,
		private modalService: NgbModal,
		private service: InvitedService,
		private changeDetector: ChangeDetectorRef
	) {}

	ngOnInit() {
		this.search.valueChanges.pipe(debounceTime(500)).subscribe(str=>
			this.getData());
		this.getData();
		const initialSelection = [];
		const allowMultiSelect = true;
		this.selection = new SelectionModel<any>(
			allowMultiSelect,
			initialSelection
		);
	}
	lapozas(event) {
		this.page = event;
		this.getData();
	}
	ngAfterViewChecked() {
		this.changeDetector.detectChanges();
	}

	activate(id) {
		const dialogRef = this.dialog.open(ActivateModalComponent, {
			width: "500px"
		});
		dialogRef.componentInstance.id = id;
		dialogRef.afterClosed().subscribe(result => {
			this.getData();
		});
	}

	test(element) {
		this.router.navigateByUrl(`/activate/${element.id}`, {
			state: { data: element }
		});
	}

	refuse(id) {
		const data = {
			invitationId: id
		};

		this.confirmationDialogService
			.confirm(
				this.translateService.instant('MESSAGES.CONFIRMREJECTION'),
				this.translateService.instant('MESSAGES.REJECTUSER')
			)
			.then(confirmed => {
				if (confirmed) {
					this.service.refuseUser(data).subscribe(
						res => {
							this.toastr.success(
								this.translateService.instant('MESSAGES.SUCCESSREJECT'),
								""
							);
							this.getData();
						},
						error => {
							this.toastr.error(error.error.message, "");
						}
					);
				} else {
					this.toastr.warning(this.translateService.instant('MESSAGES.DISSMISS'), '');
					this.getData();
				}
			});
	}

	activateSelected() {
		if (
			this.selection.selected.every(
				item => item.eventId === this.selection.selected[0].eventId
			)
		) {
			this.router.navigateByUrl("/activate/selected", {
				state: { selection: this.selection }
			});
		} else {
			this.toastr.error(
				this.translateService.instant('MESSAGES.INVITATIONSMUSTBE'),
				"",
				{ timeOut: 1000 }
			);
		}
	}
	setNickName(id, nickName?) {
		const modalRef = this.modalService.open(NicknameComponent, {
			size: 'lg',
			backdrop  : 'static',
			keyboard  : false
		});
		modalRef.componentInstance.id = id;
		modalRef.result.then(() => {
			this.getData();
		}).catch(() => {
		});

	}

	getData() {
		let params = new HttpParams()
			.append('oldal', `${this.page - 1}`).append('meret', `${this.pageSize}`);
		if (this.search.value && this.search.value.length) {
			params = params.append('szoveg', this.search.value);
		}
		this.service.getInv(params).subscribe((res) => {
			this.collection = res.osszes;
			this.dataSource.data = res.elemek;
		});
	}
	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource.data.length;
		return numSelected == numRows;
	}

	masterToggle() {
		this.isAllSelected()
			? this.selection.clear()
			: this.dataSource.data.forEach(row => this.selection.select(row));
	}
}
