import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, Validators} from '@angular/forms';
import {MailTemplateService} from './mail-template.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
	selector: 'kt-mail-template',
	templateUrl: './mail-template.component.html',
	styleUrls: ['./mail-template.component.scss']
})
export class MailTemplateComponent implements OnInit {
	formreg = this.fb.group({
		header: ['', Validators.required],
		body: ['', Validators.required]
	});
	forma = this.fb.group({
		header: ['', Validators.required],
		body: ['', Validators.required]
	});
	formb = this.fb.group({
		header: ['', Validators.required],
		body: ['', Validators.required]
	});
	formpark = this.fb.group({
		header: ['', Validators.required],
		body: ['', Validators.required]
	});
	formforg = this.fb.group({
		header: ['', Validators.required],
		body: ['', Validators.required]
	})
	formdenied = this.fb.group({
		header: ['', Validators.required],
		body: ['', Validators.required]
	})
	formWrongProfile= this.fb.group({
		header: ['', Validators.required],
		body: ['', Validators.required]
	})
	formpassGenerator= this.fb.group({
		header: ['', Validators.required],
		body: ['', Validators.required]
	})

	constructor(private translateService: TranslateService,private toastr: ToastrService,private fb: FormBuilder, public activeModal: NgbActiveModal, private mailService: MailTemplateService) {
	}

	ngOnInit() {
		this.getData();
	}

	getData() {
		this.mailService.getTemplate().subscribe((res) => {
			console.log(res)
			this.formreg.setValue({
				header: res.reg.header,
				body: res.reg.body
			});
			this.forma.setValue({
				header: res.accept.header,
				body: res.accept.body
			});
			this.formb.setValue({
				header: res.alreadyMember.header,
				body: res.alreadyMember.body
			});
			this.formpark.setValue({
				header: res.parking.header,
				body: res.parking.body
			});
			this.formforg.setValue({
				header: res.forgottenPassword.header,
				body: res.forgottenPassword.body
			});
			this.formdenied.setValue({
				header: res.deniedUser.header,
				body: res.deniedUser.body
			});
			this.formWrongProfile.setValue({
				header: res.wrongProfile.header,
				body: res.wrongProfile.body
			});
			this.formpassGenerator.setValue({
				header: res.passGenerator.header,
				body: res.passGenerator.body
			});
		});
	}

	save(item?) {
		if (item=='reg') {
			this.mailService.putTemplate(1, this.formreg.getRawValue()).subscribe((res) => {
				if(res.status==200){
					this.toastr.success(this.translateService.instant('MESSAGES.REGTEMPLATE'),'');
					this.activeModal.close(true);
				}
				else{
					this.toastr.error(this.translateService.instant('MESSAGES.ERRORSAVING'),'');
				}
			});
		}
		if (item=='tag') {
			this.mailService.putTemplate(3,this.formb.getRawValue()).subscribe((res)=>{
				if(res.status==200){
					this.toastr.success(this.translateService.instant('MESSAGES.CONFIRMTEMPLATE'),'');
					this.activeModal.close(true);
				}
				else{
					this.toastr.error(this.translateService.instant('MESSAGES.ERRORSAVING'),'');
				}
			})
		}
		if(item=='vissz'){
			this.mailService.putTemplate(2,this.forma.getRawValue()).subscribe((res)=>{
				if(res.status==200){
					this.toastr.success(this.translateService.instant('MESSAGES.CONFIRMTEMPLATE'),'');
					this.activeModal.close(true);
				}
				else{
					this.toastr.error(this.translateService.instant('MESSAGES.ERRORSAVING'),'');
				}
			})
		}
		if(item=='parking'){
			this.mailService.putTemplate(4,this.formpark.getRawValue()).subscribe((res)=>{
				if(res.status==200){
					this.toastr.success(this.translateService.instant('MESSAGES.CONFIRMTEMPLATE'),'');
					this.activeModal.close(true);
				}
				else{
					this.toastr.error(this.translateService.instant('MESSAGES.ERRORSAVING'),'');
				}
			})
		}
		if(item=='forgot'){
			this.mailService.putTemplate(5,this.formforg.getRawValue()).subscribe((res)=>{
				if(res.status==200){
					this.toastr.success(this.translateService.instant('MESSAGES.CONFIRMTEMPLATE'),'');
					this.activeModal.close(true);
				}
				else{
					this.toastr.error(this.translateService.instant('MESSAGES.ERRORSAVING'),'');
				}
			})
		}

		if(item=='denied'){
			this.mailService.putTemplate(6,this.formdenied.getRawValue()).subscribe((res)=>{
				if(res.status==200){
					this.toastr.success(this.translateService.instant('MESSAGES.CONFIRMTEMPLATE'),'');
					this.activeModal.close(true);
				}
				else{
					this.toastr.error(this.translateService.instant('MESSAGES.ERRORSAVING'),'');
				}
			})
		}
		if(item=='wrongProfile'){
			this.mailService.putTemplate(7,this.formWrongProfile.getRawValue()).subscribe((res)=>{
				if(res.status==200){
					this.toastr.success(this.translateService.instant('MESSAGES.CONFIRMTEMPLATE'),'');
					this.activeModal.close(true);
				}
				else{
					this.toastr.error(this.translateService.instant('MESSAGES.ERRORSAVING'),'');
				}
			})
		}
		if(item=='passGenerator'){
			this.mailService.putTemplate(8,this.formpassGenerator.getRawValue()).subscribe((res)=>{
				if(res.status==200){
					this.toastr.success(this.translateService.instant('MESSAGES.CONFIRMTEMPLATE'),'');
					this.activeModal.close(true);
				}
				else{
					this.toastr.error(this.translateService.instant('MESSAGES.ERRORSAVING'),'');
				}
			})
		}
	}
}
