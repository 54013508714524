import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {ArmbandService} from '../armband.service';

@Component({
	selector: 'kt-newandedit-armband',
	templateUrl: './newandedit-armband.component.html',
	styleUrls: ['./newandedit-armband.component.scss']
})
export class NewandeditArmbandComponent implements OnInit {
	@Input() public id;
	colorArmband;
	form = this.fb.group({
		name: ['', Validators.required],
		colorCode: ['', Validators.required],
		password:['']
	});

	constructor(private service: ArmbandService, public activeModal: NgbActiveModal, private toastr: ToastrService, private fb: FormBuilder) {
	}

	ngOnInit() {
		if (this.id) {
			this.service.getArmband(this.id).subscribe(resp => {
				this.form.setValue({
					name: resp.name,
					colorCode: resp.colorCode,
					password: '',
				});
				this.colorArmband = resp.colorCode;
			});
		}
	}

	setColorArmband(event){
		this.form.controls['colorCode'].setValue(event);
	}

	save() {
		if (!this.id) {
			this.service.newArmband(this.form.getRawValue()).subscribe((res) => {
				if (res.status == 200) {
					this.toastr.success('A karszalagot sikeresen létrehozta', '');
					this.activeModal.close(true);
				} else {
					this.toastr.error('Hiba létrehozás közben', 'Hiba');
				}
			}, (error) => {
				this.toastr.error(error.error.message, 'Hiba');
			});
		} else {
			this.service.modifyEvent(this.id, this.form.getRawValue()).subscribe((res) => {
					if (res.status == 200) {
						this.toastr.success('A karszalagot sikeresen frissítette', '');
						this.activeModal.close(true);
					} else {
						this.toastr.error('Hiba történt!', '');
					}
				}, (error => {
					this.toastr.error(error.error.message, 'Hiba');
				})
			);
		}
	}

}
