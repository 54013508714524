import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostListener,
	OnInit,
	ViewChild
} from '@angular/core';
import {
	AbstractControl,
	FormBuilder,
	FormGroup,
	ValidationErrors,
	Validators
} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {baseUrl} from '../url.model';
import {Location} from '@angular/common';
import {ToastrService} from 'ngx-toastr';
import moment from 'moment';
import {AuthService} from '../../../core/auth/_services';
import {NgxPermissionsService} from 'ngx-permissions';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {
	CropperDialogComponent,
	dataURLtoFile
} from '../cropper-dialog/cropper-dialog.component';
import * as jwt_decode from 'jwt-decode';
import {TranslateService} from '@ngx-translate/core';
import {TranslationService} from '../../../core/_base/layout';
import {NgxSpinnerService} from 'ngx-spinner';

const url = '/assets/media/avatar.png';
const styles = theme => ({
	'@global': {
		body: {
			backgroundColor: theme.background.default,
			color: theme.text.default,
			fontFamily: theme.typography.fontFamily,
			margin: '1em'
		}
	},
	cropping: {
		maxWidth: '400px',
		height: '300px'
	},
	icon: {
		marginEnd: '.25em'
	}
});

@Component({
	selector: 'kt-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
	@ViewChild('imagee', null) imagee: ElementRef;
	form: FormGroup;
	form2: FormGroup;
	// nationals: string[] = [
	// 	'Afghan', 'Albanian', 'Algerian', 'Argentine', 'Argentinian', 'Australian', 'Austrian', 'Bangladeshi', 'Belgian',
	// 	'Bolivian', 'Batswana', 'Brazilian', 'Bulgarian', 'Cambodian', 'Cameroonian', 'Canadian', 'Chilean', 'Chinese',
	// 	'Colombian', 'Costa Rican', 'Croatian', 'Cuban', 'Czech', 'Danish', 'Dominican', 'Ecuadorian', 'Egyptian',
	// 	'Salvadorian', 'English', 'Estonian', 'Ethiopian', 'Fijian', 'Finnish', 'French', 'German', 'Ghanaian',
	// 	'Greek', 'Guatemalan', 'Haitian', 'Honduran', 'Magyar', 'Icelandic', 'Indian', 'Indonesian', 'Iranian',
	// 	'Iraqi', 'Irish', 'Israeli', 'Italian', 'Jamaican', 'Japanese', 'Jordanian', 'Kenyan', 'Kuwaiti',
	// 	'Lao', 'Latvian', 'Lebanese', 'Libyan', 'Lithuanian', 'Malagasy', 'Malaysian', 'Malian', 'Maltese',
	// 	'Mexican', 'Mongolian', 'Moroccan', 'Mozambican', 'Namibian', 'Nepalese', 'Dutch', 'New Zealand', 'Nicaraguan',
	// 	'Nigerian', 'Norwegian', 'Pakistani', 'Panamanian', 'Paraguayan', 'Peruvian', 'Philippine', 'Polish',
	// 	'Portuguese', 'Romanian', 'Russian', 'Saudi', 'Scottish', 'Senegalese', 'Serbian', 'Singaporean', 'Slovak',
	// 	'South African', 'Korean', 'Spanish', 'Sri Lankan', 'Sudanese', 'Swedish', 'Swiss', 'Syrian', 'Taiwanese',
	// 	'Tajikistani', 'Thai', 'Tongan', 'Tunisian', 'Turkish', 'Ukrainian', 'Emirati', 'British', 'American', 'Uruguayan',
	// 	'Venezuelan', 'Vietnamese', 'Welsh', 'Zambian', 'Zimbabwean',
	// ];
	nationals: string[] = [
		'Afghan',
		'Albanian',
		'Algerian',
		'American',
		'Andorran',
		'Angolan',
		'Antiguans and Barbudan',
		'Argentine',
		'Armenian',
		'Aruban',
		'Australian',
		'Austrian',
		'Azerbaijani',
		'Bahamian',
		'Bahraini',
		'Bangladeshis',
		'Barbadian',
		'Basques',
		'Belarusian',
		'Belgian',
		'Belizean',
		'Beninese',
		'Bermudian',
		'Bhutanese',
		'Bolivian',
		'Bosniak',
		'Bosnians and Herzegovinian',
		'Botswana',
		'Brazilian',
		'Breton',
		'British',
		'British Virgin Islander',
		'Bruneian',
		'Bulgarian',
		'Macedonian Bulgarian',
		'Burkinabés',
		'Burmese',
		'Burundian',
		'Cambodian',
		'Cameroonian',
		'Canadian',
		'Catalan',
		'Cape Verdean',
		'Chaldean',
		'Chadian',
		'Chilean',
		'Chinese',
		'Colombian',
		'Comorian',
		'Congolese (DRC)',
		'Congolese (RotC)',
		'Costa Rican',
		'Croat',
		'Cuban',
		'Cypriot',
		'Czech',
		'Danes',
		'Greenlander',
		'Djiboutian',
		'Dominican (Commonwealth)',
		'Dominican (Republic)',
		'Dutch',
		'East Timorese',
		'Ecuadorian',
		'Egyptian',
		'Emiratis',
		'English',
		'Equatoguineans',
		'Eritrean',
		'Estonian',
		'Ethiopian',
		'Falkland Islander',
		'Faroese',
		'Fijian',
		'Finn',
		'Finnish Swedish',
		'Filipino',
		'French',
		'Gabonese',
		'Gambian',
		'Georgian',
		'German',
		'Baltic German',
		'Ghanaian',
		'Gibraltarian',
		'Greek',
		'Greek Macedonian',
		'Grenadian',
		'Guatemalan',
		'Guianese (French)',
		'Guinean',
		'Guinea-Bissau national',
		'Guyanese',
		'Haitian',
		'Honduran',
		'Hong Konger',
		'Icelander',
		'I-Kiribati',
		'Indian',
		'Indonesian',
		'Iranian',
		'Iraqis',
		'Irish',
		'Israel',
		'Italian',
		'Ivoirian',
		'Jamaican',
		'Japanese',
		'Jordanian',
		'Kazakh',
		'Kenyan',
		'Korean',
		'Kosovar',
		'Kuwaitis',
		'Kyrgyz',
		'Lao',
		'Latvian',
		'Lebanese',
		'Liberian',
		'Libyans',
		'Liechtensteiners',
		'Lithuanian',
		'Luxembourger',
		'Macao',
		'Macedonian',
		'Malagasy',
		'Malawian',
		'Malaysian',
		'Maldivian',
		'Malian',
		'Maltese',
		'Manx',
		'Magyar',
		'Marshallese',
		'Mauritanian',
		'Mauritian',
		'Mexican',
		'Micronesian',
		'Moldovan',
		'Monégasque',
		'Mongolian',
		'Montenegrin',
		'Moroccan',
		'Mozambican',
		'Namibian',
		'Nauruan',
		'Nepalese',
		'New Zealander',
		'Nicaraguan',
		'Nigerien',
		'Nigerian',
		'Norwegian',
		'Omani',
		'Pakistanis',
		'Palauan',
		'Palestinian',
		'Panamanian',
		'Papua New Guinean',
		'Paraguayan',
		'Peruvian',
		'Poles',
		'Portuguese',
		'Puerto Rican',
		'Qatari',
		'Quebecer',
		'Réunionnai',
		'Romanian',
		'Russian',
		'Baltic Russian',
		'Rwandan',
		'Saint Kitts and Nevis',
		'Saint Lucian',
		'Salvadoran',
		'Sammarinese',
		'Samoan',
		'São Tomé and Príncipe',
		'Saudi',
		'Scots',
		'Senegalese',
		'Serb',
		'Seychelloi',
		'Sierra Leonean',
		'Singaporean',
		'Slovak',
		'Slovenes',
		'Solomon Islander',
		'Somali',
		'Somalilander',
		'Sotho',
		'South African',
		'Spaniard',
		'Sri Lankan',
		'Sudanese',
		'Surinamese',
		'Swazi',
		'Swedes',
		'Swiss',
		'Syriac',
		'Syrian',
		'Taiwanese',
		'Tamil',
		'Tajik',
		'Tanzanian',
		'Thai',
		'Tibetan',
		'Tobagonian',
		'Togolese',
		'Tongan',
		'Trinidadian',
		'Tunisian',
		'Turk',
		'Tuvaluans',
		'Ugandan',
		'Ukrainian',
		'Uruguayan',
		'Uzbek',
		'Vanuatuan',
		'Venezuelan',
		'Vietnamese',
		'Vincentian',
		'Welsh',
		'Yemeni',
		'Zambian',
		'Zimbabwean',

	];
	inviterId;
	eventId;
	url: string | ArrayBuffer = url;
	vanmar: boolean = false;
	userType;
	kivalasztott: boolean = false;
	file: File;

	needTutelary: boolean = true;

	image: string | File = null;
	openP: boolean = false;
	privacy;
	aszfCheck: boolean = false;
	aszfCheckLogin: boolean = false;
	minDate = moment()
		.subtract(110, 'years')
		.toDate();
	maxDate = moment()
		.subtract(13, 'years')
		.subtract(1, 'days')
		.toDate();

	constructor(
		private permissionsService: NgxPermissionsService,
		private auth: AuthService,
		private cdr: ChangeDetectorRef,
		private translationService: TranslationService,
		private router: Router,
		private toastr: ToastrService,
		private spinner: NgxSpinnerService,
		private location: Location,
		private translateService: TranslateService,
		private http: HttpClient,
		private route: ActivatedRoute,
		private fb: FormBuilder,
		private modal: NgbModal
	) {
		var blob = null;
		var xhr = new XMLHttpRequest();
		xhr.open('GET', url);
		xhr.responseType = 'blob';
		xhr.onload = () => {
			const reader = new FileReader();
			reader.addEventListener('load', () => {
				this.url = reader.result;
			});
			reader.readAsDataURL(xhr.response);
		};
		xhr.send();

	}

	@HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
		e.preventDefault();
	}


	ngOnInit() {
		localStorage.clear();
		this.translationService.setLanguage(
			this.translationService.getSelectedLanguage()
		);

		this.route.fragment.subscribe(fragment => {
			let decoded: any = atob(fragment);

			try {
				decoded = JSON.parse(
					decoded
						.replace(/\?(.*)/, '{$1}')
						.replace(/=/g, ':')
						.replace(/&/g, ',')
						.replace(/([,\{])(.*?):/g, '$1"$2":')
				);
			} catch (e) {
				this.toastr.error(this.translateService.instant('REGISTER.BADLINK'), '', {
					timeOut: 5000
				});
			}


			this.inviterId = decoded.inviterId;
			this.eventId = decoded.eventId;
			this.userType = decoded.userType;
		});

		this.form = this.fb.group({
			email: [
				'',
				Validators.compose([
					Validators.required,
					Validators.pattern(
						'^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'
					),
					Validators.minLength(3),
					Validators.maxLength(320) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
				])
			],
			emailRe: [
				'',
				Validators.compose([
					Validators.required,
					Validators.pattern(
						'^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'
					),
					Validators.minLength(3),
					Validators.maxLength(320) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
				])
			],
			password: [
				'',
				Validators.compose([
					Validators.required,
					Validators.minLength(8),
					Validators.maxLength(100),
					Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/)
				])
			],
			passwordRe: [
				'',
				Validators.compose([
					Validators.required,
					this.matchValues('password'),
					Validators.minLength(8),
					Validators.maxLength(100),
					Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/)
				])
			],
			firstname: [
				'',
				[
					Validators.required,
					Validators.pattern('^[a-zÁÉÚŐÓ. ÜÖÍáéúőóűüöíA-Z_-]{3,15}$')
				]
			],
			lastname: [
				'',
				[
					Validators.required,
					Validators.pattern('^[a-zÁÉÚŐÓÜÖÍáé úőóűüöíA-Z_-]{3,20}$')
				]
			],
			eventId: [''],
			userType: [''],
			tutelaryName: [''],
			birthDate: ['', Validators.required],
			birthPlace: ['', Validators.required],
			// motherName: ['', Validators.required],
			privacyPolicy: ['', Validators.required],
			idNumber: ['', Validators.required],
			idType: ['', Validators.required],
			nationality: ['', Validators.required]
		});

		this.form2 = this.fb.group({
			email: [
				'',
				Validators.compose([
					Validators.required,
					Validators.pattern(
						'^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'
					),
					Validators.minLength(3),
					Validators.maxLength(320) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
				])
			],
			privacyPolicy: ['', Validators.required],
			password: [
				'',
				Validators.compose([
					Validators.required,
					Validators.minLength(3),
					Validators.maxLength(100)
				])
			]
		});
		this.form.controls['privacyPolicy'].valueChanges.subscribe(value => {
			this.aszfCheck = value;
		});
		this.form2.controls['privacyPolicy'].valueChanges.subscribe(value => {
			this.aszfCheckLogin = value;
		});

		//todo ezt majd ki kell szednem
		this.form.controls['birthDate'].valueChanges.subscribe(value => {
			let mdate = moment(value).format('YYYY-MM-DD');
			let yearThen = parseInt(mdate.substring(0, 4), 10);
			let monthThen = parseInt(mdate.substring(5, 7), 10);
			let dayThen = parseInt(mdate.substring(8, 10), 10);

			let today = new Date();
			let birthday = new Date(yearThen, monthThen - 1, dayThen);

			let differenceInMilisecond = today.valueOf() - birthday.valueOf();

			let year_age = Math.floor(differenceInMilisecond / 31536000000);
			let day_age = Math.floor((differenceInMilisecond % 31536000000) / 86400000);
			let month_age = Math.floor(day_age / 30);

			day_age = day_age % 30;

			if (year_age >= 13 && year_age <= 16) {
				if (year_age == 13) {
					if (month_age == 0) {
						if (day_age == 0) {
							this.needTutelary = false;
						} else {
							this.needTutelary = true;
						}
					}
				}
				if (year_age == 16) {
					if (day_age > 0 && month_age > 0) {
						this.needTutelary = false;
					}
				} else {
					this.needTutelary = true;
				}
			} else {
				this.needTutelary = false;
			}
		});
	}


	openPrivacy() {
		this.http.get<any>(baseUrl + 'privacyPolicy?eventId=' + this.eventId).subscribe(res => {
			this.openP = true;
			this.privacy = res.privacyPolicy;
		});
	}

	nincsFile() {
		return this.image == null;
	}

	back() {
		this.openP = false;
	}

	setLocale(lang) {
		this.translationService.setLanguage(lang);
	}

	hasPasswordError = (controlName: string, errorName: string) => {
		return this.form.controls[controlName].hasError(errorName);
	};

	checkTutelary() {
		if (this.needTutelary) {
			if (!this.form.get('tutelaryName').value) {
				return true;
			} else {
				return false;
			}
		}
	}

	passwor() {
		return (
			this.form.get('password').value != this.form.get('passwordRe').value
		);
	}

	emailValid() {
		return this.form.get('emailRe').value != this.form.get('email').value;
	}

	public matchValues(
		matchTo: string
	): (AbstractControl) => ValidationErrors | null {
		return (control: AbstractControl): ValidationErrors | null => {
			return !!control.parent &&
			!!control.parent.value &&
			control.value === control.parent.controls[matchTo].value
				? null
				: {isMatching: false};
		};
	}

	nemegyezik() {
		return (
			this.form.controls['password'].value ==
			this.form.controls['passwordRe'].value
		);
	}


	public hasError = (controlName: string, errorName: string) => {
		return this.form.controls[controlName].hasError(errorName);
	};

	openCropModal(event) {
		const ref = this.modal.open(CropperDialogComponent, {
			ariaLabelledBy: 'modal-basic-title',
			size: 'lg'
		});
		ref.componentInstance.source = event;
		ref.result.then(result => {
			if (result) {
				this.url = result;
				this.image = dataURLtoFile(result, 'file.png');
			}
		});
	}

	ngAfterViewChecked() {
		this.cdr.detectChanges();
	}

	login() {
		this.vanmar = true;
	}

	submit() {
		this.spinner.show();

		if (!this.image) {
			this.toastr.error(this.translateService.instant('MESSAGES.IMAGEREQUIRED'));
			return;
		}
		const formData = new FormData();
		formData.append('email', this.form.controls['email'].value);
		formData.append('password', this.form.controls['password'].value);
		formData.append('eventId', this.eventId);
		formData.append('inviterId', this.inviterId);
		// formData.append('motherName', this.form.controls['motherName'].value);
		formData.append('birthPlace', this.form.controls['birthPlace'].value);
		formData.append('nationality', this.form.controls['nationality'].value);
		formData.append('idType', this.form.controls['idType'].value);
		formData.append('idNumber', this.form.controls['idNumber'].value);


		const birth = moment(this.form.controls['birthDate'].value).format(
			'YYYY-MM-DD'
		);
		formData.append('birthDate', birth);
		if (this.form.controls['tutelaryName'].value) {
			formData.append('tutelaryName', this.form.controls['tutelaryName'].value);
		}
		formData.append('firstName', this.form.controls['firstname'].value);
		formData.append('lastName', this.form.controls['lastname'].value);
		if (this.image) {
			formData.append('file', this.image);
		}
		if (this.userType == 1) {
			formData.append('roleType', 'ADMIN');
		}
		if (this.userType == 2) {
			formData.append('roleType', 'SUPERVISOR');
		}
		if (this.userType == 3) {
			formData.append('roleType', 'ALSUPERVISOR');
		}
		if (this.userType == 4) {
			formData.append('roleType', 'USER');
		}
		if (this.userType == 5) {
			formData.append('roleType', 'PENZTAROS');
		}
		return this.http
			.post<any>(baseUrl + 'users/registerUserFromUrl', formData, {
				observe: 'response'
			})
			.subscribe(
				res => {
					if (res.status == 200) {
						this.spinner.hide();
						this.toastr.success(this.translateService.instant('MESSAGES.SUCCESSREGI'), '');
						this.router.navigate(['/auth/login']);
					} else {
						this.spinner.hide();
						this.toastr.error(this.translateService.instant('MESSAGES.ERRORONREGI'), '');
					}
				},
				error => {
					this.spinner.hide();

					this.toastr.error(error.error.message, '', {
						timeOut: 3000
					});
				}
			);
	}

	submit2() {
		this.spinner.show();
		const emaile = this.form2.controls['email'].value;
		const password = this.form2.controls['password'].value;
		const formD = {
			email: emaile,
			password: password,
			eventId: this.eventId,
			inviterId: this.inviterId,
			roleType: this.userType
		};
		const LoginForm = {
			username: emaile,
			password: password
		};

		this.http
			.post<any>(baseUrl + 'users/alreadyMember', formD, {
				observe: 'response'
			})
			.subscribe(
				res => {
					this.toastr.success(
						this.translateService.instant('MESSAGES.ACTIVATEANDLOGIN'),
						''
					);
					this.auth.login(LoginForm).subscribe(res => {
						this.spinner.hide();
						localStorage.setItem('token', res.token);
						localStorage.setItem('role', res.role);
						this.permissionsService.loadPermissions([res.role]);
						const decoded = this.getDecodedAccessToken(res.token);
						this.auth.setFirstname(decoded.firstname);
						this.auth.setLastname(decoded.lastname);
						this.auth.setUser(decoded.sub);
						localStorage.setItem('userId', decoded.userId);
						this.router.navigate(['/']);
					});
				},
				error => {
					this.spinner.hide();

					this.toastr.error(this.translateService.instant(error.error.message), '');
				}
			);
	}

	getDecodedAccessToken(token: string): any {
		try {
			return jwt_decode(token);
		} catch (Error) {
			return null;
		}
	}
}
