import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {baseUrl} from '../../../url.model';

@Injectable({providedIn: 'root'})
export class ForgotPassService {

	constructor(private http: HttpClient) {
	}

	forgotPass(formdata): Observable<any> {
		return this.http.post<any>(baseUrl + 'forgottenPassword', formdata, {observe: 'response'});
	}
}
