import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {LoginpopupService} from '../loginpopup.service';
import {baseUrl} from '../../url.model';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'kt-login-privacy',
	templateUrl: './login-privacy.component.html',
	styleUrls: ['./login-privacy.component.scss']
})
export class LoginPrivacyComponent implements OnInit {
	data;

	constructor(private http: HttpClient, private service: LoginpopupService,public activeModal: NgbActiveModal,private modalService: NgbModal) {
	}

	ngOnInit() {

		this.http.get<any>(baseUrl + 'privacyPolicy', {headers: new HttpHeaders({'Content-Type': 'application/json'})}).subscribe((res) => {
				this.data = res.privacyPolicy;
			}
		);


	}


}
