// Angular
import { Component, HostBinding, OnInit, Input } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
// RxJS
import { filter } from 'rxjs/operators';
// Translate
import { TranslationService } from '../../../../../core/_base/layout';
import {TranslateService} from '@ngx-translate/core';

interface LanguageFlag {
	lang: string;
	name: string;
	flag: string;
	active?: boolean;
}

@Component({
	selector: 'kt-language-selector',
	templateUrl: './language-selector.component.html',
})
export class LanguageSelectorComponent implements OnInit {
	@HostBinding('class') classes = '';
	@Input() iconType: '' | 'brand';
	language: LanguageFlag;
	languages: LanguageFlag[] = [
		{
			lang: 'en',
			name: 'LANGUAGESELECTOR.ENG',
			flag: './assets/media/flags/012-uk.svg'
		},
		{
			lang: 'hu',
			name: 'LANGUAGESELECTOR.HUN',
			flag: './assets/media/flags/021-hungary.svg'
		},
	];


	constructor(private translationService: TranslationService, private translate: TranslateService, private router: Router) {
	}

	ngOnInit() {
		this.setSelectedLanguage();
		this.router.events
			.pipe(filter((event) => event instanceof NavigationStart))
			.subscribe((event) => {
				this.setSelectedLanguage();
			});

	}

	setLanguageWithRefresh(lang) {
		this.setLanguage(lang);
	}

	setLanguage(lang) {
		this.languages.forEach((language: LanguageFlag) => {
			if (language.lang === lang) {
				language.active = true;
				this.language = language;
			} else {
				language.active = false;
			}
		});
		this.translationService.setLanguage(lang);
	}

	setSelectedLanguage(): any {
		this.setLanguage(this.translationService.getSelectedLanguage());
	}
}
