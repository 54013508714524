import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatTableDataSource, MatSort} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationDialog} from '../confirmation-dialog/confirmation-dialog.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ArmbandService} from '../armband/armband.service';
import {debounceTime} from 'rxjs/operators';
import {HttpParams} from '@angular/common/http';
import {InactiveUsersService} from './inactive-users.service';
import {SelectionModel} from '@angular/cdk/collections';

@Component({
	selector: 'kt-inactive-users',
	templateUrl: './inactive-users.component.html',
	styleUrls: ['./inactive-users.component.scss']
})
export class InactiveUsersComponent implements OnInit {


	search = new FormControl();
	dataSource = new MatTableDataSource<any>();
	displayedColumns = ['select', 'name', 'email'];
	pageSize = 10;
	nev = '';
	page = 1;
	collection: number;
	rendezesirany = 'asc';
	colorCode;
	selection: SelectionModel<any>;

	constructor(private translateService: TranslateService, private toastr: ToastrService, private confirmationDialogService: ConfirmationDialog, private modalService: NgbModal, private service: InactiveUsersService, private changeDetector: ChangeDetectorRef) {
	}


	ngOnInit() {
		this.search.valueChanges.pipe(debounceTime(500)).subscribe(str =>
			this.getData());
		this.getData();
		const initialSelection = [];
		const allowMultiSelect = true;
		this.selection = new SelectionModel<any>(
			allowMultiSelect,
			initialSelection
		);
	}

	sortData(event) {
		this.nev = event.active;
		this.rendezesirany = event.direction;
		console.log(this.nev);
		console.log(this.rendezesirany);
		this.getData();
	}

	ngAfterViewChecked() {
		this.changeDetector.detectChanges();
	}

	lapozas(event) {
		this.page = event;
		this.getData();
	}

	getData() {
		let params = new HttpParams()
			.append('oldal', `${this.page - 1}`).append('meret', `${this.pageSize}`).append('date', '2021-09-05').append('rendezesirany', `${this.rendezesirany}`);
		if (this.search.value && this.search.value.length > 2) {
			params = params.append('name', this.search.value);
		}
		this.service.getInactiveUsers(params).subscribe((res) => {
				this.collection = res.osszes;
				this.dataSource.data = res.elemek;
			}
		);
	}

	deleteInactiveUsers() {
		let idSelected: any = [];
		this.selection.selected.map(item => {
			idSelected.push(item.id);
		});
		this.confirmationDialogService
			.confirm(this.translateService.instant('MESSAGES.DELETECONFIRM'), this.translateService.instant('MESSAGES.DELETEDESC'))
			.then((confirmed => {
				if (confirmed) {
					this.service.deleteInactiveUser(idSelected).subscribe(res => {
						this.getData()
					}, error => {
						this.toastr.error(error.error.message, '');
					});
				} else {
					this.toastr.warning(this.translateService.instant('MESSAGES.DISSMISS'), '');
					this.getData()
				}
			}));

	}

	isAllSelected() {

		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource.data.length;
		return numSelected == numRows;
	}

	masterToggle() {
		this.isAllSelected()
			? this.selection.clear()
			: this.dataSource.data.forEach(row => this.selection.select(row));
	}
}
