import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {InformationService} from '../information.service';
import {ToastrService} from 'ngx-toastr';
import {TranslationService} from '../../../../core/_base/layout';
import {TranslateService} from '@ngx-translate/core';

@Component({
	selector: 'kt-information-data',
	templateUrl: './information-data.component.html',
	styleUrls: ['./information-data.component.scss']
})
export class InformationDataComponent implements OnInit {
	id;
	data;
lang;
	constructor(private cdr: ChangeDetectorRef,private translateService: TranslateService,	private translation: TranslationService,private toastr: ToastrService,private route: ActivatedRoute, private informationService: InformationService) {
	}

	ngOnInit() {
		this.lang = this.translation.getSelectedLanguage();
		this.translateService.onLangChange.subscribe(event => {
			this.lang = event.lang;
		});
		this.id = this.route.snapshot.paramMap.get('id');
		this.getData();
	}

	getData(){
		this.informationService.getEventsInformation(this.id).subscribe((res) => {
			this.data = res;
		},error => {
			this.toastr.error(error.error.message);
		});
	}

	ngAfterViewChecked() {
		this.cdr.detectChanges();
	}
}
