import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {RedemptionService} from '../redemption.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {MatTableDataSource} from '@angular/material';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {baseUrl} from '../../url.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UsersService} from '../../users/users.service';
import {CheckDataService} from './check-data/check-data.service';
import moment from 'moment';
import {CropperDialogComponent, dataURLtoFile} from '../../cropper-dialog/cropper-dialog.component';
import {TranslateService} from '@ngx-translate/core';

const urlLogo = 'https://mlsz-defba.firebaseapp.com/assets/media/add_photo.png';
const url = 'https://mlsz-defba.firebaseapp.com/assets/media/add_photo.png';

@Component({
	selector: 'kt-details',
	templateUrl: './details.component.html',
	styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {
	userID;
	url = url;
	helperId;
	typehelper;
	file: File;

	dataSource = new MatTableDataSource<any>();
	displayedColumns = ['eventname', 'type', 'color', 'invitername', 'actions'];
	search = new FormControl();
	pageSize = 50;
	page = 1;
	nev = '';
	image: string | File = null;
	collection: number;
	rendezesirany = 'asc';

	form = this.fb.group({

		firstname: ['', [Validators.required, Validators.pattern('^[a-zÁÉÚŐÓ. ÜÖÍáéúőóüöíA-Z_-]{3,15}$')]],
		lastname: ['', [Validators.required, Validators.pattern('^[a-zÁÉÚŐÓÜÖÍáéúőóüöíA-Z_ -]{3,15}$')]],
		birthDate: ['', Validators.required],
		birthPlace: ['', Validators.required],
		idType: ["", Validators.required],
		idNumber: ["", Validators.required],
		nationality: ["", Validators.required],
		// motherName: ['', Validators.required]
	});


	constructor(private translateService: TranslateService,private redemptionService: RedemptionService, private modal: NgbModal, private changeDetectorRef: ChangeDetectorRef, private userService: UsersService, private checkService: CheckDataService, private fb: FormBuilder, private modalService: NgbModal, private cdr: ChangeDetectorRef, private toastr: ToastrService, private router: Router, private route: ActivatedRoute, private redService: RedemptionService) {
	}

	ngAfterViewChecked() {
		this.cdr.detectChanges();
	}

	download(dataid) {


		const birth = moment(this.form.controls['birthDate'].value).format('YYYY-MM-DD');
		this.form.controls['birthDate'].setValue(birth);

		const formData = {
			id: this.userID,
			firstname: this.form.controls['firstname'].value,
			lastname: this.form.controls['lastname'].value,
			nationality: this.form.controls['nationality'].value,
			idType: this.form.controls['idType'].value,
			idNumber: this.form.controls['idNumber'].value,
			// motherName: this.form.controls['motherName'].value,
			birthDate: birth,
			birthPlace: this.form.controls['birthPlace'].value,
		};

		this.checkService.putUser(formData).subscribe((res) => {
				if (res.status == 200) {
					this.checkService.downloadUserPass(this.userID,dataid).subscribe((res) => {
						var link = document.createElement("a");
						let file = new Blob([res], {type: 'application/pdf'});
						var fileUrl = URL.createObjectURL(file);
						link.setAttribute("href", fileUrl);
						link.setAttribute("download", 'userPass'+this.userID+'.pdf');
						link.style.visibility = 'hidden';
						document.body.appendChild(link);
						link.click();
						document.body.removeChild(link);
					}, error => {

						var string = new TextDecoder("utf-8").decode(error.error);
						let obj = JSON.parse(string);
						let message = obj['message'];

						this.toastr.error(message, '');
					});
				}
			}, (error => {
				this.toastr.error(error.error.message, '');
			})
		);

	}


	redeem(data) {
		const form = {
			helperId: data.helperId,
			type: this.typehelper
		};
		this.redService.redemption(form).subscribe((res) => {
			this.toastr.success('Sikeres beváltás');
		}, error => {
			this.toastr.error(error.error.message);
		});
	}

	lapozas(event) {
		this.page = event;
		this.getDatawitId();
	}

	ngOnInit() {
		this.userID = this.route.snapshot.paramMap.get('id');
		this.userService.getUser(this.userID).subscribe((res) => {
			this.form.setValue({
				firstname: res.firstname,
				lastname: res.lastname,
				birthDate: res.birthDate,
				birthPlace: res.birthPlace,
				idType: res.idType,
				idNumber: res.idNumber,
				nationality: res.nationality
				// motherName: res.motherName
			});
			this.image = res.url;
			this.url = res.url;
		}, error => {
			this.toastr.error(error.error.message, '');
		});
		this.getDatawitId();
	}

	public hasError = (controlName: string, errorName: string) => {
		return this.form.controls[controlName].hasError(errorName);
	};

	changeImage(event) {
		const ref = this.modal.open(CropperDialogComponent, {
			ariaLabelledBy: 'modal-basic-title',
			size: 'lg'
		});
		ref.componentInstance.source = event;
		ref.result.then(result => {
			if (result) {
				this.url = result;
				this.image = dataURLtoFile(result, 'file.png');
				this.changeDetectorRef.detectChanges();
				const formData = new FormData();
				if (this.file) {

					formData.append('file', this.file);
				} else {
					formData.append('file', dataURLtoFile(result, 'file.png'));
				}
				this.redemptionService.changeImage(this.userID, formData).subscribe((res) => {
					if (res.status == 200) {
						this.toastr.success(this.translateService.instant('MESSAGES.SUCCESSUPDATEIMAGE'), '');
					} else {
						this.toastr.error(this.translateService.instant('MESSAGES.DEFAULTERROR'));
					}
				}, error => {
					this.toastr.error(error.error.message, '');
				});
			}
		});
	}

	getDatawitId() {
		const formData = {
			userId: this.userID
		};
		this.redService.getDatawithId(formData).subscribe((res) => {
			this.helperId = res[0].helperId;
			this.typehelper = res[0].type;
			this.dataSource = res;
		}, error => {
			this.toastr.error(error.error.message, this.translateService.instant('MESSAGES.ERROR'));
		});
	}
}
