import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BItemService } from './b-item.service';
import { Section } from '../../../invited/activate-modal/activate-modal.component';
import { EventsService } from '../../../events/events.service';
import { BehaviorSubject } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { MatListOption, MatSelectionList } from '@angular/material';
import { InvitedService } from '../../../invited/invited.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
	selector: 'kt-b-item',
	templateUrl: './b-item.component.html',
	styleUrls: ['./b-item.component.scss']
})
export class BItemComponent implements OnInit {
	@Input() public id;
	@ViewChild(MatSelectionList, null) selectionList: MatSelectionList;
	@Input() public eventId;
	available$ = new BehaviorSubject([]);
	title;
	selectedOptions = [];
	selectedOptionsPark = [];
	passes: Section[];
	bracelets: Section[];
	parkings: Section[];
	selectedOption;
	entryId;
	oldEntryType;
	activateButton: boolean = false;
	slotId;
	selectedOptionsChange: boolean = false;
	selectedOptionsParkChange: boolean = false;

	constructor(private translateService: TranslateService,private iservice: InvitedService, private eventService: EventsService, public activeModal: NgbActiveModal, private toastr: ToastrService, private service: BItemService) {
	}

	post() {
		const formData = {
			userId: this.id,
			eventId: this.eventId
		};
		this.iservice.postFake(formData).subscribe((res) => {
			this.passes = res.body.passes;
			this.bracelets = res.body.bracelets;
			this.parkings = res.body.parkingSlots;
		}, error => {
			this.toastr.error(error.error.message, '');
		});
	}


	ngOnInit() {
		this.selectionList.selectedOptions = new SelectionModel<MatListOption>(false);
		this.post();
		this.getAvailable();
		this.getData();
	}

	getAvailable() {
		this.eventService.getActivableEvents(this.id).subscribe((res) => {
			this.available$.next(res);
		}, error => {
			this.toastr.error(error.error.message, '');
		});
	}


	onNgModelChange(event) {
		if (event) {
			this.selectedOptionsChange = true;
			this.selectedOption = event;
			this.activateButton = true;
		} else {
			this.activateButton = false;
		}
	}

	onNgModelChangePark(event) {
		if (event) {
			this.selectedOptionsParkChange = true;
			this.selectedOptionsPark = event;
			this.activateButton = true;
		} else {
			this.activateButton = false;
		}
	}

	submit() {
		if (this.selectedOptionsChange) {
			let formd;
			if (this.selectedOptionsPark[0]) {
				formd = {
					userId: this.id,
					itemId: this.selectedOption[0].id,
					eventId: this.eventId,
					entryType: this.selectedOption[0].type,
					parkingSlotId: this.selectedOptionsPark[0].id,
					entryId: this.entryId,
					oldEntryType: this.oldEntryType
				};
			}else{
				formd = {
					userId: this.id,
					itemId: this.selectedOption[0].id,
					eventId: this.eventId,
					entryType: this.selectedOption[0].type,
					entryId: this.entryId,
					oldEntryType: this.oldEntryType
				};
			}
			this.service.changeEntryItem(formd).subscribe((res) => {
				this.toastr.success(this.translateService.instant('MESSAGES.TICKETMODIFY'), '');
				this.onClose();
			}, error => {
				this.toastr.error(error.error.message, '');
			});
		}
		if (this.selectedOptionsParkChange) {
			const formd = {
				userId: this.id,
				eventId: this.eventId,
				parkingSlotId: this.selectedOptionsPark[0].id,
			};
			this.service.changeEntryItem(formd).subscribe((res) => {
				this.toastr.success(this.translateService.instant('MESSAGES.TICKETMODIFY'), '');
				this.onClose();
			}, error => {
				this.toastr.error(error.error.message, '');
			});
		}

	}

	onClose(): void {
		this.activeModal.close(true);
	}

	getData() {
		const formData = new FormData();
		formData.append('eventId', this.eventId);
		formData.append('userId', this.id);
		this.service.getEntryItem(formData).subscribe((res) => {
			this.slotId = res.slotId;
			this.title = res.name;
			this.entryId = res.entryId;
			this.oldEntryType = res.oldEntryType;
		}, error => {
			this.toastr.error(error.error.message, '');
		});
	}

	deletePark() {
		const formdata = {
			slotId: this.slotId
		};
		this.service.deleteParks(formdata).subscribe(res => {
			this.toastr.success(this.translateService.instant('MESSAGES.SUCDELPARKING'), '');
			this.activeModal.close(true);
		}, error => {
			this.toastr.error(error.error.message, '');
		});
	}

}
