import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {baseUrl} from '../url.model';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class PassesService {
	constructor(private http: HttpClient) {

	}

	getPrivacyPolicy(): Observable<any> {
		return this.http.get<any>(baseUrl + 'privacyPolicy');
	}

	getPasses(params) {
		return this.http.get<any>(baseUrl + 'passes/passAll', {params: params});
	}

	getPassesAll(params){
		return this.http.get<any>(baseUrl+'passes/passAllList',{params:params})
	}
	// basehost/events/{eventId}/activePasses
	getPassesNew(id) {
		return this.http.get<any>(baseUrl + 'events/' + id + '/activePasses');
	}

	getPass(id): Observable<any> {
		return this.http.get<any>(baseUrl + 'passes/' + id);
	}

	newPass(formdata): Observable<any> {
		return this.http.post<any>(baseUrl + 'passes', formdata, {observe: 'response'});
	}

	deletePass(id): Observable<any> {
		return this.http.delete<any>(baseUrl + 'passes/' + id, {observe: 'response'});
	}

	downloadPass(id, userId): Observable<any> {
		return this.http.get(baseUrl + `passes/loadPassSampleWithToken/${id}/${userId}`, {responseType: 'arraybuffer'});
	}

	deletePasswithPassword(id, password): Observable<any> {
		const options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
			}),
			body: {
				password: password
			},
		};
		return this.http.delete<any>(baseUrl + 'passes/' + id, options);
	}

	modifyPasses(id: number, formdata: any) {
		return this.http.put<any>(baseUrl + 'passes/' + id, formdata, {observe: 'response'});
	}
}
