import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {baseUrl} from '../url.model';

@Injectable({providedIn: 'root'})
export class UsersService {
	constructor(private http: HttpClient) {
	}


	getUsers(params): Observable<any> {
		return this.http.get<any>(baseUrl + 'users/usersAll', {params: params});
	}

	getMyUser(): Observable<any> {
		return this.http.get<any>(baseUrl + 'users/getMyUsers');
	}
	// Meghívó személye dropdown: basehost/event/{eventId}/inviters-> responseban {id , fullName}
	getInvitedPersons(id): Observable<any>{
		return this.http.get<any>(baseUrl+"advancedFilters/event/"+id+"/inviters")
	}

	// Pass dropdown basehost/event/{eventId}/passes
	getPassDropwodn(id): Observable<any> {
		return this.http.get<any>(baseUrl + 'advancedFilters/event/'+id+'/passes');
	}

	// Karszalag dropdown basehost/event/{eventId}/bracelets
	getBraceletDropwodn(id): Observable<any> {
		return this.http.get<any>(baseUrl + 'advancedFilters/event/'+id+'/bracelets');
	}

// Parkoló dropdown basehost/event/{eventId}/parkingSlots
	getParkingDropdown(id): Observable<any> {
		return this.http.get<any>(baseUrl + 'advancedFilters/event/'+id+'/parkingSlots');
	}


//todo másik végpontot átkötni
	getMyUser2(): Observable<any> {
		return this.http.get<any>(baseUrl + 'users/getMyUsers');
	}

	// basehost/users/eventAccess POST-os eventId, userId,
	// "denied" booleant vár ha true akkor letiltom ha false akkor aktiválom a palit az eseményen. amikor lekéred a suer részleteit az eseményei listában szintén "denied" mezővel fog szerepelnihogy tiltott vagy sem

	setuserEventDenied(formData):Observable<any>{
		return this.http.post<any>(baseUrl + 'users/eventAccess', formData, {observe: 'response'});
	}

	newGlobalSearch(params): Observable<any> {
		return this.http.get<any>(baseUrl + 'users/searchOnEvent', {params: params});
	}

	globalSearch(params): Observable<any> {
		return this.http.get<any>(baseUrl + 'users/globalSearch', {params: params});
	}

	getEvents(): Observable<any>{
		return this.http.get<any>(baseUrl+"users/myEvents")
	}

	newUser(formdata): Observable<any> {
		return this.http.post<any>(baseUrl + 'users/createUser', formdata, {observe: 'response'});
	}

	deleteUser(id): Observable<any> {
		return this.http.delete<any>(baseUrl + 'users/delete/' + id);
	}

	// basehost/users/eventAccess POST-os eventId, userId,
	// "denied

	seteventAccess(formData): Observable<any>{
		return this.http.post<any>(baseUrl+'users/eventAccess', formData);
	}

	putUser(id, formdata): Observable<any> {
		return this.http.put<any>(baseUrl + 'users/modifyUser/' + id, formdata, {observe: 'response'});
	}

	getUser(id): Observable<any> {
		return this.http.get<any>(baseUrl + 'users/' + id);
	}

	getAvailableRoles(): Observable<any> {
		return this.http.get<any>(baseUrl + 'roles/avaibleRoles', {observe: 'response'});
	}


	getAvailableParkingSlots(id) {
		return this.http.get<any>(baseUrl + 'users/' + id + '/avaibleParkingSlots');
	}

	getAviblePasses(id) {
		return this.http.get<any>(baseUrl + 'users/' + id + '/avaiblePasses');
	}

	getCurrentuserAvalable() {
		return this.http.get<any>(baseUrl + 'users/currentUser/avaiblePasses');
	}

	getAvailablebracelets(id) {
		return this.http.get<any>(baseUrl + 'users/' + id + '/avaibleBracelets');
	}
	getImg(url){
		return this.http.get(url);
	}

	getImage(id):Observable<any>{
		return this.http.get<any>(baseUrl+'images/profileImages/'+id);
	}
	getImageSecured(id):Observable<any>{
		return this.http.get<any>(baseUrl+'images/profileImageSecured/'+id);
	}
	getCurrentuserAvalableBracelet() {
		return this.http.get<any>(baseUrl + 'users/currentUser/avaibleBracelets');
	}

	modifyBraceletLimitToSupervisor(formdata) {
		return this.http.post<any>(baseUrl + 'users/braceletLimits', formdata, {observe: 'response'});
	}

	modifyPassLimitToSupervisor(formdata) {
		return this.http.post<any>(baseUrl + 'users/passLimits', formdata, {observe: 'response'});
	}


	modifyParkingLimitToSupervisor(formdata) {
		return this.http.post<any>(baseUrl + 'users/parkingLimits', formdata, {observe: 'response'});
	}

	getPassLimitToSupervisor(id) {
		return this.http.get<any>(baseUrl + 'users/' + id + '/passLimits');
	}

	getBraceletLimitToSupervisor(id) {
		return this.http.get<any>(baseUrl + 'users/' + id + '/braceletLimits');
	}

	getParkingLimitToSupervisor(id) {
		return this.http.get<any>(baseUrl + 'users/' + id + '/parkingLimits');
	}
}
