import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {baseUrl} from '../url.model';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class EventsService {

	constructor(private http: HttpClient) {

	}

	getEvents(params) {
		return this.http.get<any>(baseUrl + 'events/eventsAll', {params: params});
	}

	getEvent(id): Observable<any> {
		return this.http.get<any>(baseUrl + 'events/' + id);
	}

	// downloadPass(id,userId): Observable<any> {
	// 	return this.http.get(baseUrl + `passes/loadPassSampleWithToken/${id}/${userId}`,  {responseType: 'arraybuffer'});
	// }

	downloadCsv(eventId):Observable<any>{
		// window.open(baseUrl + 'events/eventExport/' + this.eventId);
		return this.http.get(baseUrl+`events/eventExport/${eventId}`,{responseType:'arraybuffer'});
	}

	getActivableEvents(id): Observable<any> {
		return this.http.get<any>(baseUrl + 'users/' + id + '/getActivableEvents');
	}

	getMyEvent(): Observable<any> {
		return this.http.get<any>(baseUrl + 'users/getMyEvents');
	}

	exportEvent(id): Observable<any> {
		return this.http.get<any>(baseUrl + 'events/eventExport/' + id);
	}

anonymPass(formdata):Observable<any> {
	return this.http.post<any>(baseUrl + 'events/impersonilizedPassGenerating', formdata,{observe: 'response'});
}
	//összes pass letöltés , emailben tájékoztató
	allPassDownload(formdata): Observable<any> {
		return this.http.post<any>(baseUrl + 'events/startAllPassGenerating', formdata);
	}

	choosenpassGenerate(formdata): Observable<any> {
		return this.http.post<any>(baseUrl + 'events/startChoosenPassGenerating', formdata,{observe: 'response'});
	}

	activePasses(eventId): Observable<any> {
		return this.http.get<any>(baseUrl + `events/${eventId}/activePasses` );
	}

	newEvent(formdata): Observable<any> {
		return this.http.post<any>(baseUrl + 'events', formdata);
	}

	deleteEvent(id): Observable<any> {
		return this.http.delete<any>(baseUrl + 'events/deleteA/' + id, {observe: 'response'});
	}

	modifyEvent(id: number, formdata: any) {
		return this.http.put<any>(baseUrl + 'events/' + id, formdata, {observe: 'response'});
	}

	allPass(id) {
		return this.http.get<any>(baseUrl + 'events/loadAllPass/' + id);
	}

	scopeMail(formdata): Observable<any> {
		return this.http.post<any>(baseUrl + 'users/notifyGroup', formdata);
	}

	refreshVoucher(formdata): Observable<any> {
		return this.http.post<any>(baseUrl + 'events/voucherReset', formdata);
	}


	downloadVoucher(eventid, userid): Observable<any> {
		return this.http.get(baseUrl + 'vouchers/loadUserVoucher/' + eventid + '/' + userid, {responseType: 'arraybuffer'});
	}

	invitingVoucher(eventid, userid):Observable<any>{
		return this.http.get(baseUrl+'vouchers/loadUserInvitingVoucher/' + eventid + '/' + userid, {responseType: 'arraybuffer'});
	}

	downloadParking(eventid, userid):  Observable<any>  {
		// basehost/parkingSlots/loadUserParkingSlot/{eventId}/{userId}
		return this.http.get(baseUrl + 'parkingSlots/loadUserParkingSlot/' + eventid + '/' + userid, {responseType: 'arraybuffer'});
	}
}
