import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {baseUrl} from '../url.model';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class HelperService {

	constructor(private http: HttpClient) {

	}

	getHelper() {
		return this.http.get<any>(baseUrl + 'youtube');
	}

	getHelp(id): Observable<any> {
		return this.http.get<any>(baseUrl + 'youtube/' + id);
	}

	newHelper(formdata){
		return this.http.post<any>(baseUrl + 'youtube', formdata, {observe: 'response'});
	}
	deleteHelper(id): Observable<any> {
		return this.http.delete<any>(baseUrl + 'youtube/' + id, {observe: 'response'});
	}
	modifyHelper(id, formdata): Observable<any> {
		return this.http.put<any>(baseUrl + 'youtube/' + id, formdata, {observe: 'response'});
	}
}
