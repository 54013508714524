import { Subscription } from 'rxjs';
// Angular
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// Layout
import { LayoutConfigService, SplashScreenService, TranslationService } from './core/_base/layout';
// language list
import { locale as enLang } from './core/_config/i18n/en';
import { locale as chLang } from './core/_config/i18n/ch';
import { locale as esLang } from './core/_config/i18n/es';
import { locale as jpLang } from './core/_config/i18n/jp';
import { locale as deLang } from './core/_config/i18n/de';
import { locale as frLang } from './core/_config/i18n/fr';
import {locale as huLang} from './core/_config/i18n/hu';
import {AuthService} from './core/auth';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { TranslateService } from '@ngx-translate/core';
@Component({
	// tslint:disable-next-line:component-selector
	selector: 'body[kt-root]',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
	// Public properties
	title = 'MLSZ';
	loader: boolean;
	selected ;
	private popupOpenSubscription: Subscription;
	private popupCloseSubscription: Subscription;
	private initializeSubscription: Subscription;
	private statusChangeSubscription: Subscription;
	private revokeChoiceSubscription: Subscription;
	private noCookieLawSubscription: Subscription;
	private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param translationService: TranslationService
	 * @param router: Router
	 * @param auth
	 * @param cdr
	 * @param layoutConfigService: LayoutCongifService
	 * @param splashScreenService: SplashScreenService
	 */
	constructor(private translationService: TranslationService,
				private router: Router,
				private auth:AuthService,
				private translateService:TranslateService,
				private ccService: NgcCookieConsentService,
				private cdr : ChangeDetectorRef,
				private layoutConfigService: LayoutConfigService,
				private splashScreenService: SplashScreenService) {

		// register translations
		this.translationService.loadTranslations(huLang, enLang);
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		const select = this.translationService.getSelectedLanguage();

		if (select) {
			this.selected = select;
			this.translationService.setLanguage(select);
		} else {
			this.selected = 'hu';
			this.translationService.setLanguage(this.selected);
		}
		// enable/disable loader
		this.translateService//
			.get(['cookie.header', 'cookie.message', 'cookie.dismiss', 'cookie.allow', 'cookie.deny', 'cookie.link', 'cookie.policy'])
			.subscribe(data => {

				this.ccService.getConfig().content = this.ccService.getConfig().content || {} ;
				// Override default messages with the translated ones
				this.ccService.getConfig().content.header = data['cookie.header'];
				this.ccService.getConfig().content.message = data['cookie.message'];
				this.ccService.getConfig().content.dismiss = data['cookie.dismiss'];
				this.ccService.getConfig().content.allow = data['cookie.allow'];
				this.ccService.getConfig().content.deny = data['cookie.deny'];
				this.ccService.getConfig().content.policy = data['cookie.policy'];

				this.ccService.destroy();//remove previous cookie bar (with default messages)
				this.ccService.init(this.ccService.getConfig()); // update config with translated messages
			});
		this.loader = this.layoutConfigService.getConfig('loader.enabled');
		const routerSubscription = this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.cdr.markForCheck()

				// hide splash screen
				this.splashScreenService.hide();
				// scroll to top on every route change
				window.scrollTo(0, 0);

				// to display back the body content
				setTimeout(() => {
					document.body.classList.add('kt-page--loaded');
				}, 500);
			}
		});
		this.unsubscribe.push(routerSubscription);
		if (!this.auth.getUser() || !localStorage.getItem('access_token')) {
			// this.router.navigate(['/auth/login']);
		}
	}

	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.unsubscribe.forEach(sb => sb.unsubscribe());
		this.popupOpenSubscription.unsubscribe();
		this.popupCloseSubscription.unsubscribe();
		this.initializeSubscription.unsubscribe();
		this.statusChangeSubscription.unsubscribe();
		this.revokeChoiceSubscription.unsubscribe();
		this.noCookieLawSubscription.unsubscribe();
	}
}
