import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatTableDataSource} from '@angular/material';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationDialog} from '../confirmation-dialog/confirmation-dialog.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NewParkingComponent} from './new-parking/new-parking.component';
import {HttpParams} from '@angular/common/http';
import {ParkingService} from './parking.service';
import {debounceTime} from 'rxjs/operators';
import {baseUrl} from '../url.model';
import {TranslateService} from '@ngx-translate/core';

@Component({
	selector: 'kt-parking',
	templateUrl: './parking.component.html',
	styleUrls: ['./parking.component.scss']
})
export class ParkingComponent implements OnInit {
	search = new FormControl();
	dataSource = new MatTableDataSource<any>();
	displayedColumns = ['name', 'description', 'actions'];
	pageSize = 10;
	page = 1;
	nev = '';
	collection: number;
	rendezesirany = 'asc';

	constructor(private translateService: TranslateService,private toastr: ToastrService, private confirmationDialogService: ConfirmationDialog, private modalService: NgbModal, private service: ParkingService, private changeDetector: ChangeDetectorRef) {
	}

	ngOnInit() {
		this.search.valueChanges.pipe(debounceTime(500)).subscribe(str =>
			this.getData());
		this.getData();
	}

	ngAfterViewChecked() {
		this.changeDetector.detectChanges();
	}

	editParking(colors?) {
		const modalRef = this.modalService.open(NewParkingComponent, {
			size: 'lg',
			backdrop: 'static',
			keyboard: false
		});
		modalRef.componentInstance.id = colors;
		modalRef.result.then(() => {
			this.getData();
		}).catch(() => {
		});
	}
	download(id){
		this.service.downloadParking(id).subscribe((res) => {
			var link = document.createElement("a");
			let file = new Blob([res], {type: 'application/pdf'});
			var fileUrl = URL.createObjectURL(file);
			link.setAttribute("href", fileUrl);
			link.setAttribute("download", 'parking'+id+'.pdf');
			link.style.visibility = 'hidden';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}, error => {

			var string = new TextDecoder("utf-8").decode(error.error);
			let obj = JSON.parse(string);
			let message = obj['message'];

			this.toastr.error(message, '');
		});
	}
	deleteParking(id) {
		this.confirmationDialogService
			.passwordconfirm(this.translateService.instant('MESSAGES.DELETECONFIRM'), this.translateService.instant('MESSAGES.DELETEDESC'))
			.then(result => {
				if (result[0].result) {
					this.service.deleteParkingwithPassword(id, result[0].password).subscribe((res) => {
						this.toastr.success(this.translateService.instant('MESSAGES.SUCCESSDELETEPARKING'), '');
						this.getData();
					},error => {
						this.toastr.error(error.error.message,'');
						this.getData();
					});
				} else {
					this.toastr.warning(this.translateService.instant('MESSAGES.DISSMISS'), '');
					this.getData();
				}
			});
	}

	lapozas(event) {
		this.page = event;
		this.getData();
	}

	getData() {
		let params = new HttpParams()
			.append('oldal', `${this.page - 1}`).append('meret', `${this.pageSize}`);
		if (this.search.value && this.search.value.length > 2) {
			params = params.append('szoveg', this.search.value);
		}
		this.service.getParkings(params).subscribe((res) => {
			this.collection = res.osszes;
			this.dataSource = res.elemek;
		});
	}
}
