// Magyar
export const locale = {
	lang: 'hu',
	data: {
		inviter_has_no_parking: 'Nincs ilyen parkoló a meghívóhoz rendelve.',
		parking_slot_limit_reached: 'Nincs ennyi szabad parkoló ebből a típusból.',
		parking_limit_only_for_supervisor_and_subsupervisor: 'Jelenleg csak Supervisorhoz,AlSupervisorhoz lehet parkolót rendelni.',
		event_pass_redundant_error: 'Ugyanaz az esemény - pass limit nem lehet kétszer menteni.',
		inviter_has_no_pass_of_this_type: 'Nincs ilyen pass a feletteshez rendelve.',
		pass_limit_reached: 'Nincs ennyi szabad pass ebből a típusból.',
		pass_limit_only_for_supervisor_and_subsupervisor: 'Jelenleg csak Supervisorhoz/Alsupervisorhoz lehet passot adni.',
		user_voucher_redundant_error: 'Egy eseményhez csak egy vouchert lehet rendelni személyenként.',
		wrong_old_password: 'Helytelen régi jelszó.',
		invited_role_error: 'Nem hívhatsz meg ilyen rangú felhasználót.',
		inviter_has_no_voucher_to_event: 'A meghívónak nincs erre az eseményre Vouchere.',
		only_for_admin_and_supervisor_and_subsupervisor: 'Admin AlSuperVisor és SuperVisor éri el ezt a funkciót',
		user_is_not_on_event: 'Felhasználó Nincs rajta az eseményen.',
		your_accreditation_is_arrived: 'Az akkreditációs kérelmed erre az eseményre már megtörtént.',
		invitation_is_not_exist: 'Nincs ilyen meghívás a rendszerben.',
		you_have_no_right_to_change_the_user_events: 'Nincs joga felhasználót másik eseményhez rendelni.',
		user_has_no_entry_item: 'Ennek a felhasználónak erre az eseményre nincs semmilyen belépője.',
		you_can_not_change_your__entry_item: 'Saját belépő nem módosítható.',
		server_error: 'Szerver hiba',
		unknown_entry_type: 'Ismeretlen belépő típus.',
		parking_limit_end: 'Nincs ehhez a parkolóhoz több limited.',
		unknown_youtube_id: 'Nincs ilyen youtube segítség.',
		limit_not_exist: 'Nincs ilyen limit.',
		parking_limit_is_not_enough: 'Nincs ennyi szabad parkoló limited.',
		parking_limit_error: 'Nincs ebből a parkolóból limited.',
		user_is_exist_on_this_event: 'A user már regisztrrálva van az eseményre.',
		bracelet_limit_reached: 'Nincs elég szabad karszalag limited.',
		parking_limit_reached: 'Nincs elég szabad parkoló limited.',
		tutelary_name_required: 'Gondviselő név kötelező.',
		fullname_required: 'Teljes név kötelező.',
		birthplace_required: 'Születési hely kötelező.',
		birthdate_required: 'Születési idő kötelező.',
		date_format_error: 'Születési idő formátum nem megfelelő.',
		nationality_required: 'Állampolgárság kötelező.',
		idtype_required: 'Személyi okmány típusa kötelező.',
		idnumber_required: 'Okmányazonosító kötelező.',
		wrong_credentials: 'Hibás felhasználónév vagy jelszó',
		server_side_error: 'Server oldali hiba',
		new_password_time_out: 'Lejárt email token',
		password_strength_error: 'A jelszónak minimum 8 karaktert tartalmaznia kell kis és nagy betűt valamint vagy számot.',
		only_a_developer_can_change_the_about_us: 'Csak fejlesztő módosíthatja a bemutatkozást.',
		unknown_bracelet_id: 'Nincs ilyen karszalag.',
		wrong_password: 'Helytelen jelszó.',
		password_required: 'Jelszóval védett tartalom.',
		delete_unsuccessfull: 'Sikertelen törlés.',
		email_unsuccessfull: 'Email kiküldés sikertelen.',
		pass_generate_error: 'Hiba a generálás közben.',
		unknown_pass_type: 'Ismeretlen pass típus.',
		token_error: 'Biztonsági hiba lépett fel. Kérem lépjen be újra.',
		only_for_developer: 'Ez a funkció csak fejlesztőnek érhető el.',
		only_for_developer_and_admin: 'Ez a funkció csak fejlesztőnek és adminnak érhető el.',
		only_for_developer_and_admin_and_supervisor: 'Ez a funkció csak fejlesztőnek,adminnak és supervisornak érhető el.',
		only_for_developer_and_admin_and_cassa: 'Ez a funkció csak fejlesztőnek,adminnak és kasszásnak érhető el.',
		only_for_developer_and_admin_and_supervisor_subsupervisor: 'Ez a funkció csak fejlesztőnek,adminnak,supervisornak és alsupervisornak érhető el.',
		only_for_cassa: 'Ez a funkció csak kasszás által érhető el.',
		only_developer_can_change_the_privacy_policy: 'Csak Fejlesztő módosíthatja az általános szerződési feltételeket.',
		active_org_missing: 'Nincs kiválasztva szervezet ehhez a felhasználóhoz.',
		unknown_parking_id: 'Ismeretlen parkoló.',
		unknown_pass_id: 'Ismeretlen pass.',
		unknown_event_id: 'Nincs ilyen esemény.',
		unknown_user_id: 'Ismeretlen felhasználó.',
		unattached_pass_id: 'Ez a pass azonosító nem tartozik az eseményhez:',
		you_have_no_voucher_to_this_event: 'Önnek nincs vouchere erre az eseményre',
		pass_limit_error: 'Nincs ilyen passból rendelkezésre álló mennyiség ehhez az eseményhez.',
		group_upload_pass_limit_error_1: 'Nincs elegendő rendelkezésre álló pass. Még',
		group_upload_pass_limit_error_2: 'van és ennyit kívánsz feltölteni:',
		amazon_connection_error: 'Hiba történt az amazon server elérése közben.',
		unknown_org_id: 'Nincs ilyen szervezet.',
		user_has_no_parking_slot: 'Ennek a usernek nincs parkolója erre az eseményre.',
		pass_name_duplicated_error: 'Ugyanazzal a névvel nem lehet több passot létrehozni.',
		upload_error: 'Sikertelen file feltöltés.',
		unknown_role_id: 'Nincs ilyen szerepkör',
		already_used_bracelet_error: 'Már beváltott karszalag.',
		already_used_pass_error: 'Már beváltott pass.',
		unknown_type: 'Ismeretlen beváltás típus.',
		used_email_error: 'Már van felhasználó ilyen email címmel.',
		only_admin_supvisor_andsubsupvisor_has_limit: 'Csak Adminnak, Supervisornak és Alsupervisornak van limitje.',
		unknown_voucher_id: 'Ismeretlen voucher azonosító.',
		download_error: 'Letöltés sikertelen.',
		not_available_for_user: 'User nem érheti el ezt a funckiót.',
		event_id_required: 'Esemeny azonosito kötelezo.',
		not_available_for_90_days_old_event: '90 napnál régebbi eseményen Ön nem jogosult keresni.',
		you_cant_change_your_own_limit: 'Saját maga limitjét nem módosíthatja.',
		user_has_no_pass_to_event: 'Ennek a usernek nincs passa ehhez az eseményhez',
		user_not_invited: 'Erre az eseményre ez a felhasználó nincs meghívva.',
		bracelet_limit_end: 'Nincs már rendelkezésre álló karszalag.',
		only_the_inviter_can_validate: 'Csak a meghívó igazolhatja vissza a meghívottat.',
		no_limit_for_this_event: 'Nincs erre az eseményre limit.',
		pass_limit_end: 'Nincs már rendelkezésre álló pass.',
		parking_slot_limit_end: 'Nincs már rendelkezésre álló parkoló.',
		limit_required: 'Limitet kötelező megadni.',
		redundant_bracelet_limit_error: 'Ugyanaz az esemény - karszalag limitet nem lehet kétszer menteni.',
		inviter_has_no_bracelet_limit: 'Nincs ilyen karszalag a feletteshez rendelve.',
		not_enough_bracelet: 'Nincs ennyi szabad karszalag ebből a típusból:',
		redundant_parking_slot_error: 'Ugyanaz az esemény - parkoló limitet nem lehet kétszer menteni.',
		ERROR: {
			active_org_missing: 'Teszt itt kapom vissza hogy active_org_missing',
		},
		NEWPASSWORDMODAL: {
			SUCCESS: 'Jelszó beállítás sikeres',
			ERROR: '',
			TITLE: 'Új jelszó beállítás',
			SEND: 'Küldés',
			CLOSE: 'Bezár',
			PLACEHOLDER: 'Új jelszó',
			LONGTEXT: 'Kérem adja meg új jelszavát'
		},
		PASSGROUP: {
			TITLE: 'Pass csoportos letöltés',
			SEND: 'Küldés',
			CLOSE: 'Bezár',
			SUCCESS: 'Ide kell szöveg',
			ERROR: 'Valami hiba történt'
		},
		ANONIMPASS: {
			SUCCESS: 'A letöltési linket elküldtük az e-mail címére.',
			ERROR: 'és ide?'
		},
		LANGUAGESELECTOR: {
			HUN: 'Magyar',
			ENG: 'English'
		},
		REGISTER: {
			BADLINK: 'Hibás link',
			NATIONAL: 'Állampolgárság',
			IDENTITYTYPE: 'Személyi okmány típusa',
			ID: 'Személyazonosító igazolvány',
			NUMBER: 'Okmányazonosító',
			PASSPORT: 'Útlevél',
			TITLE: 'Regisztráció',
			ALREADY: 'Van már fiókom!',
			INFOTEXT: 'A beírt adatokat kizárólag\n' +
				'                              az\n' +
				'                              adott szolgáltatáshoz használjuk fel, bizalmasan kezeljük,\n' +
				'                              és\n' +
				'                              nem adjuk ki! A Regisztráció gombra kattintva elfogadod\n' +
				'                              Felhasználási feltételeinket, Adatkezelési szabályzatunkat\n' +
				'                              és a\n' +
				'                              cookie-k használatára vonatkozó szabályzatunkat.',
			REQUIRED: 'A csillagos mezők kitöltése kötelező.',
			PERSONALDATA: ' Személyes adatok:',
			FIRSTNAME: 'Vezetéknév',
			TUTELARY: 'Kötelező gondviselő email cím megadása',
			LASTNAME: 'Keresztnév',
			FIRSTNAMEERROR: 'A\n' +
				'                                vezetéknév\n' +
				'                                nem tartalmazhat speciális\n' +
				'                                karaktereket, csak kötőjelet, és minimum 3 és maximum 15\n' +
				'                                karakter hosszúságú',
			LASTNAMEERROR: 'A\n' +
				'                                keresztnév\n' +
				'                                nem tartalmazhat speciális\n' +
				'                                karaktereket, csak kötőjelet, és minimum 3 és maximum 20\n' +
				'                                karakter hosszúságú!',
			MOTHERNAME: 'Anyja neve',
			BIRTHPLACE: 'Születési hely',
			BIRTHDATE: 'Születési idő',
			CLICKHEREPLACEHOLDER: 'Kérem kattintson ide a születési idő beállításához',
			DATATOACCESS: 'Belépéshez szükséges adatok:',
			EMAIL: 'E-mail cím',
			EMAILRE: 'E-mail cím újra',
			EMAILNOTMATCH: 'A két megadott e-mail nem egyezik!',
			EMAILERROR: 'Az email cím\n' +
				'                                nem\n' +
				'                                tartalmazhat ékezetet és nagybetűt, minimum 3 és maximum\n' +
				'                                15\n' +
				'                                karakter hosszú lehet!\n' +
				'                                Kérem valós email címet adjon meg!',
			PASSWORD: 'Jelszó ',
			PASSWORDRE: 'Jelszó újra',
			PASSWORDNOTMATCH: 'A két megadott jelszó nem egyezik!',
			PASSWORDSTRENGTH: 'A jelszó minimum 8 karakter hosszú, tartalmaznia kell kis és nagybetűt, valamint számot.',
			REQUIREDIMAGE: 'Feltölteni kívánt kép (kötelező *):',
			IMAGETOOLTIP: 'Kérem válassza ki a feltölteni kívánt képet!',
			// PRIVACYPOLICYFIRST: 'Regisztrációmmal elfogadom az Adatvédelmi nyilatkozat-ot.',
			//„Regisztrációmmal kijelentem, hogy megismertem és megértettem az Adatkezelési tájékoztatót. Az Adatkezelési tájékoztatót IDE kattintva elolvashatja.”
			PRIVACYPOLICYFIRST: 'Regisztrációmmal kijelentem, hogy megismertem és megértettem az Adatkezelési tájékoztatót.',
			PRIVACYPOLICYSECOND: 'Az Adatkezelési tájékoztatót IDE kattintva elolvashatja.',
			PRIVACYPOLICYLOGIN: 'Kijelentem, hogy megismertem és megértettem az Adatkezelési tájékoztatót.',
			REGISTRATION: 'Regisztráció',
			PLEASELOGIN: 'Kérem jelentkezzen be felhasználónevével és jelszavával:',
			ALREADYEMAIL: 'E-mail cím',
			ALREADYEMAILERROR: 'Az email cím\n' +
				'\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tnem\n' +
				'\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\ttartalmazhat ékezetet és nagybetűt, minimum 3 és maximum\n' +
				'\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t15\n' +
				'\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tkarakter hosszú lehet!\n' +
				'\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tKérem valós email címet adjon meg!',
			LOGIN: 'Bejelentkezés',
			BACK: 'Vissza',
			TUTELARYEMAIL: 'Gondviselő e-mail címe'
		},
		AUTH: {
			INPUT: {
				EMAIL: 'E-mail',
				PASSWORD: 'Jelszó'
			}
		},
		ABOUTUS: {
			SAVEABOUTUS: 'Bemutatkozó szöveg mentése',
			ABOUTUS: 'Rólunk',
			MODIFYSAVE: 'Módosítások mentése'
		},
		ARMBAND: {
			QUICKSEARCH: 'Gyorskeresés:',
			TITLE: 'Karszalagok',
			BUTTONTOOLTIP: 'Új karszalag hozzáadása',
			BUTTONTITLE: 'Hozzáadás',
			DATATABLECLEAR: 'Nem található rekord!',
			ARMBANDNAME: 'Karszalag neve',
			COLOR: 'Szín',
			ACTION: 'Művelet',
			EDIT: 'Szerkesztés',
			DELETECOLOR: 'Szín törlése'
		},
		NEWANDEDITARMBAND: {
			EDIT: 'Karszalag szerkesztése',
			ADD: 'Új karszalag hozzáadása',
			NAME: 'Karszalag neve: ',
			COLOR: 'Szín: ',
			PLACEHOLDER: 'ide kattintva válassza ki a színt',
			CANCEL: 'Mégsem',
			PASSWORD: 'Jelszó: ',
			CLOSE: 'Bezár',
			SAVE: 'Mentés'
		},
		LOGIN: {
			LOGIN: 'Bejelentkezés',
			LANGUAGEDESC: 'Kérem válasszon nyelvet',
			HUN: 'Magyar',
			ENG: 'English',
			PLEASELOGIN: 'Felhasználói adatinak megadásával kérem jelentkezzen be!',
			EMAIL: 'E-mail cím',
			REQUIRED: 'Email cím megadása kötelező',
			WRONGEMAIL: 'Hibás email cím formátum',
			PASSWORD: 'Jelszó',
			PASSWORDREQUIRED: 'Jelszó megadása kötelező',
			FORGOTTENPASSWORD: 'Elfelejtett jelszó',
			PRIVACYPOLICY: 'Adatkezelés és adatvédelem',
			ABOUTUS: 'Rólunk',
			BUTTON: 'BEJELENTKEZÉS'
		},
		FORGOTTENPASSWORD: {
			FORGOTTEN: 'Elfelejtett jelszó',
			FORGOTTENPASS: 'Elfelejtetted jelszavad?',
			LONGTEXT: 'Elfelejtetted jelszavad?\n' +
				'\t\t\t\t\tKérjük, add meg a regisztrált e-mail címet, és emailben elküldjük a további tudnivalókat!',
			NOTMATCH: 'A megadott e-mail címre kiküldtük a további teendőkhöz szükséges teendőket!',
			PLACEHOLDER: 'E-mail cím',
			NEWPASSWORD: 'Új jelszó igénylés'
		},
		LOGINABOUTUS: {
			ABOUTUS: 'Rólunk',
			CLOSE: 'Bezárás'
		},
		LOGINPRIVACY: {
			PRIVACY: 'Adatkezelési és adatvédelmi szabályzat',
			CLOSE: 'Bezárás'
		},
		CONFIRMDIALOG: {
			NEEDPASSWORD: 'Törléshez szükséges jelszó: ',
			CANCEL: 'Mégsem'
		},
		CROPPERDIALOG: {
			TITLE: 'Kép körbevágása',
			ROTATE: 'Forgatás',
			CANCEL: 'Mégsem',
			CROP: 'Kivág'
		},
		EVENTS: {
			QUICKSEARCH: 'Gyorskeresés:',
			EVENTS: 'Események',
			NEWEVENTS: 'Új esemény hozzáadása',
			ADD: 'Hozzáadás',
			DATATABLECLEAR: 'Nem található rekord!',
			EVENTSNAME: 'Esemény neve',
			EVENTSSTART: 'Esemény kezdete',
			EVENTSEND: 'Esemény vége',
			ACTION: 'Művelet',
			EVENTSDATATABLEEDIT: 'Esemény adatlap szerkesztése',
			DELETEEVENTS: 'Esemény törlése',
			INVITELINK: 'Meghívó linkek',
			CIRCLEEMAIL: 'Köremail küldése',
			DOWNLOADVOUCHER: 'Voucher letöltése',
			INVITEVOUCHERDOWNLOAD: 'Meghívó voucher letöltése',
			INVITEPARKINGDOWNLOAD: 'Parkoló letöltése'
		},
		EVENTSDATA: {
			PASSGROUP: 'Pass csoportos letöltés',
			PARKINGVOUCHERDOWNLOAD: 'Parkoló voucher letöltése',
			ANONIMPASS: 'Anonim pass',
			EDITEVENTS: 'Esemény szerkesztése',
			ALLPASSDOWNLOAD: 'Összes pass letöltése',
			EXPORT: 'Export',
			INVITELINK: 'Meghívó linkek',
			RESET: 'Elvet',
			SAVE: 'Mentés',
			EVENTNAME: 'Esemény neve: ',
			STARTDATE: 'Kezdő dátum: ',
			ENDDATE: 'Végdátum: ',
			STARTTIME: 'Kezdés időpont: ',
			ENDTIME: 'Befejezés időpont: ',
			PASSES: 'PASS-ok',
			NARROW: 'Szűkítés',
			DATATABLECLEAR: 'Nem található rekord!',
			ADDED: 'Hozzáadott',
			PASS: 'Pass',
			ARMBANDS: 'Karszalagok',
			ARMBAND: 'Karszalag',
			PARKINGS: 'Parkolók',
			PARKING: 'Parkoló',
			DOWNLOAD: 'Letöltés'
		},
		NEWEVENT: {
			EDITEVENT: 'Esemény szerkesztése',
			NEWEVENT: 'Új esemény hozzáadása',
			EVENTNAME: 'Esemény neve: ',
			STARTDATE: 'Kezdő dátum: ',
			ENDDATE: 'Végdátum: ',
			STARTTIME: 'Kezdés időpont: ',
			ENDTIME: 'Befejezés időpont: ',
			ALLPASSDOWNLOAD: 'Összes pass letöltése',
			INVITESLINK: 'Meghívó linkek',
			CLOSE: 'Bezár',
			MAKE: 'Létrehozás',
			SAVE: 'Mentés'
		},
		SCOPEMAIL: {
			CIRCLETITLE: 'Kör e-mail küldés! Esemény neve:',
			SUBJECT: 'Tárgy: ',
			DESC: 'Törzs: ',
			CLOSE: 'Bezár',
			SEND: 'Küldés'
		},
		HELPER: {
			HELPER: 'Segédletek',
			NEWHELPERPLACEHOLDER: 'Új segédlet hozzáadása',
			ADD: 'Hozzáadás',
			DATATABLECLEAR: 'Nem található rekord!',
			HELPERNAME: 'Segédlet neve',
			DESC: 'Leírás',
			ACTION: 'Művelet',
			EDIT: 'Szerkesztés',
			DELETEHELPER: 'Segédlet törlése',
			PLAYHELPER: 'Segédlet lejátszása'
		},
		NEWHELPER: {
			EDIT: 'Segédlet szerkesztése',
			NEWHELPER: 'Új Segédlet hozzáadása',
			HELPERNAME: 'Segédlet neve: ',
			DESC: 'Leírás: ',
			URL: 'URL: ',
			CLOSE: 'Bezár',
			SAVE: 'Mentés'
		},
		PLAYHELPER: {
			HELPER: 'Segédlet'
		},
		INFORMATION: {
			TITLE: 'Információk',
			ROWCLICK: 'Sorra kattintva további információk érhetők el az eseményről.'
		},
		INFORMATIONDATA: {
			EVENTSNAME: 'Esemény neve:',
			START: 'Kezdés:',
			END: 'Befejezés:',
			ALLPASSTYPE: 'Összes pass típus',
			ALLARMBANDTYPE: 'Összes karszalag típus',
			ALLPARKINGTYPE: 'Összes parkoló típus',
			PASSES: 'Pass-ok',
			ADDEDPERREDEMPTION: 'Kiadott/beváltott:',
			ARMBANDS: 'Karszalagok',
			PARKINGS: 'Parkolók'
		},
		INVITED: {
			INVITERPERSON: 'Meghívó',
			QUICKSEARCH: 'Gyorskeresés:',
			INVITEDLIST: 'Meghívottak listája',
			DATATABLECLEAR: 'Nem található rekord!',
			USERNAME: 'Felhasználónév',
			ACTIVATE: 'Kijelöltek aktiválása',
			FIRSTNAME: 'Vezetéknév',
			LASTNAME: 'Keresztnév',
			EVENTSNAME: 'Esemény neve',
			NICKNAME: 'Nicknév',
			ACTION: 'Művelet',
			ACTIVATETOOLTIP: 'Aktiválás',
			DISCARD: 'Elutasítás',
			SETUPNICKNAME: 'Nicknév beállítás'
		},
		INVITEDACTIVATE: {
			ACTIVATEUSER: 'felhasználó aktiválása',
			ACTIVATE: 'Felhasználók kötegelt aktiválása',
			LONGTEXT: 'Kérem a legördülő menübe válassza ki, hogy a felhasználót mely eseményre aktiválja majd ezt\n' +
				'\t\t\t\t\t\tkövetően, a karszalagot vagy a pass-t!',
			NOCHOOSE: 'Nincs kiválasztva',
			ARMBAND: 'Karszalag',
			PASS: 'Pass',
			PARKING: 'Parkoló',
			NOTPARKING: 'Nem kell parkoló',
			UPLOADEDIMAGE: 'Feltöltött profilkép',
			WRONGIMAGEREPORT: 'Hibás kép jelentése',
			ACTIVATEBUTTON: 'Aktiválás'
		},
		ACTIVATEMODAL: {
			ACTIVATE: 'Aktiválás',
			PLEASEADD: 'Kérem, válassza ki, a karszalagot vagy a pass-t!',
			EVENT: 'Esemény',
			NOCHOOSE: 'Nincs kiválasztva',
			PASS: 'Pass',
			ARMBAND: 'Karszalag',
			PARKING: 'Parkoló',
			DATATABLECLEAR: 'Nem található rekord!',
			ADDED: 'Hozzáadott',
			ACTIVATEBUTTON: 'Aktiválás'
		},
		NICKNAME: {
			EDIT: 'Nicknév szerkesztése',
			ADD: 'Nicknév hozzáadása',
			NICKNAME: 'Nicknév ',
			CLOSE: 'Bezár',
			SAVE: 'Mentés'
		},
		MAILTEMPLATE: {
			PASSGENERATOR: 'Pass generáló template beállítás',
			WRONGPROFILE: 'Hibás profilkép template beállítás',
			DENIEDUSER: 'Elutasító email template beállítás',
			TEMPLATESETUP: 'E-mail template beállítás',
			REGEMAILTEDIT: 'Regisztrációs e-mail template szerkesztése',
			SUBJECT: 'Tárgy: ',
			BODY: 'Törzs: ',
			REEMAIL: 'Visszaigazoló e-mail template szerkesztése',
			SAVE: 'Mentés',
			ALREADYMEMBER: '"Már tag vagyok" e-mail template szerkesztése',
			PARKINGTEMPLATE: 'Parkoló e-mail template szerkesztése',
			CLOSE: 'Close',
			FORGOTTENPASSWORD: 'Elfelejtett jelszó template szerkesztése'
		},
		MYLINKS: {
			TITLE: 'Linkjeim',
			NOTFOUND: 'Ennek a felhasználónak nincs voucher rendelve az eseményéhez!',
			CLOSE: 'Bezár'
		},
		PARKING: {
			QUICKSEARCH: 'Gyorskeresés:',
			PARKINGS: 'Parkolók',
			VOUCHERADDPLACEHOLDER: 'Új voucher hozzáadása',
			ADD: 'Hozzáadás',
			DATATABLECLEAR: 'Nem található rekord!',
			PARKINGNAME: 'Parkoló neve',
			DESCRIPTION: 'Leírás',
			ACTIONS: 'Művelet',
			EDIT: 'Szerkesztés',
			DELETEPARKING: 'Parkoló törlése',
			DOWNLOADPARKING: 'Parkoló letöltése'
		},
		NEWPARKING: {
			EDIT: 'Parkoló szerkesztése',
			ADD: 'Új Parkoló hozzáadása',
			PARKINGNAME: 'Parkoló neve: ',
			COLOR: 'Szín: ',
			CLICKHERE: 'ide kattintva válassza ki a színt',
			CANCEL: 'Mégsem',
			PASSWORD: 'Jelszó: ',
			CLOSE: 'Bezár',
			SAVE: 'Mentés'
		},
		PASSES: {
			QUICKSEARCH: 'Gyorskeresés:',
			NEWPASS: 'Új pass hozzáadása',
			TITLE: 'Pass-ok',
			ADD: 'Hozzáadás',
			DATATABLECLEAR: 'Nem található rekord!',
			NAME: 'Megnevezés',
			COLOR: 'Szín',
			INFORMATION: 'Információ',
			ACTIONS: 'Művelet',
			EDIT: 'Szerkesztés',
			PASSREVIEW: 'Pass előnézet',
			PASSDELETE: 'Pass törlése'
		},
		NEWANDEDITPASS: {
			CODE: 'Kód:',
			PASSEDIT: 'Pass szerkesztése',
			NEWPASSADD: 'Új pass hozzáadása',
			PASSNAME: 'Pass neve: ',
			COLOR: 'Szín: ',
			CLICKHERE: 'ide kattintva válassza ki a színt',
			CANCEL: 'Mégsem',
			TYPE: 'Típus: ',
			TRADITIONAL: 'Hagyományos',
			SEKTOR: 'Szektoros',
			REQUIREDTITLE: 'Legalább egy szektor megjelölése kötelező!',
			INFORMATION: 'Információ: ',
			SECINFORMATION: 'Információ (2): ',
			BACKGROUND: 'Háttérkép',
			TOOLTIP: 'Kérem válassza ki a feltölteni kívánt képet!',
			PASSTEXTCOLOR: 'Pass szöveg szín: ',
			PROFILECOLOR: 'Profil mező betűszín: ',
			CAPITALIZED: 'Nagybetűs?: ',
			ORGANISATIONSEC: 'Szervezet név első sor: ',
			ORGANISATIONSECOND: 'Szervezet név második sor: ',
			ORGANISATIONTHREE: 'Szervezet név harmadik sor: ',
			ORGANISATIONFOUR: 'Szervezet név negyedik sor: ',
			PASSWORD: 'Jelszó: ',
			CLOSE: 'Bezár',
			CREATE: 'Létrehozás',
			SAVE: 'Mentés'
		},
		PRIVACYPOLICYMENU: {
			TITLE: 'Adatkezelési és adatvédelmi szabályzat',
			SAVE: 'Szabályzat mentése',
			MODIFYSAVE: 'Módosítások mentése'
		},
		PROFILE: {
			IDTYPE: 'Személyi okmány típusa',
			IDNUMBER: 'Okmányazonosító',
			NATIONALITY: 'Állampolgárság',
			MODIFY: 'Adatok módosítása',
			OWNDATA: 'Saját adatok:',
			FIRSTNAME: 'Vezetéknév',
			FIRSTNAMERROR: 'A vezetéknév nem\n' +
				'\t\t\t\t\t\t\t\t\t\t\t\ttartalmazhat speciális\n' +
				'\t\t\t\t\t\t\t\t\t\t\t\tkaraktereket, csak kötőjelet, és minimum 3 és maximum 15 karakter\n' +
				'\t\t\t\t\t\t\t\t\t\t\t\thosszúságú',
			LASTNAME: 'Keresztnév',
			LASTNAMEERROR: 'A keresztnév nem\n' +
				'\t\t\t\t\t\t\t\t\t\t\t\ttartalmazhat speciális\n' +
				'\t\t\t\t\t\t\t\t\t\t\t\tkaraktereket, csak kötőjelet, és minimum 3 és maximum 20 karakter\n' +
				'\t\t\t\t\t\t\t\t\t\t\t\thosszúságú',
			MOTHERNAME: 'Anyja neve',
			TUTELARY: 'Gondviselő neve',
			BIRTHPLACE: 'Születési hely',
			BIRTHDATE: 'Születési idő',
			BIRTHTEXT: 'Kérem kattintson idő a születési idő beállításához',
			PROFILEIMAGE: 'Profil kép:',
			DELETEPROFILE: 'Profil törlése',
			SAVEDATA: 'Adatok mentése',
			PASSWORDMODIFY: 'Jelszó módosítása:',
			OLDPASSWORD: 'Régi jelszó: *',
			NEWPASSWORD: 'Új jelszó: *',
			NEWPASSWORDAGAIN: 'Új jelszó mégegyszer: *',
			NOTMATCH: 'A két megadott jelszó nem egyezik!',
			PASSERROR: 'A jelszó minimum 8 karakter hosszú, tartalmaznia kell kis és nagybetűt, valamint számot.',
			SAVEPASS: 'Jelszó módosítása'
		},
		REDEMPTION: {
			SEARCH: 'Kérem adja meg a keresési feltételt a kereséshez:',
			REDEMPTION: 'Beváltás',
			FIRSTNAME: 'Vezetéknév',
			DATATABLECLEAR: 'Nem található rekord!',
			LASTNAME: 'Keresztnév',
			EMAIL: 'E-mail',
			ACTIONS: 'Művelet'
		},
		REDEMPTIONDETAILS: {
			IDTYPE: 'Személyi okmány típusa',
			IDNUMBER: 'Okmányazonosító',
			NATIONALITY: 'Állampolgárság',
			DATA: 'Adatok',
			FIRSTNAME: 'Vezetéknév ',
			LASTNAME: 'Keresztnév',
			MOTHERNAME: 'Anyja neve',
			BIRTHPLACE: 'Születési hely',
			BIRTHDATE: 'Születési idő',
			BIRTHTEXT: 'Kérem kattintson idő a születési idő beállításához',
			PASSESARMANDS: 'Elérhető pass-ok és karszalagok',
			DATATABLECLEAR: 'Nem található rekord!',
			IMAGECHANGE: 'Kép csere',
			EVENTNAME: 'Esemény neve',
			TYPE: 'Típus',
			ARMBANDSCOLOR: 'Karszalag szín',
			ACTION: 'Művelet',
			PASSDOWNLOAD: 'PASS letöltése',
			REDEMPTION: 'Beváltás'
		},
		REDEMPTIONCHECKDATA: {
			TITLE: 'Kérem ellenőrizze az adatok helyességét',
			FIRSTNAME: 'Vezetéknév',
			FIRSTNAMEERROR: 'A vezetéknév nem tartalmazhat speciális\n' +
				'\t\t\t\t\tkaraktereket, csak kötőjelet, és minimum 3 és maximum 15 karakter hosszúságú',
			LASTNAME: 'Keresztnév',
			LASTNAMEERROR: 'A keresztnév nem tartalmazhat speciális\n' +
				'\t\t\t\t\tkaraktereket, csak kötőjelet, és minimum 3 és maximum 15 karakter hosszúságú',
			MOTHERNAME: 'Anyja neve',
			BIRTHPLACE: 'Születési hely',
			BIRTHDATE: 'Születési idő',
			BIRTHTEXT: 'Kérem kattintson idő a születési idő beállításához',
			CLOSE: 'Bezár',
			SAVEANDDOWNLOAD: 'Adatok rendben, letöltés!'
		},
		USERIMPORT: {
			TITLE: 'Csoportos regisztráció',
			CHOOSEEVENT: 'Válassza ki az eseményt ',
			CHOOSETYPE: 'Válassza ki a típust: ',
			PASS: 'Pass',
			ARMBANDS: 'Karszalag',
			CHOOSEPASS: 'Válassza ki a pass-t ',
			UNCHOOSED: 'Nincs kiválasztva',
			CHOOSEARMBANDS: 'Válassza ki a karszalagot-t ',
			CHOOSEPARKING: 'Válassza ki a parkolót-t ',
			CHOOSEFILE: 'Válassza ki a file-t ',
			SEND: 'Beküldés'
		},
		USERS: {
			RADIOBUTTONPASSINFORMATION: 'Pass információ',
			BEVALTVA: 'Beváltva:',
			RADIOBUTTONYES: 'Igen',
			RADIOBUTTONNO: 'Nem',
			RADIOREQUIRED: 'Kötelező mező',
			RADIOBUTTONBRACELETINFORMATION: 'Karszalag információ',
			DEVELOPER: 'Fejlesztő',
			ADMIN: 'Admin',
			SUPERVISOR: 'Supervisor',
			USERROLE: 'User',
			CASHIER: 'Pénztáros',
			ALSUPERVISOR: 'Alsupervisor',
			QUICKSEARCH: 'Gyorskeresés:',
			CHOOSEEVENT: 'Válassza ki az eseményt ',
			ALLEVENT: 'Összes esemény',
			ALLPERSON: 'Összes személy',
			EVENTERROR: 'Esemény kiválasztása kötelező',
			INVITERPERSON: 'Válassza ki a meghívó személyét',
			PASSSEARCH: 'Válassza ki a pass-t',
			BRACELETSEARCH: 'Válassza ki a karszalagot',
			PARKINGSEARCH: 'Válassza ki a parkolót',
			PASSUSED: 'Pass beváltva',
			BRACELETUSED: 'Karszalag beváltva',
			USERS: 'Felhasználók',
			CREATE: 'Létrehozás',
			DATATABLECLEAR: 'Nem található rekord!',
			USER: 'Felhasználó',
			FIRSTNAME: 'Vezetéknév',
			LASTNAME: 'Keresztnév',
			ROLE: 'Jogosultság',
			ACTION: 'Művelet',
			USEREDIT: 'Felhasználó szerkesztése',
			DELETEUSER: 'Felhasználó törlése'
		},
		EVENTATT: {
			TITLE: 'Eseményhez rendelés',
			PLEASECHOOSE: 'Kérem, válassza ki, a karszalagot vagy a pass-t!',
			EVENT: 'Esemény',
			UNCHOOSED: 'Nincs kiválasztva',
			SEARCHPLACEHOLDER: 'Találatok szűkítése',
			PASS: 'Pass',
			ARMBANDS: 'Karszalag',
			CLOSE: 'Bezár',
			BUTTONTITLE: 'Eseményhez rendelés'
		},
		INVITESF: {
			INFILE: 'Meghívók küldése file-ból',
			CLOSE: 'Bezár',
			SEND: 'Meghívók kiküldése'
		},
		USERSNEW: {
			INVITERPERSON: 'Meghívó',
			IDTYPE: 'Személyi okmány típusa',
			IDNUMBER: 'Okmányazonosító',
			NATIONALITY: 'Állampolgárság',
			SUCCESSDENIED: 'A felhasználót az eseményről sikeresen tiltotta',
			BLOCK: 'Tiltás!',
			CANCEL: 'Elvet',
			EDIT: 'Felhasználó szerkesztése',
			ADDNEW: 'Új felhasználó hozzáadása',
			ACTIONS: 'Művelet',
			EVENTBUTTON: 'Eseményhez rendelés',
			PARKINGBUTTON: 'Parkoló',
			PASSBUTTON: 'PASS',
			PARKINGSBUTTON: 'Karszalag',
			VOUCHERBUTTON: 'Voucher',
			SAVE: 'Mentés',
			CREATE: 'Létrehozás',
			USERNAME: 'Felhasználónév',
			USERNAMEERROR: 'Kérem felhasználónévnek az email címét adja\n' +
				'\t\t\t\t\t\t\t\tmeg! Az\n' +
				'\t\t\t\t\t\t\t\temail cím nem tartalmazhat ékezetet és nagybetűt, minimum 3 és maximum 15 karakter\n' +
				'\t\t\t\t\t\t\t\thosszú lehet!\n' +
				'\t\t\t\t\t\t\t\tKérem valós email címet adjon meg!',
			PASSWORD: 'Jelszó',
			ROLE: 'Jogosultság',
			FIRSTNAME: 'Vezetéknév',
			FIRSTNAMEERROR: 'A vezetéknév nem tartalmazhat speciális\n' +
				'\t\t\t\t\t\t\t\tkaraktereket, csak kötőjelet, és minimum 3 és maximum 15 karakter hosszúságú',
			LASTNAME: 'Keresztnév',
			LASTNAMEERROR: 'A keresztnév nem tartalmazhat speciális\n' +
				'\t\t\t\t\t\t\t\tkaraktereket, csak kötőjelet, és minimum 3 és maximum 20 karakter hosszúságú',
			MOTHERNAME: 'Anyja neve',
			BIRTHPLACE: 'Születési hely',
			BIRTHDATE: 'Születési idő',
			TUTELARY: 'Gondviselő neve',
			BIRTHTEXT: 'Kérem kattintson idő a születési idő beállításához',
			NICKNAME: 'Nicknév',
			IMAGE: 'Kép',
			REQUIREDFIELD: 'A csillagos (*) mezők kitöltése kötelező.',
			DATATABLECLEAR: 'Nem található rekord!',
			EVENTNAME: 'Esemény neve',
			TICKET: 'Belépő',
			MODIFYTICKET: 'Belépő módosítás'
		},
		BITEM: {
			TITLE: 'Belépő módosítás',
			MODIFY: 'A felhasználónak jelenleg ez a belépője:',
			PASS: 'Pass',
			ARMBAND: 'Karszalag',
			PARKINGS: 'Parkoló',
			DELETEPARKING: 'Parkoló törlése',
			CLOSE: 'Bezár',
			MODIFYBUTTON: 'Módosítás'
		},
		USERBRACELETS: {
			TITLE: 'Felhasználói karszalagok',
			EVENTNAME: 'Esemény neve',
			NOTCHOOSE: 'Nincs kiválasztva',
			ARMBANDS: 'Karszalag',
			QUANTITY: 'Darabszám',
			FUNCTIONS: 'Funkciók',
			CLOSE: 'Bezár',
			SAVE: 'Mentés'
		},
		USERPARKING: {
			TITLE: 'Felhasználói parkolók',
			EVENTNAME: 'Esemény neve',
			NOTCHOOSE: 'Nincs kiválasztva',
			PARKINGS: 'Parkoló',
			QUANTITY: 'Darabszám',
			FUNCTIONS: 'Funkciók',
			CLOSE: 'Bezár',
			SAVE: 'Mentés'
		},
		USERPASS: {
			TITLE: 'Felhasználói pass-ok',
			EVENTNAME: 'Esemény neve',
			NOTCHOOSE: 'Nincs kiválasztva',
			PASSES: 'Pass-ok',
			QUANTITY: 'Darabszám',
			FUNCTIONS: 'Funkciók',
			CLOSE: 'Bezár',
			SAVE: 'Mentés'
		},
		USERPERMISSION: {
			TITLE: 'Jogosultság beállítása',
			EVENTNAME: 'Esemény neve',
			NOTCHOOSE: 'Nincs kiválasztva',
			VOUCHER: 'Voucher',
			FUNCTIONS: 'Funkciók',
			SAVEPERMI: 'Jogosultságok mentése'
		},
		SETPERMIS: {
			NOTCHOOSE: 'Nincs kiválasztva',
		},
		VOUCHERS: {
			QUICKSEARCH: 'Gyorskeresés:',
			TITLE: 'Voucherek',
			NEWVOUCHERPLACE: 'Új voucher hozzáadása',
			ADD: 'Hozzáadás',
			DATATABLECLEAR: 'Nem található rekord!',
			VOUCHERNAME: 'Voucher neve',
			DESCRIPT: 'Leírás',
			ACTION: 'Művelet',
			EDIT: 'Szerkesztés',
			VOUCHERDELETE: 'Voucher törlése',
			VOUCHERDOWNLOAD: 'Voucher letöltése'
		},
		NEWVOUCHER: {
			EDIT: 'Voucher szerkesztése',
			NEW: 'Új voucher hozzáadása',
			VOUCHERNAME: 'Voucher neve: ',
			PASSWORD: 'Jelszó: ',
			CLOSE: 'Bezár',
			SAVE: 'Mentés'
		},
		ORGANISATION: {
			QUICKSEARCH: 'Gyorskeresés:',
			TITLE: 'Szervezetek',
			MATTABLETITLE: 'Szervezet neve',
			ACTIONS: 'Művelet',
			ADDTOOLBAR: 'Új szervezet hozzáadása',
			ADD: 'Hozzáadás',
			DATATABLECLEAR: 'Nem található rekord!',
			ORGANISATIONDATATABLEEDIT: 'Szervezet adatlap szerkesztése',
			DELETEORGANISATION: 'Szervezet törlése',
		},
		NEWORGANISATION: {
			NEWORGANISATION: 'Új szervezet hozzáadása',
			EDITORGANISATION: 'Szervezet szerkesztése',
			ORGANISATIONNAME: 'Szervezet neve: ',
			CLOSE: 'Bezár',
			MAKE: 'Létrehozás',
			SAVE: 'Mentés',
		},
		ANONYMPASS: {
			TITLE: 'Anonim pass',
			CLOSE: 'Bezár',
			SEND: 'Beküldés',
			LABEL: 'Pass-ok',
			NAME: 'Név',
			QUANTITY: 'Darabszám'
		},
		PARKINGVOUCHER: {
			TITLE: 'Parkoló voucher letöltése',
			CLOSE: 'Bezár'
		},
		MENUCONFIG: {
			ORGANISATION: 'Szervezetek',
			INFORMATION: 'Információk',
			EVENTS: 'Események',
			USERS: 'Felhasználók',
			INACTIVEUSERS: 'Inaktív felhasználók',
			VOUCHERS: 'Voucher-ek',
			PASSES: 'Pass-ok',
			ARMBANDS: 'Karszalagok',
			INVITED: 'Meghívottak',
			PARKING: 'Parkolók',
			ABOUTUS: 'Rólunk',
			PRIVACYPOL: 'Adatkezelés és adatvédelem',
			FAQ: 'Segédletek',
			GROUPREG: 'Csoportos regisztráció',
			REDEMPTION: 'Beváltás'
		},
		MESSAGES: {
			CONFIRMDOWNLOAD: 'Letöltés megerősítése',
			ALLPASSDOWNLOAD: 'Biztos benne, hogy leakarja tölteni az összes pass-t?',
			DISSMISSDOWNLOAD: 'Letöltés elutasítva',
			ABOUTUSSAVE: 'Rólunk leírást sikeresen elmentette',
			CONFIGPLACEHOLDER: 'Kérem adja meg a leírást!',
			DELETECONFIRM: 'Törlés megerősítése!',
			DELETEDESC: 'Biztosan törli a kiválasztott rekordot?',
			DISSMISS: 'Törlés elutasítva',
			DELETEVOUCHER: 'Sikeresen törölte a karszalagot-t',
			DELETEVENT: 'Sikeresesn törölte az eseményt',
			DEFAULTERROR: 'Hiba történt!',
			PDFGENERATE: 'A PDF generálás elindult, ha elkészült emailben tajekoztatjukl',
			UPDATEEVENT: 'Az eseményt sikeresen frissítette!',
			ERROR: 'Hiba',
			CREATEEVENT: 'Az eseményt sikeresen létrehozta',
			CREATEERROR: 'Hiba létrehozás közben',
			CREATEHELPER: 'A segédletet sikeresen hozzáadta',
			UPDATEHELPER: 'A segédletet sikeresen frissítette',
			CONFIRMREJECTION: 'Elutasítás megerősítése!',
			REJECTUSER: 'Biztosan elutasítja a felhasználót?',
			SUCCESSREJECT: 'Sikeresen elutasította a felhasználót',
			INVITATIONSMUSTBE: 'Minden kiválasztott meghívónak ugyanarra az eseményre kell szólnia!',
			REPORTIMAGESUCCESS: 'Sikeresen jelentette a hibás képet!',
			REPORTSENDINGERROR: 'Hiba jelentés kiküldésnél',
			SUCCESSACTIVEUSER: 'Sikeresen aktiválta a felhasználót',
			ERRORACTIVATE: 'Hiba aktiváláskor',
			NICKNAMESUCCESSCREATE: 'A nicknevet sikeresen létrehozta',
			REGTEMPLATE: 'A regisztrációs templatet sikeresen mentette',
			ERRORSAVING: 'Hiba mentéskor',
			CONFIRMTEMPLATE: 'A visszaigazoló templatet sikeresen mentette',
			SUCCESSDELETEORGANISATION: 'Sikeresen törölte a szervezetet',
			SUCCESSUPDATEORGANISATION: 'A szervezetet sikeresen frissítette',
			SUCCESSDELETEPARKING: 'Sikeresen törölte a parkolót',
			SUCCESSCREATORGAN: 'A szervezetet sikeresen létrehozta',
			SUCCESSDELETEPASS: 'Sikeresen törölte a pass-t',
			PASSADDEDSUCCESS: 'A pass hozzáadása sikeres volt',
			ADDINGPASSERROR: 'Hiba pass hozzáadása közben',
			UPDATEPASSERROR: 'A PASS-t sikeresen frissítette',
			NOTSECTOR: 'Nem választott ki szektort!',
			PLEASESPECIFYDATA: 'Kérem adja meg az adatkezelési és adatvédelmi szabályzatot!',
			SUCCESSSAVEPOLICY: 'A szabályzatot sikeresen elmentette',
			CONFIRMPROFILEDELETE: 'Biztosan törli szeretné profilját?',
			DATASUCCESSAVE: 'Adatok mentése sikeres volt',
			SUCCESSUPDATEIMAGE: 'Sikeresen módosította a képet',
			IMAGEREQUIRED: 'Kép megadása kötelező!',
			SUCCESSREGI: 'A regisztráció sikeres volt',
			ERRORONREGI: 'Hiba regisztráció során',
			ACTIVATEANDLOGIN: 'Sikeres bejelentkezés és meghívó aktiválás',
			SUCCESSUPLOADFILE: 'Sikeres file feltöltés!',
			SUCCESSADDEDEVENT: 'Sikeresen hozzáadta az eseményhez',
			USERSUCADDED: 'A felhasználót sikeresen hozzáadta',
			USERUPDATESUCCES: 'A felhasználó adatait sikeresen frissítette!',
			TICKETMODIFY: 'Sikeresen módosította a belépőt',
			SUCDELPARKING: 'Sikeresen törölte a parkolót',
			SUCSAVE: 'Sikeresen mentve',
			SETTINGERROR: 'Beállítási hiba',
			PERMISSIONSET: 'A jogosultságot/jogosultságokat sikeresen beállította',
			SUCDELETEVOUCHER: 'You have successfully deleted your voucher',
			VOUCHERPLACEHOLDER: 'Kérem adja meg a voucher leírást!',
			VOUCHERSUCCESSADDED: 'A vouchert sikeresen hozzáadta',
			SUCCESSUPDATEVOUHCER: 'A vouchert sikeresen frissítette'
		},
		USERPROFILE: {
			MYPROFILE: 'Profilom',
			DATAMODIFY: 'Profil adatok és fiókbeállítások',
			EMAIL: 'E-mail',
			EMAILTEMPLATEMODIFY: 'E-mail template beállítás',
			LOGOUT: 'Kijelentkezés'
		},
		FOOTER: {
			FEEDBACK: 'Rendszer működésével kapcsolatos visszajelzés: '
		},
		SPLASH: {
			LOADING: 'Kérem várjon...'
		},
		MENUHORIZONTAL: {
			TITLE: 'Szervezet: ',
			SELECTPLACEHOLDER: 'Válasszon szervezetet'
		},
		cookie: {
			header: 'Cookies used on the website!',
			message: 'Ez a weboldal sütiket használ, hogy a lehető legjobb élményt nyújtsa Önnek a weboldalon.',
			link: 'a',
			dismiss: 'Elfogadom'
		},
		INACTIVEUSERS: {
			TITLE: 'Inaktív felhasználók',
			NAME: 'Név',
			EMAIL: 'Email',
			DELETE: 'Törlés'
		}
	}
};
