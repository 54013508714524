// Angular
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
// Components
import {BaseComponent} from './base/base.component';
import {ErrorPageComponent} from './content/error-page/error-page.component';
import {AuthGuard} from '../../core/auth';
import {EventsComponent} from '../pages/events/events.component';
import {UsersComponent} from '../pages/users/users.component';
import {NewComponent} from '../pages/users/new/new.component';
import {VouchersComponent} from '../pages/vouchers/vouchers.component';
import {ArmbandComponent} from '../pages/armband/armband.component';
import {NgxPermission} from 'ngx-permissions/lib/model/permission.model';
import {NgxPermissionsGuard} from 'ngx-permissions';
import {RegisterComponent} from '../pages/register/register.component';
import {InvitedComponent} from '../pages/invited/invited.component';
import {UserPermisComponent} from '../pages/users/user-permis/user-permis.component';
import {PassesComponent} from '../pages/passes/passes.component';
import {PrivacypolicyComponent} from '../pages/privacypolicy/privacypolicy.component';
import {ProfileComponent} from '../pages/profile/profile.component';
import {EventsDataComponent} from '../pages/events/events-data/events-data.component';
import {RedemptionComponent} from '../pages/redemption/redemption.component';
import {DetailsComponent} from '../pages/redemption/details/details.component';
import {ParkingComponent} from '../pages/parking/parking.component';
import {ActivateComponent} from '../pages/invited/activate/activate.component';
import {AboutusComponent} from '../pages/aboutus/aboutus.component';
import {InformationComponent} from '../pages/information/information.component';
import {InformationDataComponent} from '../pages/information/information-data/information-data.component';
import {HelperComponent} from '../pages/helper/helper.component';
import {UserImportComponent} from '../pages/user-import/user-import.component';
import {OrganisationComponent} from '../pages/organisation/organisation.component';
import {ForgotPassComponent} from '../pages/auth/login/forgot-pass/forgot-pass.component';
import {LoginComponent} from '../pages/auth/login/login.component';
import {WhiteComponent} from '../pages/white/white/white.component';
import {InactiveUsersComponent} from '../pages/inactive-users/inactive-users.component';
// Auth

const routes: Routes = [
	{
		path: 'register',
		component: RegisterComponent,
		canActivate: []
	},
	{
		path: 'download',
		component: WhiteComponent,
		canActivate: []
	},
	// {
	// 	path: 'newPassword',
	// 	component: LoginComponent,
	// 	// data: {returnUrl: window.location.pathname},
	// 	canActivate: []
	// },
	{
		path: '',
		component: BaseComponent,
		canActivate: [AuthGuard, NgxPermissionsGuard],
		children: [
			{
				path: 'organisation',
				component: OrganisationComponent
			},
			{
				path: 'events',
				component: EventsComponent
			},
			{
				path: 'users',
				canActivate: [NgxPermissionsGuard],
				data: {
					ngxPermissions: {
						only: ['FEJLESZTO', 'ADMIN', 'SUPERVISOR', 'ALSUPERVISOR']
					}
				},
				component: UsersComponent
			},
			{
				path: 'users/:id',
				component: NewComponent
			},
			{
				path: 'activate/selected',
				component: ActivateComponent
			},
			{
				path: 'activate/:id/:eventId',
				component: ActivateComponent
			},
			{
				path: 'events/:id',
				component: EventsDataComponent
			},
			{
				path: 'userpermis/:id',
				component: UserPermisComponent
			},
			{
				path: 'redemption/:id',
				component: DetailsComponent
			},
			{
				path: 'vouchers',
				component: VouchersComponent,
				canActivate: [NgxPermissionsGuard],
				data: {
					permissions: {
						only: ['FEJLESZTO', 'ADMIN'],
						redirectTo: '/'
					}
				},
			},
			{
				path: 'passes',
				component: PassesComponent
			},
			{
				path: 'armband',
				component: ArmbandComponent
			},

			{
				path: 'invited',
				component: InvitedComponent
			},
			{
				path: 'inactiveusers',
				component: InactiveUsersComponent
			},
			{
				path: 'aboutus',
				component: AboutusComponent
			},
			{
				path: 'information',
				component: InformationComponent
			},
			{
				path: 'information/:id',
				component: InformationDataComponent
			},
			{
				path: 'helper',
				component: HelperComponent
			},
			{
				path: 'privacypolicy',
				component: PrivacypolicyComponent
			},
			{
				path: 'profile',
				component: ProfileComponent
			},
			{
				path: 'redemption',
				component: RedemptionComponent
			},
			{
				path: 'parking',
				component: ParkingComponent
			},
			{
				path: 'userimport',
				component: UserImportComponent
			},
			{path: 'error/:type', component: ErrorPageComponent},

			{path: '', redirectTo: 'events', pathMatch: 'full'},
			{path: '**', redirectTo: 'events', pathMatch: 'full'}
		]
	},

	{path: '**', redirectTo: 'error/403', pathMatch: 'full'}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class PagesRoutingModule {
}
