import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {UsersService} from '../users.service';
import {EventsService} from '../../events/events.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatTableDataSource} from '@angular/material';
import {BehaviorSubject} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UserPermisComponent} from '../user-permis/user-permis.component';
import {UserPassesComponent} from '../user-passes/user-passes.component';
import {UserBraceletsComponent} from '../user-bracelets/user-bracelets.component';
import moment from 'moment';
import {EventAttComponent} from '../event-att/event-att.component';
import {BItemComponent} from './b-item/b-item.component';
import {UserParkingComponent} from '../user-parking/user-parking.component';
import {CropperDialogComponent, dataURLtoFile} from '../../cropper-dialog/cropper-dialog.component';
import * as jwt_decode from 'jwt-decode';
import {TranslateService} from '@ngx-translate/core';
import {NgxSpinnerService} from 'ngx-spinner';
// const urlLogo = 'https://mlsz-defba.firebaseapp.com/assets/media/add_photo.png';
// const url = 'https://mlsz-defba.firebaseapp.com/assets/media/add_photo.png';

const url = '/assets/media/avatar.png';

export interface Events {
	id: string;
	name: string;
}

@Component({
	selector: 'kt-new',
	templateUrl: './new.component.html',
	styleUrls: ['./new.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default
})
export class NewComponent implements OnInit {
	@ViewChild('imagee', null) imagee: ElementRef;
	availableRoles$ = new BehaviorSubject([]);
	events = new MatTableDataSource<Events>();
	userID;
	url: string | ArrayBuffer = url;
	userDetail: any = {};
	// displayedColumns: string[] = ['checked', 'eventsName', 'pass', 'karszalag'];
	displayedColumns: string[] = ['eventsName', 'item', 'inviterName', 'actions'];
	pageSize = 50;
	page = 1;
	file: File;
	image: string | File = null;
	roleModify: boolean = false;
	nev = '';
	collection: number;
	userRoleName = new FormControl();
	rendezesirany = 'asc';
	roleName = new FormControl();
	joggomb: boolean = false;
	permission;
	currentYear = new Date();
	minDate = new Date(1901, 1, 1);
	maxDate = this.currentYear;
	nationals: string[] = [
		'Afghan', 'Albanian', 'Algerian', 'Argentine', 'Argentinian', 'Australian', 'Austrian', 'Bangladeshi', 'Belgian',
		'Bolivian', 'Batswana', 'Brazilian', 'Bulgarian', 'Cambodian', 'Cameroonian', 'Canadian', 'Chilean', 'Chinese',
		'Colombian', 'Costa Rican', 'Croatian', 'Cuban', 'Czech', 'Danish', 'Dominican', 'Ecuadorian', 'Egyptian',
		'Salvadorian', 'English', 'Estonian', 'Ethiopian', 'Fijian', 'Finnish', 'French', 'German', 'Ghanaian',
		'Greek', 'Guatemalan', 'Haitian', 'Honduran', 'Magyar', 'Icelandic', 'Indian', 'Indonesian', 'Iranian',
		'Iraqi', 'Irish', 'Israeli', 'Italian', 'Jamaican', 'Japanese', 'Jordanian', 'Kenyan', 'Kuwaiti',
		'Lao', 'Latvian', 'Lebanese', 'Libyan', 'Lithuanian', 'Malagasy', 'Malaysian', 'Malian', 'Maltese',
		'Mexican', 'Mongolian', 'Moroccan', 'Mozambican', 'Namibian', 'Nepalese', 'Dutch', 'New Zealand', 'Nicaraguan',
		'Nigerian', 'Norwegian', 'Pakistani', 'Panamanian', 'Paraguayan', 'Peruvian', 'Philippine', 'Polish',
		'Portuguese', 'Romanian', 'Russian', 'Saudi', 'Scottish', 'Senegalese', 'Serbian', 'Singaporean', 'Slovak',
		'South African', 'Korean', 'Spanish', 'Sri Lankan', 'Sudanese', 'Swedish', 'Swiss', 'Syrian', 'Taiwanese',
		'Tajikistani', 'Thai', 'Tongan', 'Tunisian', 'Turkish', 'Ukrainian', 'Emirati', 'British', 'American', 'Uruguayan',
		'Venezuelan', 'Vietnamese', 'Welsh', 'Zambian', 'Zimbabwean',
	];

	constructor(	private spinner: NgxSpinnerService,private translateService: TranslateService, private changeDetectorRef: ChangeDetectorRef, private modal: NgbModal, private modalService: NgbModal, private router: Router, private route: ActivatedRoute, private eventService: EventsService, private userService: UsersService, private toastr: ToastrService, private fb: FormBuilder) {
		var blob = null;
		var xhr = new XMLHttpRequest();
		xhr.open('GET', url);
		xhr.responseType = 'blob';
		xhr.onload = () => {
			const reader = new FileReader();
			reader.addEventListener('load', () => {
				this.url = reader.result;
			});
			reader.readAsDataURL(xhr.response);
		};
		xhr.send();
	}

	form = this.fb.group({

		email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
		password: ['', Validators.required],
		eventIds: [''],
		roleId: ['', Validators.required],
		firstname: ['', [Validators.required, Validators.pattern('^[a-zÁÉÚŐÓÜÖÍ .áéúőóűüöíA-Z_-]{3,15}$')]],
		lastname: ['', [Validators.required, Validators.pattern('^[a-zÁÉÚŐÓÜÖÍáé úőóűüöíA-Z_-]{3,20}$')]],
		birthDate: ['', Validators.required],
		birthPlace: ['', Validators.required],
		nickName: [],
		tutelaryName: [],
		idType: ['', Validators.required],
		idNumber: ['', Validators.required],
		nationality: ['', Validators.required],
	});


	ngOnInit() {
		const decoded = this.getDecodedAccessToken(localStorage.getItem('token'));
		const role = decoded.role;
		if (role == 'ADMIN' || role == 'FEJLESZTO') {
			this.roleModify = true;
		}
		this.userID = this.route.snapshot.paramMap.get('id');
		this.getAvailableRoles();
		this.getData();
	}


	getEvents() {

		this.eventService.getMyEvent().subscribe((res) => {
			this.events.data = res;
		});
	}

	compareRole = (option, selected) => option && selected && option === selected;

	changeToggle(id, event) {
		const formData = {
			eventId: id,
			userId: this.userID,
			denied: event.checked
		};
		this.userService.seteventAccess(formData).subscribe(res => {
			this.toastr.success(this.translateService.instant('USERSNEW.SUCCESSDENIED'), '');
		}, error => {
			this.toastr.error(error.error.message, '');
		});
	}

	getData() {
		if (this.userID != 'new') {
			const passwordController = this.form.get('password');
			passwordController.setValidators(null);
			this.userService.getUser(this.userID).subscribe((res) => {

				this.userDetail = res;
				this.events.data = res.events;
				this.form.setValue({
					email: res.email,
					password: null,
					eventIds: res.events,
					roleId: res.userRole.id,
					firstname: res.firstname,
					lastname: res.lastname,
					birthDate: res.birthDate,
					tutelaryName: res.tutelaryName,
					birthPlace: res.birthPlace,
					nickName: res.nickName,
					idType: res.idType,
					idNumber: res.idNumber,
					nationality: res.nationality
				});
				this.image = res.url;
				this.userRoleName.setValue(res.userRole.name);
				this.permission = res.userRole.name;
				if (res.userRole.name == 'SUPERVISOR' || res.userRole.name == 'ALSUPERVISOR') {
					this.setJogosultsagGomb();
				}

				this.userDetail.event = res.eventIds;
				if (!this.roleModify) {
					this.form.controls['roleId'].disable();
				}
				this.url = res.url;
			});
		}
	}


	onSelectFile(event) {
		const ref = this.modal.open(CropperDialogComponent, {
			ariaLabelledBy: 'modal-basic-title',
			size: 'lg'
		});
		ref.componentInstance.source = event;
		ref.result.then(result => {
			if (result) {
				this.url = result;
				this.image = dataURLtoFile(result, 'file.png');
				this.changeDetectorRef.detectChanges();
			}
		});

	}

	setJogosultsagGomb() {
		const decoded = this.getDecodedAccessToken(localStorage.getItem('token'));
		const role = decoded.role;
		if (role == 'ADMIN' || role == 'FEJLESZTO' || role == 'SUPERVISOR' || role == 'ALSUPERVISOR') {
			this.joggomb = true;
		}

	}

	setVoucher() {
		if (this.permission == 'SUPERVISOR' || this.permission == 'ALSUPERVISOR' || this.permission == 'ADMIN' || this.permission == 'FEJLESZTO') {
			return true;
		}
		return false;

	}

	public hasError = (controlName: string, errorName: string) => {
		return this.form.controls[controlName].hasError(errorName);
	};


	applyFilter(filterValue: string) {
		this.events.filter = filterValue.trim().toLowerCase();
	}

	sample(eventId) {
		const modalRef = this.modalService.open(BItemComponent, {
			size: 'lg',
			backdrop: 'static',
			keyboard: false
		});
		modalRef.componentInstance.id = this.userID;
		modalRef.componentInstance.eventId = eventId;
		modalRef.result.then(() => {
			this.getData();
		}).catch(() => {
		});
	}

	getDecodedAccessToken(token: string): any {
		try {
			return jwt_decode(token);
		} catch (Error) {
			return null;
		}
	}

	isSelected(row) {
		return this.userDetail.event && this.userDetail.event.find(x => {
			return x === row.id;
		});
	}

	userPermission() {
		const modalRef = this.modalService.open(UserPermisComponent, {
			size: 'lg',
			backdrop: 'static',
			keyboard: false
		});
		modalRef.componentInstance.id = this.userID;
		modalRef.result.then(() => {
			this.getData();
		}).catch(() => {
		});
	}

	check($event, element) {
		if (!this.userDetail.event) {
			this.userDetail.event = [];
		}
		if ($event.checked) {
			this.userDetail.event.push(element.id);
		} else {
			this.userDetail.event = this.userDetail.event.filter(event => {
				return element.id !== event;
			});
		}
	}

	userEvents() {
		const modalRef = this.modalService.open(EventAttComponent, {
			size: 'lg',
			backdrop: 'static',
			keyboard: false
		});
		modalRef.componentInstance.userId = this.userID;
		// modalRef.componentInstance.permission = this.permission;
		modalRef.result.then(() => {
			this.getData();
		}).catch(() => {
		});
	}

	userParking() {
		const modalRef = this.modalService.open(UserParkingComponent, {
			size: 'lg',
			backdrop: 'static',
			keyboard: false
		});
		modalRef.componentInstance.id = this.userID;
		modalRef.componentInstance.permission = this.permission;
		modalRef.result.then(() => {
			this.getData();
		}).catch(() => {
		});
	}

	userPasses() {
		const modalRef = this.modalService.open(UserPassesComponent, {
			size: 'lg',
			backdrop: 'static',
			keyboard: false
		});
		modalRef.componentInstance.id = this.userID;
		modalRef.componentInstance.permission = this.permission;
		modalRef.result.then(() => {
			this.getData();
		}).catch(() => {
		});
	}

	userVoucher() {
		const modalRef = this.modalService.open(UserPermisComponent, {
			size: 'lg',
			backdrop: 'static',
			keyboard: false
		});
		modalRef.componentInstance.userID = this.userID;
		modalRef.componentInstance.permission = this.permission;
		modalRef.result.then(() => {
			this.getData();
		}).catch(() => {
		});
	}

	userBracelets() {
		const modalRef = this.modalService.open(UserBraceletsComponent, {
			size: 'lg',
			backdrop: 'static',
			keyboard: false
		});
		modalRef.componentInstance.id = this.userID;
		modalRef.componentInstance.permission = this.permission;
		modalRef.result.then(() => {
			this.getData();
		}).catch(() => {
		});
	}

	getAvailableRoles() {
		this.userService.getAvailableRoles().subscribe((res) => {
			this.availableRoles$.next(res.body);
		});
	}

	save() {
		this.spinner.show();
		const birth = moment(this.form.controls['birthDate'].value).format('YYYY-MM-DD');
		this.form.controls['birthDate'].setValue(birth);
		this.form.controls['eventIds'].setValue(this.userDetail.event);
		const formData = new FormData();
		formData.append('email', this.form.controls['email'].value);
		formData.append('firstname', this.form.controls['firstname'].value);
		formData.append('lastname', this.form.controls['lastname'].value);
		formData.append('roleId', this.form.controls['roleId'].value);
		formData.append('password', this.form.controls['password'].value);
		formData.append('birthDate', birth);
		formData.append('birthPlace', this.form.controls['birthPlace'].value);
		formData.append('eventIds', this.form.controls['eventIds'].value);
		formData.append('nationality', this.form.controls['nationality'].value);
		formData.append('idNumber', this.form.controls['idNumber'].value);
		formData.append('idType', this.form.controls['idType'].value);
		if (this.image) {
			formData.append('profileImage', this.image);
		} else {
			formData.append('profileImage', dataURLtoFile(this.url, 'file.png'));
		}
		if (this.form.controls['nickName'].value) {
			formData.append('nickName', this.form.controls['nickName'].value);
		}
		if (this.userID == 'new') {
			this.userService.newUser(formData).subscribe((res) => {
					if (res.status == 200) {
						this.spinner.hide()
						this.toastr.success(this.translateService.instant('MESSAGES.USERSUCADDED'), '');
						this.router.navigate(['/users']);
					} else {
						this.spinner.hide()
						this.toastr.error(this.translateService.instant('MESSAGES.DEFAULTERROR'), '');
					}
				}, error => {
				this.spinner.hide()
					this.toastr.error(error.error.message, '');
				}
			);
		} else {
			this.userService.putUser(this.userID, formData).subscribe((res) => {
					if (res.status == 200) {
						this.toastr.success(this.translateService.instant('MESSAGES.USERUPDATESUCCES'), '');
						this.getData();
						this.spinner.hide()
					} else {
						this.spinner.hide()
						this.toastr.error(this.translateService.instant('MESSAGES.ERROR'), '');
					}
				}, (error => {
				this.spinner.hide()
				this.toastr.error(error.error.message, '');
				})
			);
		}
	}
}
