import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AboutusService} from './aboutus.service';
import {NgxPermissionsService} from 'ngx-permissions';
import {TranslateService} from '@ngx-translate/core';

@Component({
	selector: 'kt-aboutus',
	templateUrl: './aboutus.component.html',
	styleUrls: ['./aboutus.component.scss']
})
export class AboutusComponent implements OnInit {
	form = this.fb.group({
		aboutUs: ['', Validators.required],
	});
	role: boolean = false;
	aboutusConfig = {
		editable: true,
		spellcheck: false,
		translate: 'yes',
		height: '750px',
		minHeight: '750px',
		placeholder: this.translateService.instant('MESSAGES.CONFIGPLACEHOLDER'),

		toolbar: [
			['bold', 'italic', 'underline', 'strikeThrough', 'superscript', 'subscript'],
			['fontSize', 'color'],
			['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'indent', 'outdent'],
			['cut', 'copy', 'delete', 'removeFormat', 'undo', 'redo'],
			['paragraph', 'blockquote', 'removeBlockquote', 'horizontalLine', 'orderedList', 'unorderedList'],
			['link', 'unlink', 'image', 'video']
		]
	};
	constructor(private translateService: TranslateService,private ngxPermissionService: NgxPermissionsService,private changeDetector: ChangeDetectorRef, private toastr: ToastrService, private fb: FormBuilder, private service: AboutusService) {
	}

	ngOnInit() {
		this.getAboutUs();
		this.role = this.ngxPermissionService.getPermissions().hasOwnProperty('FEJLESZTO');
		if(!this.role){
			this.aboutusConfig ={
				height: "750px",
				minHeight: "750px",
				placeholder: this.translateService.instant('MESSAGES.CONFIGPLACEHOLDER'),
				spellcheck: false,
				toolbar: undefined,
				translate: "yes",
				editable: false
			}
		}
	}

	getAboutUs() {
		this.service.getAboutUs().subscribe((res) => {
			this.form.setValue({
				aboutUs: (res.aboutUs)
			});
		});
	}

	ngAfterViewChecked() {
		this.changeDetector.detectChanges();
	}

	edit() {
		this.service.newAboutUs(this.form.getRawValue()).subscribe((res) => {
			this.toastr.success(this.translateService.instant('MESSAGES.ABOUTUSSAVE'), '');
		}, error => {
			this.toastr.error(error.error.message, '');
		});
	}
}
