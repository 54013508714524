// Angular
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
// RxJS
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
// NGRX
import {select, Store} from '@ngrx/store';
// Auth reducers and selectors
import {AppState} from '../../reducers';
import {isLoggedIn} from '../_selectors/auth.selectors';
import {AuthService} from '../_services';

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(private store: Store<AppState>, private router: Router, private auth: AuthService) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		if (this.auth.getUser()) {
			return true;
		} else {
			if (route.queryParams) {
				console.log(route.queryParams);
				this.router.navigate(['/auth/login'],{ queryParams: route.queryParams});
			} else {
				this.router.navigate(['/auth/login']);
			}
		}
	}
}
