import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormControl} from '@angular/forms';

@Component({
	selector: 'kt-confirmation-dialog',
	templateUrl: './confirmation-dialog.component.html',
	styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
	@Input() showCancel: boolean;
	@Input() title: string;
	@Input() message: string;
	@Input() btnOkText: string;
	@Input() btnCancelText: string;
	@Input() showPasswordInput: boolean;
	@Input() password: string;
	@Output() passEntry: EventEmitter<any> = new EventEmitter();
	pass = new FormControl();
	constructor(private activeModal: NgbActiveModal) {
	}

	ngOnInit() {
	}

	public decline() {
		this.activeModal.close(false);
	}

	public accept() {
		if (this.showPasswordInput) {
			const form = [{
				result: true,
				password: this.pass.value
			}];
			this.activeModal.close(form);
		} else {
			this.activeModal.close(true);
		}
	}

	public dismiss() {
		this.activeModal.dismiss();
	}
}
