export class MenuConfig {

	public defaults: any = {
		header: {
			self: {},
		},
		aside: {
			self: {},
			items: [
				{
					title: 'Szervezet',
					root: true,
					alignment: 'left',
					icon: 'flaticon-network',
					page: '/organisation',
					permission: ['FEJLESZTO'],
					translate: 'MENUCONFIG.ORGANISATION',
				},
				{
					title: 'Információk',
					root: true,
					alignment: 'left',
					icon: 'flaticon-information',
					page: '/information',
					permission: ['FEJLESZTO','ADMIN'],
					translate: 'MENUCONFIG.INFORMATION',
				},
				{
					title: 'Események',
					root: true,
					alignment: 'left',
					icon: 'flaticon-event-calendar-symbol',
					page: '/events',
					permission: ['FEJLESZTO','ADMIN','SUPERVISOR','USER','ALSUPERVISOR'],
					translate: 'MENUCONFIG.EVENTS',
				},
				{
					title: 'Felhasználók',
					root: true,
					alignment: 'left',
					icon: 'flaticon2-avatar',
					page: '/users',
					permission: ['FEJLESZTO','ADMIN','SUPERVISOR','ALSUPERVISOR'],
					translate: 'MENUCONFIG.USERS',
				},
				{
					title: 'Voucher-ek',
					root: true,
					icon: 'la la-barcode',
					page: '/vouchers',
					permission: ['FEJLESZTO','ADMIN'],
					translate: 'MENUCONFIG.VOUCHERS',
				},
				{
					title: 'Inaktív felhasználók',
					root: true,
					icon: 'la la-asterisk',
					page: '/inactiveusers',
					permission: ['FEJLESZTO','ADMIN'],
					translate: 'MENUCONFIG.INACTIVEUSERS',
				},
				{
					title: 'Pass-ok',
					root: true,
					icon: 'la la-file-text',
					page: '/passes',
					permission: ['FEJLESZTO','ADMIN'],
					translate: 'MENUCONFIG.PASSES',
				},
				{
					title: 'Karszalagok',
					root: true,
					icon: 'la la-ticket',
					page: '/armband',
					permission: ['FEJLESZTO','ADMIN'],
					translate: 'MENUCONFIG.ARMBANDS',
				},

				{
					title: 'Meghívottak',
					root: true,
					alignment: 'left',
					icon: 'flaticon2-group',
					page: '/invited',
					permission: ['FEJLESZTO','ADMIN','SUPERVISOR','ALSUPERVISOR'],
					translate: 'MENUCONFIG.INVITED',
				},
				{
					title: 'Parkolók',
					root: true,
					icon: 'flaticon2-shrink',
					page: '/parking',
					permission: ['FEJLESZTO','ADMIN'],
					translate: 'MENUCONFIG.PARKING',
				},
				{
					title: 'Rólunk',
					root: true,
					alignment: 'left',
					icon: 'flaticon-interface-11',
					page: '/aboutus',
					permission: ['FEJLESZTO','ADMIN','SUPERVISOR','USER','ALSUPERVISOR'],
					translate: 'MENUCONFIG.ABOUTUS',
				},
				{
					title: 'Adatkezelés és adatvédelem',
					root: true,
					alignment: 'left',
					icon: 'flaticon-notes',
					page: '/privacypolicy',
					permission: ['FEJLESZTO','ADMIN','SUPERVISOR','USER','ALSUPERVISOR'],
					translate: 'MENUCONFIG.PRIVACYPOL',
				},
				{
					title: 'Segédletek',
					root: true,
					icon: 'flaticon2-help',
					page: '/helper',
					permission: [],
					translate: 'MENUCONFIG.FAQ',
				},
				{
					title: 'Beváltás',
					root: true,
					alignment: 'left',
					icon: 'flaticon-notes',
					page: '/redemption',
					permission: ['PENZTAROS'],
					translate: 'MENUCONFIG.REDEMPTION',
				},
				{
					title: 'Csoportos regisztráció',
					root: true,
					alignment: 'left',
					icon: 'flaticon-apps',
					page: '/userimport',
					permission: ['FEJLESZTO','ADMIN','SUPERVISOR','ALSUPERVISOR'],
					translate: 'MENUCONFIG.GROUPREG',
				},
			]
		},
	};

	public get configs(): any {
		return this.defaults;
	}
}
