import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {EventsService} from '../events.service';
import {ToastrService} from 'ngx-toastr';

@Component({
	selector: 'kt-scope-mail',
	templateUrl: './scope-mail.component.html',
	styleUrls: ['./scope-mail.component.scss']
})
export class ScopeMailComponent implements OnInit {
	@Input() public id;
	@Input() public name;
	form = this.fb.group({
		header: ['', Validators.required],
		body: ['', Validators.required]
	});

	constructor(private toastr: ToastrService, private service: EventsService, private fb: FormBuilder, public activeModal: NgbActiveModal) {
	}

	ngOnInit() {
	}


	save() {
		const form = {
			eventId: this.id,
			header: this.form.controls['header'].value,
			body: this.form.controls['body'].value
		};
		this.service.scopeMail(form).subscribe(res => {
			this.toastr.success('Sikeresen beállította a köremail-t!', '');
			this.activeModal.close(true);
		}, error => {
			this.toastr.error(error.error.message, '');
		});
	}
}
