import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {LoginpopupService} from '../loginpopup.service';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'kt-login-about-us',
	templateUrl: './login-about-us.component.html',
	styleUrls: ['./login-about-us.component.scss']
})
export class LoginAboutUsComponent implements OnInit {
	data;

	constructor(private service: LoginpopupService, public activeModal: NgbActiveModal,private modalService: NgbModal) {
	}

	ngOnInit() {
		this.service.getAboutUs().subscribe((res) => {
			this.data = res.aboutUs;
		});
	}


}
