import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PipeTransform, Pipe } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
	name: 'authImage'
})
export class AuthImagePipe implements PipeTransform {

	constructor(
		private sanitizer: DomSanitizer,
		private http: HttpClient,
	) {}

	async transform(src: string): Promise<any> {
		const token = localStorage.getItem('token');
		const headers = new HttpHeaders({'Authorization': `Bearer ${token}`});
		const imageBlob = await this.http.get(src, {headers, responseType: 'arraybuffer'}).toPromise();
		return  this.sanitizer.bypassSecurityTrustUrl('data:image/jpg;base64, ' + this._arrayBufferToBase64(imageBlob));
	}
	_arrayBufferToBase64( buffer ) {
		var binary = '';
		var bytes = new Uint8Array( buffer );
		var len = bytes.byteLength;
		for (var i = 0; i < len; i++) {
			binary += String.fromCharCode( bytes[ i ] );
		}
		return window.btoa( binary );
	}
}
