import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormControl} from '@angular/forms';
import {AuthNoticeService, AuthService} from '../../../../core/auth';
import {baseUrl} from '../../url.model';
import {HttpClient} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
	selector: 'kt-newpassword',
	templateUrl: './newpassword.component.html',
	styleUrls: ['./newpassword.component.scss']
})
export class NewpasswordComponent implements OnInit {
	@Input() public code;
	newPassword = new FormControl();

	constructor(private http: HttpClient, private toastr: ToastrService, private translateService: TranslateService, public activeModal: NgbActiveModal, private authService: AuthService) {
	}

	ngOnInit() {
	}

	send() {
		const formData = {
			key: this.code,
			newPassword: this.newPassword.value
		};
		this.http
			.post<any>(baseUrl + 'newPassword', formData, {
				observe: 'response'
			})
			.subscribe((res) => {
				if (res.status == 200) {
					this.toastr.success(this.translateService.instant('NEWPASSWORDMODAL.SUCCESS'), '');
					this.activeModal.close();
				} else {
						this.toastr.error(this.translateService.instant('NEWPASSWORDMODAL.ERROR'), '');
					}
			},error => {
				this.toastr.error(this.translateService.instant(error.error.message, ''));
			});

	}
}
