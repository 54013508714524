import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {baseUrl} from '../url.model';
import {Observable} from 'rxjs';
import {FormBuilder, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';

@Injectable({providedIn: 'root'})
export class AboutusService {


	constructor(private http: HttpClient) {
	}

	getAboutUs(): Observable<any> {
		return this.http.get<any>(baseUrl + 'aboutUs');
	}

	newAboutUs(formdata): Observable<any> {
		return this.http.post<any>(baseUrl + 'aboutUs', formdata, {observe: 'response'});
	}

}
