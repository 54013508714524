import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {MatTableDataSource} from '@angular/material';
import {FormControl} from '@angular/forms';
import {UserPermisService} from '../user-permis/user-permis.service';
import {UsersService} from '../users.service';
import {HttpParams} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {NgxPermissionsService} from 'ngx-permissions';
import {VouchersService} from '../../vouchers/vouchers.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
	selector: 'kt-user-passes',
	templateUrl: './user-passes.component.html',
	styleUrls: ['./user-passes.component.scss']
})
export class UserPassesComponent implements OnInit {

	@Input() public id;
	@Input() public permission;
	subCategoryList = new MatTableDataSource<any>();
	eventList;
	selectedRow;
	passList;
	perm;

	visible: boolean = false;

	displayedColumns: string[];

	name = new FormControl();

	constructor(private translateService: TranslateService,private ngxPermissionService: NgxPermissionsService, private service1: UserPermisService, private service: UsersService, public activeModal: NgbActiveModal, private toastr: ToastrService,private changeDetector: ChangeDetectorRef) {
	}

	ngOnInit() {
		this.getData();
		this.Visible();
		this.getEvents();
		this.getAvailablePass();
		this.getDisplayedColumns();
	}

	getData() {
		this.service.getPassLimitToSupervisor(this.id).subscribe((res) => {
			this.subCategoryList.data = res;
		});
	}
	ngAfterViewChecked(){
		this.changeDetector.detectChanges();
	}
	Visible() {
		this.perm = this.ngxPermissionService.getPermissions().hasOwnProperty(this.permission);
		if(this.perm == this.permission){
			this.visible = false
		}else{
			this.visible = true
		}

	}

	getDisplayedColumns() {
		if (this.permission == 'ADMIN'  ) {
			this.displayedColumns = [
				'eventname',
				'passname',
				'functions'
			];
		} else {
			if (!this.visible ) {
				this.displayedColumns = [
					'eventname',
					'passname',
					'darab',
				];
			} else {
				this.displayedColumns = [
					'eventname',
					'passname',
					'darab',
					'functions'
				];
			}
		}
	}

	removeSubCateg(item) {

		const index = this.subCategoryList.data.indexOf(item.id);
		this.subCategoryList.data.splice(item, 1);
		this.subCategoryList._updateChangeSubscription();
	}

	getEvents() {
		let params = new HttpParams();
		params = params.append('id', this.id);
		this.service1.getUserEvents(params).subscribe((res) => {
			this.eventList = res;
		});
	}

	getAvailablePass() {
		this.service.getAviblePasses(this.id).subscribe((res) => {
			this.passList = res.passes;
		});
	}

	addSubcateg() {

		if (this.subCategoryList.data) {
			this.subCategoryList.data.push({
				eventId: '',
				passId: '',
				limit: ''
			});
		} else {
			this.subCategoryList.data = [];
		}
		this.subCategoryList.data = this.subCategoryList.data;
	}

	getPasses() {
		this.service.getAviblePasses(this.id).subscribe((res) => {
			this.passList = res.bracelets;
		});
	}

	save() {
		const data = {
			userId: this.id,
			limits:
			this.subCategoryList.data

		};

		this.service.modifyPassLimitToSupervisor(data).subscribe((res) => {

				this.toastr.success(this.translateService.instant('MESSAGES.SUCSAVE'), '');
				this.activeModal.close(true);
			}, (error) => {
				this.toastr.error(error.error.message, '', {timeOut: 5000});
			}
		);
	}

}
