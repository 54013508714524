import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewChild
} from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Ng2ImgMaxService } from "ng2-img-max";
import { PassesService } from "../passes.service";
import { DomSanitizer } from "@angular/platform-browser";
import { MatRadioChange } from "@angular/material";
import {TranslateService} from '@ngx-translate/core';
import {NgxSpinnerService} from 'ngx-spinner';

const urlLogo = "https://mlsz-defba.firebaseapp.com/assets/media/add_photo.png";
const url = "https://mlsz-defba.firebaseapp.com/assets/media/add_photo.png";

@Component({
    selector: "kt-newandedit-pass",
    templateUrl: "./newandedit-pass.component.html",
    styleUrls: ["./newandedit-pass.component.scss"]
})
export class NewandeditPassComponent implements OnInit {
    @Input() public id;
    @ViewChild("imageeLogo", null) imageeLogo: ElementRef;
    @ViewChild("imagee", null) imagee: ElementRef;
    color;
    textcolor;
    profileTextColor;
    urlLogo = urlLogo;
    url = url;
    fileLogo: File;
    file: File;
    imageLogo: string | ArrayBuffer = null;
    image: string | ArrayBuffer = null;
    form = this.fb.group({
        name: ["", Validators.required],
        color: ["", Validators.required],
        information: ["", Validators.required],
        capitalised: false,
        textcolor: ["", Validators.required],
        profileTextColor: ["", Validators.required],
        organisation: [""],
        organisationSecond: [""],
        organisationThird: [""],
        organisationFourth: [""],
        information2: [""],
        type: ["simple", Validators.required],
        sectors: [""],
        password: [''],
		code: ['']
    });

    constructor(
        private sanitizer: DomSanitizer,
        private service: PassesService,
		private translateService: TranslateService,
		private spinner: NgxSpinnerService,
        private ng2ImgMax: Ng2ImgMaxService,
        private changeDetectorRef: ChangeDetectorRef,
        public activeModal: NgbActiveModal,
        private toastr: ToastrService,
        private fb: FormBuilder,
        private modalService: NgbModal
    ) { }

    ngOnInit() {
        this.form.get("type").valueChanges.subscribe(type => {
            if (type === "simple") {
                this.form
                    .get("information")
                    .setValidators([Validators.required]);
                this.form.get("sectors").setValidators(null);
            }
            if (type === "sector") {
                this.form.get("information").setValidators(null);
                this.form.get("sectors").setValidators([Validators.required]);
            }
            this.form.get("information").updateValueAndValidity();
            this.form.get("sectors").updateValueAndValidity();
        });
        if (this.id) {
            this.service.getPass(this.id).subscribe(res => {
            	console.log(res)
                this.form.setValue({
                    name: res.name,
                    color: res.color,
                    information: res.information,
                    capitalised: res.capitalised,
                    type: res.passType || "simple",
                    textcolor: res.textcolor ? res.textcolor : "",
                    profileTextColor: res.profileTextColor
                        ? res.profileTextColor
                        : "",
                    organisation: res.organisation ? res.organisation : "",
                    organisationSecond: res.organisationSecond ? res.organisationSecond : "",
                    organisationThird: res.organisationThird ? res.organisationThird : "",
                    organisationFourth: res.organisationFourth ? res.organisationFourth : "",
                    information2: res.information2,
                    password: '',
					code:res.code,
                    sectors: res.sectors || ""
                });
                this.color = res.color;
                this.textcolor = res.textcolor;
                this.profileTextColor = res.profileTextColor;

                this.imageLogo = res.backGroundLink;
                // this.image = res.sponsorLink;
            });
        }
    }

    toggleSector(value) {
        let sectors: string = this.form.get("sectors").value;
        const index = sectors.indexOf(value);
        if (index > -1) {
            const [start, end] = sectors.split(value);
            sectors = start + end;
        } else {
            sectors += value;
        }
        this.form.get("sectors").setValue(sectors);
    }

    isActive(value) {
        const sectors = this.form.get("sectors").value;
        return sectors.indexOf(value) > -1;
    }

    save() {
		this.spinner.show();
        if (
            this.form.get("type").value === "sector" &&
            this.form.get("sectors").value.length === 0
        ) {
            this.toastr.error(this.translateService.instant('MESSAGES.NOTSECTOR'), "");
            return;
        }
        const formData = new FormData();
        formData.append("name", this.form.controls["name"].value);
        formData.append("color", this.form.controls["color"].value);
        formData.append("information", this.form.controls["information"].value);
        formData.append(
            "information2",
            this.form.controls["information2"].value
        );
        formData.append("organisation", this.form.controls["organisation"].value);
        formData.append("organisationSecond", this.form.controls["organisationSecond"].value);
        formData.append("organisationThird", this.form.controls["organisationThird"].value);
        formData.append("organisationFourth", this.form.controls["organisationFourth"].value);
        formData.append("bgImage", this.fileLogo);
        formData.append("capitalised", this.form.controls["capitalised"].value);
        // formData.append("sponsorImage", this.file);
        formData.append("textcolor", this.form.controls["textcolor"].value);
        formData.append(
            "profileTextColor",
            this.form.controls["profileTextColor"].value
        );
        formData.append("type", this.form.get("type").value);
        formData.append("sectors", this.form.get("sectors").value);
        if (this.form.controls['password'].value) {
            formData.append("password", this.form.controls['password'].value)
        }
		if (this.form.controls['code'].value) {
			formData.append("code", this.form.controls['code'].value)
		}
        if (!this.id) {
            this.service.newPass(formData).subscribe(
                res => {
                    if (res.status == 200) {
                        this.toastr.success(
							this.translateService.instant('MESSAGES.PASSADDEDSUCCESS'),
                            ""
                        );
						this.spinner.hide();
                        this.activeModal.close(true);
                    } else {
						this.spinner.hide();
                        this.toastr.error(this.translateService.instant('MESSAGES.ADDINGPASSERROR'), "");
                    }
                },
                error => {
					this.spinner.hide();
                    this.toastr.error(error.error.message, "");
                }
            );
        } else {
            this.service.modifyPasses(this.id, formData).subscribe(
                res => {
					this.spinner.hide();
                    this.toastr.success(this.translateService.instant('MESSAGES.UPDATEPASSERROR'), "");
                    this.activeModal.close(true);
                },
                error => {
					this.spinner.hide();
                    this.toastr.error(error.error.message, "");
                }
            );
        }

    }

    onSelectFileLogo(event) {
        if (event.target.files && event.target.files[0]) {
            const reader = new FileReader();
            this.fileLogo = event.target.files[0];
            reader.readAsDataURL(this.fileLogo);
            reader.onload = event => {
                this.imageLogo = reader.result;
                this.changeDetectorRef.detectChanges();
            };
        }
    }

    onSelectFile(event) {
        if (event.target.files && event.target.files[0]) {
            const reader = new FileReader();
            this.file = event.target.files[0];
            reader.readAsDataURL(this.file);

            reader.onload = event => {
                this.image = reader.result;
                this.changeDetectorRef.detectChanges();
            };
        }
    }

    setColor(event) {
        this.form.controls["color"].setValue(event);
    }

    setprofileTextColor(event) {
        this.form.controls["profileTextColor"].setValue(event);
    }

    setColortextcolor(event) {
        this.form.controls["textcolor"].setValue(event);
    }

    changeType(event: MatRadioChange) {
        const typeControl = this.form.get("type");
        typeControl.setValue(event.value);
    }
}
