import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {baseUrl} from '../url.model';
import {Observable} from 'rxjs';
import {FormBuilder, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';

@Injectable({providedIn: 'root'})
export class LoginpopupService {


	constructor(private http: HttpClient) {
	}

	getPrivacyPolicy(): Observable<any> {
		return this.http.get<any>(baseUrl + 'privacyPolicy');
	}


	getAboutUs(): Observable<any> {
		return this.http.get<any>(baseUrl + 'aboutUs');
	}
}
