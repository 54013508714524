import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material';
import {FormBuilder, FormControl} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationDialog} from '../confirmation-dialog/confirmation-dialog.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../core/reducers';
import {EventsService} from '../events/events.service';
import {NgxPermissionsService} from 'ngx-permissions';
import {debounceTime} from 'rxjs/operators';
import {NewEventComponent} from '../events/new-event/new-event.component';
import {HttpParams} from '@angular/common/http';
import {ScopeMailComponent} from '../events/scope-mail/scope-mail.component';
import {MyLinksComponent} from '../my-links/my-links.component';
import {NewOrganisationComponent} from './new-organisation/new-organisation.component';
import {OrganisationService} from './organisation.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'kt-organisation',
  templateUrl: './organisation.component.html',
  styleUrls: ['./organisation.component.scss']
})
export class OrganisationComponent implements OnInit {
	dataSource = new MatTableDataSource<any>();
	displayedColumns = ['organisationName', 'actions'];
	search = new FormControl();
	private subscriptions: Subscription[] = [];
	pageSize = 10;
	show = false;
	page = 1;
	nev = '';
	collection: number;
	rendezesirany = 'asc';
	role: boolean = true;

	constructor(
		private fb: FormBuilder,
		private activatedRoute: ActivatedRoute,
		private cdr: ChangeDetectorRef,
		private modalService: NgbModal,
		private translateService: TranslateService,
		private router: Router,
		private toastr: ToastrService,
		private confirmationDialogService: ConfirmationDialog,
		private store: Store<AppState>,
		private service: OrganisationService,
		private ngxPermissionService: NgxPermissionsService) {
	}

	textareaEnterPressed($event: KeyboardEvent) {
		$event.preventDefault();
		$event.stopPropagation();
		this.getData();
	}

	ngOnInit() {
		this.search.valueChanges.pipe(debounceTime(500)).subscribe(str =>
			this.getData());
		this.getData();
		this.cdr.detectChanges();

	}

	szukites() {
		this.getData();
	}

	ngAfterViewChecked() {
		this.cdr.detectChanges();
	}

	lapozas(event) {
		this.page = event;
		this.getData();
	}

	sortData(event) {
		this.nev = event.active;
		this.rendezesirany = event.direction;
		this.getData();
	}

	getData() {
		let params = new HttpParams()
			.append('oldal', `${this.page - 1}`).append('meret', `${this.pageSize}`);
		if (this.search.value && this.search.value.length > 2) {
			params = params.append('szoveg', this.search.value);
		}
		this.service.getOrganisations(params).subscribe((res) => {
				this.collection = res.osszes;
				this.dataSource = res.elemek;
			}
		);
	}
	deleteOrganisation(id){
		this.confirmationDialogService
			.confirm(this.translateService.instant('MESSAGES.DELETECONFIRM'), this.translateService.instant('MESSAGES.DELETEDESC'))
			.then(result => {
				if (result) {
					this.service.deleteOrganisation(id).subscribe((res) => {
						this.toastr.success(this.translateService.instant('MESSAGES.SUCCESSDELETEORGANISATION'), '');
						this.getData();
					},error => {
						this.toastr.error(error.error.message,'');
						this.getData();
					});
				} else {
					this.toastr.warning(this.translateService.instant('MESSAGES.DISSMISS'), '');
					this.getData();
				}
			});

	}

	newOrganisation(item?) {
		const modalRef = this.modalService.open(NewOrganisationComponent, {
			size: 'lg',
			backdrop: 'static',
			keyboard: false
		});
		modalRef.componentInstance.id = item;
		modalRef.result.then(() => {
			this.getData();
		}).catch(() => {
		});
	}
}
