import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {FormBuilder, Validators} from '@angular/forms';
import {UsersService} from '../../../users/users.service';
import moment from 'moment';
import {CheckDataService} from './check-data.service';
import {baseUrl} from '../../../url.model';

@Component({
	selector: 'kt-check-data',
	templateUrl: './check-data.component.html',
	styleUrls: ['./check-data.component.scss']
})
export class CheckDataComponent implements OnInit {
	@Input() public id;
	@Input() public userId;

	form = this.fb.group({

		firstname: ['', [Validators.required, Validators.pattern('^[a-zÁÉÚŐÓÜ. ÖÍáéúőóüöíA-Z_-]{3,15}$')]],
		lastname: ['', [Validators.required, Validators.pattern('^[a-zÁÉÚŐÓÜÖÍáéúőóüöíA-Z_ -]{3,15}$')]],
		birthDate: ['', Validators.required],
		birthPlace: ['', Validators.required],
		motherName: ['', Validators.required]
	});


	constructor(private userService: UsersService, private checkService: CheckDataService, public activeModal: NgbActiveModal, private toastr: ToastrService, private fb: FormBuilder) {
	}

	ngOnInit() {
		this.userService.getUser(this.userId).subscribe((res) => {
			this.form.setValue({
				firstname: res.firstname,
				lastname: res.lastname,
				birthDate: res.birthDate,
				birthPlace: res.birthPlace,
				motherName: res.motherName
			});
		}, error => {
			this.toastr.error(error.error.message, '');
		});
	}


	public hasError = (controlName: string, errorName: string) => {
		return this.form.controls[controlName].hasError(errorName);
	};

	save() {
		const birth = moment(this.form.controls['birthDate'].value).format('YYYY-MM-DD');
		this.form.controls['birthDate'].setValue(birth);

		const formData = {
			id: this.userId,
			firstname: this.form.controls['firstname'].value,
			lastname: this.form.controls['lastname'].value,
			motherName: this.form.controls['motherName'].value,
			birthDate: birth,
			birthPlace: this.form.controls['birthPlace'].value,
		};

		this.checkService.putUser(formData).subscribe((res) => {
				if (res.status == 200) {
					window.open(baseUrl + `users/loadUserPass/${this.userId}/${this.id}`, '_blank');
				}
			}, (error => {
				this.toastr.error(error.error.message, '');
			})
		);
	}

}
