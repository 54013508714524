import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {MyLinksService} from '../../my-links/my-links.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {BehaviorSubject} from 'rxjs';
import {HttpParams} from '@angular/common/http';
import {ParkingService} from '../../parking/parking.service';
import {ToastrService} from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
	selector: 'kt-parking-voucher',
	templateUrl: './parking-voucher.component.html',
	styleUrls: ['./parking-voucher.component.scss']
})
export class ParkingVoucherComponent implements OnInit {
	@Input() public eventID;
	available$ = new BehaviorSubject([]);

	selectedParking;
	form = this.fb.group({
		count: [''],
	});
	constructor(private spinner: NgxSpinnerService,private toastr: ToastrService,private parkingService: ParkingService, private fb: FormBuilder, private myLinkService: MyLinksService, public activeModal: NgbActiveModal) {
	}

	ngOnInit() {
		this.getParkings();
	}

	getParkings() {
		let params = new HttpParams();

		//todo ellenőrizni
		this.parkingService.loadParkingSlot(this.eventID).subscribe(res=>{
			this.available$.next(res);
		})
		// this.parkingService.getParkingsAll(params).subscribe(res => {
		// 	this.available$.next(res.elemek);
		// });
	}

	send() {
		this.spinner.show();
		const formData = {
			eventId: parseInt(this.eventID),
			parkingSlotId: this.selectedParking,
			count:  parseInt(this.form.controls['count'].value)
		};
		this.parkingService.loadParkingSlotPackage(formData).subscribe(res=>{
			var link = document.createElement('a');
			let file = new Blob([res], {type: 'application/zip'});
			var fileUrl = URL.createObjectURL(file);
			this.spinner.hide();
			link.setAttribute('href', fileUrl);
			link.setAttribute('download', 'ParkingVoucher.zip');
			link.style.visibility = 'hidden';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}, error => {
			this.spinner.hide();
			var string = new TextDecoder('utf-8').decode(error.error);
			let obj = JSON.parse(string);
			let message = obj['message'];
			this.toastr.error(message, '');
		});




	}

}
