import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {baseUrl} from '../../url.model';
import {HttpClient} from '@angular/common/http';

@Component({
	selector: 'kt-white',
	templateUrl: './white.component.html',
	styleUrls: ['./white.component.scss']
})
export class WhiteComponent implements OnInit {

	constructor(private router: Router, private http: HttpClient, private route: ActivatedRoute,) {
	}

//https://accesscontroll.com/download?name=magyarorszag-ciprus1622982953253
	ngOnInit() {
		let queryParams = null;
		this.route.queryParams.subscribe(params => {
			queryParams = params;
		});
		if (queryParams.name) {
			window.open('http://mlsz.herokuapp.com/allPass/' + queryParams.name, '_self');
			this.router.navigate(['/']);

		}

	}

}
