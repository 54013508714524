import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {baseUrl} from '../url.model';

@Injectable({providedIn: 'root'})
export class UserImportService {
	constructor(private http: HttpClient) {
	}


	createUserGroupByPass(formdata): Observable<any> {
		return this.http.post<any>(baseUrl + 'groupRegister/createUserGroupByPass', formdata, {observe: 'response'});
	}

	createUserGroupByBracelet(formdata): Observable<any> {
		return this.http.post<any>(baseUrl + 'groupRegister/createUserGroupByBracelet', formdata, {observe: 'response'});
	}

	getAvailableBracelets(id): Observable<any> {
		return this.http.get<any>(baseUrl + 'groupRegister/'+id+'/getAvailableBracelets');
	}

	getAvailableParkingSlots(id): Observable<any> {
		return this.http.get<any>(baseUrl + 'groupRegister/'+id+'/getAvailableParkingSlots');
	}

	getAvailablePass(id): Observable<any> {
		return this.http.get<any>(baseUrl + 'groupRegister/'+id+'/getAvailablePasses');
	}

	getEvents(): Observable<any> {
		return this.http.get<any>(baseUrl + 'groupRegister/getAvailableEvents');
	}
}
