import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ProfileService} from '../profile.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../core/reducers';
import {Logout} from '../../../../core/auth';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
	selector: 'kt-confirm-with-password',
	templateUrl: './confirm-with-password.component.html',
	styleUrls: ['./confirm-with-password.component.scss']
})
export class ConfirmWithPasswordComponent implements OnInit {
	form: FormGroup;

	constructor(private translateService: TranslateService,private toastr: ToastrService, private store: Store<AppState>, private service: ProfileService, private fb: FormBuilder, private activeModal: NgbActiveModal) {
	}

	ngOnInit() {
		this.form = this.fb.group({
			password: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])]
		});
	}

	public decline() {
		this.activeModal.close(false);
	}

	public accept() {
		const formdata = {
			password: this.form.controls['password'].value
		};
		this.service.deleteProfil(formdata).subscribe((res) => {
			localStorage.clear();
			this.store.dispatch(new Logout());
		}, error => {
			this.toastr.success(error.error.message, this.translateService.instant('MESSAGES.ERROR'));
		});
		this.activeModal.close(true);
	}

	public dismiss() {
		this.activeModal.dismiss();
	}


}
