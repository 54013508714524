import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationDialogComponent} from './confirmation-dialog.component';
import {Injectable} from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class ConfirmationDialog {

	constructor(private modalService: NgbModal) {
	}

	public confirm(
		title: string,
		message: string,
		btnOkText: string = 'OK',
		btnCancelText: string = 'Cancel',
		dialogSize: 'sm' | 'lg' = 'sm'): Promise<boolean> {
		const modalRef = this.modalService.open(ConfirmationDialogComponent, {size: dialogSize});
		modalRef.componentInstance.title = title;
		modalRef.componentInstance.message = message;
		modalRef.componentInstance.showCancel = true;
		modalRef.componentInstance.btnOkText = btnOkText;
		modalRef.componentInstance.btnCancelText = btnCancelText;

		return modalRef.result;
	}

	public passwordconfirm(
		title: string,
		message: string,
		// password: string,
		btnOkText: string = 'OK',
		btnCancelText: string = 'Cancel',
		dialogSize: 'sm' | 'lg' = 'sm'): Promise<boolean> {
		const modalRef = this.modalService.open(ConfirmationDialogComponent, {size: dialogSize});
		modalRef.componentInstance.title = title;
		modalRef.componentInstance.showPasswordInput = true;
		// modalRef.componentInstance.password = password;
		modalRef.componentInstance.message = message;
		modalRef.componentInstance.showCancel = true;
		modalRef.componentInstance.btnOkText = btnOkText;
		modalRef.componentInstance.btnCancelText = btnCancelText;
		return modalRef.result;
	}



	public message(
		title: string,
		message: string,
		btnOkText: string = 'OK',
		dialogSize: 'sm' | 'lg' = 'sm'): Promise<boolean> {
		const modalRef = this.modalService.open(ConfirmationDialogComponent, {size: dialogSize});
		modalRef.componentInstance.title = title;
		modalRef.componentInstance.showCancel = false;
		modalRef.componentInstance.message = message;
		modalRef.componentInstance.btnOkText = btnOkText;

		return modalRef.result;
	}

}
