// Angular
import {
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
// RxJS
import {Observable, Subject, BehaviorSubject} from 'rxjs';
// Translate
import {TranslateService} from '@ngx-translate/core';
// Store
import {Store} from '@ngrx/store';
import {AppState} from '../../../../core/reducers';
// Auth
import {AuthNoticeService, AuthService} from '../../../../core/auth';
import * as jwt_decode from 'jwt-decode';
import {NgxPermissionsService, NgxRolesService} from 'ngx-permissions';
import {MatDialog} from '@angular/material';
import {ForgotPassComponent} from './forgot-pass/forgot-pass.component';
import {ToastrService} from 'ngx-toastr';
import {LoginAboutUsComponent} from '../login-about-us/login-about-us.component';
import {LoginPrivacyComponent} from '../login-privacy/login-privacy.component';
import {TranslationService} from '../../../../core/_base/layout';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NewEventComponent} from '../../events/new-event/new-event.component';
import {NewpasswordComponent} from '../newpassword/newpassword.component';
/**
 * ! Just example => Should be removed in development
 */
const DEMO_PARAMS = {
    USERNAME: '',
    PASSWORD: ''
};



@Component({
    selector: 'kt-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
    loginForm: FormGroup;
    form: FormGroup;
    loading = false;
    isLoggedIn$: Observable<boolean>;
    errors: any = [];
    error = false;
    private unsubscribe: Subject<any>;
    permissions$;
    roles$;
    selected ;
    reason = '';
    redirectError$ = new BehaviorSubject(false);


    constructor(
		private modalService: NgbModal,
        private dialog: MatDialog,
        private toastr: ToastrService,
		private translateService: TranslateService,
		private route: ActivatedRoute,
        private rolesService: NgxRolesService,
        private permissionsService: NgxPermissionsService,
        private auth: AuthService,
        private authNoticeService: AuthNoticeService,
		private router: Router,
        private translate: TranslateService,
        private translationService: TranslationService,
        private store: Store<AppState>,
        private fb: FormBuilder,
    ) {
        this.permissions$ = permissionsService.permissions$;
        this.unsubscribe = new Subject();
        this.roles$ = rolesService.roles$;
    }

    set loadingS(loading: boolean) {
        this.loading = loading;
    }

//http://localhost:4200/newPassword?code=AlcsutiAttila


    ngOnInit(): void {
    	let queryParams = null;
		this.route.queryParams.subscribe(params => {
			queryParams=params;
		});
		// this.route.events.subscribe(params => console.log(params));
        const select = this.translationService.getSelectedLanguage();
        if (select) {
            this.selected = select;
        } else {
            this.selected = 'hu';
            this.translationService.setLanguage(this.selected);
        }
        this.initLoginForm();
        if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras) {
            const navState = this.router.getCurrentNavigation().extras.state;
            if (navState.reason) {
                this.redirectError$.next(true);
                this.reason = navState.reason;
            }
        }
        if(queryParams.code){
        	this.opennewPasswor(queryParams.code)
		}
    }

    ngOnDestroy(): void {
        this.loading = false;
    }

    languageChange(event) {
        this.translationService.setLanguage(event);
    }

    opennewPasswor(code){
		const modalRef = this.modalService.open(NewpasswordComponent, {
			size: 'lg',
			backdrop: 'static',
			keyboard: false
		});
		modalRef.componentInstance.code = code;
	}

    aboutus() {
		const modalRef = this.modalService.open(LoginAboutUsComponent, {
			size: 'lg',
			backdrop: 'static',
			keyboard: false
		});

    }

	openModal() {
		const modalRef = this.modalService.open(ForgotPassComponent, {
			size: 'lg',
			backdrop: 'static',
			keyboard: false
		});

	}

    adatkez() {
		const modalRef = this.modalService.open(LoginPrivacyComponent, {
			size: 'lg',
			backdrop: 'static',
			keyboard: false
		});
        // this.dialog.open(LoginPrivacyComponent, {
        //     width: '750px'
        // });
    }

    initLoginForm() {
        this.form = this.fb.group({
            username: [
                DEMO_PARAMS.USERNAME,
                Validators.compose([Validators.required, Validators.email])
            ],
            password: [
                DEMO_PARAMS.PASSWORD,
                Validators.compose([Validators.required])
            ]
        });
    }


    submit() {
        this.loading = true;
        const controls = this.form.controls;
        const authData = {
            username: controls['username'].value,
            password: controls['password'].value
        };
        this.auth.login(authData).subscribe(
            res => {
                localStorage.setItem('token', res.token);
                localStorage.setItem('role', res.role);

                this.permissionsService.loadPermissions([res.role]);

                const decoded = this.getDecodedAccessToken(res.token);
                this.auth.setFirstname(decoded.firstname);
                this.auth.setLastname(decoded.lastname);
                this.auth.setUser(decoded.sub);
                localStorage.setItem('userId', decoded.userId);
                this.loading = false;

                const redirectUrl = localStorage.getItem('redirectUrl');

                if (redirectUrl) {
                    localStorage.removeItem('redirectUrl');
                    this.router.navigateByUrl(redirectUrl);
                }
                this.router.navigate(['/']);
            },
            error => {
                // console.log(error);
                if (error.status == 401) {
                    this.toastr.error(this.translateService.instant('wrong_credentials'), '', {
                        timeOut: 8000
                    });
                }
                if (error.status > 500) {
                    this.toastr.error('server_side_error', '', {
                        timeOut: 8000
                    });
                }

                this.error = true;
                this.loading = false;
            }
        );
    }



    getDecodedAccessToken(token: string): any {
        try {
            return jwt_decode(token);
        } catch (Error) {
            return null;
        }
    }
}
