import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {baseUrl} from '../../../url.model';

@Injectable({providedIn: 'root'})
export class BItemService {
	constructor(private http: HttpClient) {
	}

	getEntryItem(formdata): Observable<any> {
		return this.http.post<any>(baseUrl + 'users/getEntryItem', formdata);
	}

	changeEntryItem(formdata): Observable<any>{
		return this.http.post<any>(baseUrl+'users/changeEntryItem',formdata);
	}

	//DELETE basehost/parkingSlots/deleteUserParkingSlot
	deleteParks(form): Observable<any> {
		return this.http.post<any>(baseUrl + 'parkingSlots/deleteUserParkingSlot',form);
	}
}
