// Angular
import { Component, Input, OnInit } from "@angular/core";
// RxJS
import { Observable } from "rxjs";
// NGRX
import { select, Store } from "@ngrx/store";
// State
import { AppState } from "../../../../../core/reducers";
import { currentUser, Logout, User } from "../../../../../core/auth";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MailTemplateComponent } from "../../../../pages/mail-template/mail-template.component";
import { NgxPermissionsService } from "ngx-permissions";
import { ProfileService } from "../../../../pages/profile/profile.service";
import * as jwt_decode from "jwt-decode";
@Component({
	selector: "kt-user-profile",
	templateUrl: "./user-profile.component.html"
})
export class UserProfileComponent implements OnInit {
	// Public properties
	user$: Observable<User>;
	user;
	firstname;
	lastname;
	@Input() avatar = "/assets/media/avatar.png";
	@Input() greeting: boolean = true;
	@Input() badge: boolean;
	@Input() icon: boolean;

	/**
	 * Component constructor
	 *
	 * @param modalService
	 * @param store: Store<AppState>
	 * @param permissionService
	 * @param profile
	 */
	constructor(
		private modalService: NgbModal,
		private store: Store<AppState>,
		private permissionService: NgxPermissionsService,
		private profile: ProfileService
	) {}

	getDecodedAccessToken(token: string): any {
		try {
			return jwt_decode(token);
		} catch (Error) {
			return null;
		}
	}
	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.firstname = localStorage.getItem("firstname");
		this.lastname = localStorage.getItem("lastname");
		const decoded = this.getDecodedAccessToken(localStorage.getItem('token'));
		this.permissionService.loadPermissions([decoded.role]);
		this.user = localStorage.getItem("user");
		this.user$ = this.store.pipe(select(currentUser));
		this.profile.me().subscribe(res => {
			if (res.url) {
				this.avatar = res.url;
			}
		});
	}

	mailSetTemplate() {
		const modalRef = this.modalService.open(MailTemplateComponent, {
			size: "lg",
			backdrop: "static",
			keyboard: false
		});
		// modalRef.componentInstance.id = item;
		modalRef.result.then(() => {}).catch(() => {});
	}

	/**
	 * Log out
	 */
	logout() {
		localStorage.clear();
		this.store.dispatch(new Logout());
	}
}
