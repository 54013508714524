import {Component, OnInit} from '@angular/core';
import {MatSelectChange, MatRadioChange} from '@angular/material';
import {InvitedService} from '../invited.service';
import {FormBuilder} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {BehaviorSubject} from 'rxjs';
import {EventsService} from '../../events/events.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UsersService} from '../../users/users.service';
import {map} from 'rxjs/operators';
import {SelectionModel} from '@angular/cdk/collections';
import {TranslateService} from '@ngx-translate/core';
import {NgxSpinnerService} from 'ngx-spinner';

export interface Section {
	type: string;
	id: number;
	name: string;
}

@Component({
	selector: 'kt-activate',
	templateUrl: './activate.component.html',
	styleUrls: ['./activate.component.scss']
})
export class ActivateComponent implements OnInit {
	id: any;
	userData: any;
	event: Section;
	access: Section;
	parkingZone: Section = null;
	available$ = new BehaviorSubject([]);
	passes: Section[];
	bracelets: Section[];
	parkings: Section[];
	selection: any;
	image: string | ArrayBuffer = null;

	constructor(
		private fb: FormBuilder,
		private translateService: TranslateService,
		private route: ActivatedRoute,
		private router: Router,
		private spinner: NgxSpinnerService,
		private toastr: ToastrService,
		private service: InvitedService,
		private eventService: EventsService,
		private userService: UsersService
	) {
	}

	ngOnInit() {
		this.spinner.show();

		const params = this.route.snapshot.paramMap;
		if (params.get('eventId')) {

			this.id = params.get('id');
			this.userService.getUser(this.id).subscribe(res => {
				this.image = res.url;
				this.userData = res;
			});
			this.available$.subscribe(res => {
				const event = res.find(
					event => event.id == params.get('eventId')
				);
				if (event) {
					this.event = event;
					this.changeEvent({value: event});
				}
			});
			this.getAvailable();
		} else {
			this.route.paramMap
				.pipe(map(() => window.history.state))
				.subscribe(state => {
					this.selection = state.selection;
					if (!this.selection) {
						window.history.back();
					}
					this.id = state.selection._selected[0].id;
					const eventId = state.selection._selected[0].eventId;
					this.userService.getUser(this.id).subscribe(res => {
						this.userData = res;
					});
					this.available$.subscribe(res => {
						const event = res.find(event => event.id == eventId);
						if (event) {
							this.event = event;
							this.changeEvent({value: event});
						}
					});
					this.getAvailable();
				});
		}
		this.spinner.hide();
	}

	getAvailable() {
		this.eventService.getActivableEvents(this.id).subscribe(res => {
			this.available$.next(res);
		});
	}

	reportImage() {
		const form = {
			userId: this.id
		};
		this.service.reportImage(form).subscribe(res => {
				if (res.status == 200) {
					this.toastr.success(
						this.translateService.instant('MESSAGES.REPORTIMAGESUCCESS'),
						''
					);
				} else {
					this.toastr.error(this.translateService.instant('MESSAGES.REPORTSENDINGERROR'), '');
				}
			}, error => {
				this.toastr.error(error.error.message, '');
			}
		);
	}

	compareEvents = (e1, e2) => {
		return e1.id === e2.id;
	};

	getSubLists(event) {
		const formData = {
			userId: this.id,
			eventId: event ? event.id : this.event
		};
		this.service.postFake(formData).subscribe(
			res => {
				this.passes = res.body.passes;
				this.bracelets = res.body.bracelets;
				this.parkings = res.body.parkingSlots;
			},
			error => {
				this.toastr.error(error.error.message, '');
			}
		);
	}

	changeEvent(event: MatSelectChange | any) {
		if (event.value) {
			this.getSubLists(event.value);
		} else {
			this.passes = null;
			this.bracelets = null;
			this.parkings = null;
			this.access = null;
			this.parkingZone = null;
		}
	}

	selectAccess(event: MatRadioChange) {
		this.access = event.value;
	}

	selectParking(event: MatRadioChange) {
		this.parkingZone = event.value;
	}

	onClose(): void {
	}

	submit(event: MouseEvent) {
		const button = event.target;
		button['disabled'] = true;
		const formd = {
			userId: this.selection
				? this.selection._selected.map(item => item.id)
				: this.id,
			eventId: this.event.id,
			itemId: this.access.id,
			type: this.access.type,
			parkingSlotId: this.parkingZone ? this.parkingZone.id : null
		};
		this.service.activateUser(formd, !!this.selection).subscribe(
			res => {
				button['disabled'] = false;
				if (res.status == 200) {
					this.toastr.success(
						this.translateService.instant('MESSAGES.SUCCESSACTIVEUSER'),
						''
					);
					this.router.navigateByUrl(`/invited`);
				} else {
					this.toastr.error(this.translateService.instant('MESSAGES.ERRORACTIVATE'), '');
				}
			},
			error => {
				this.toastr.error(error.error.message, '');
				button['disabled'] = false;
			}
		);
	}
}
