// Angular
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
// Partials
import { PartialsModule } from "../partials/partials.module";
// Pages
import { CoreModule } from "../../core/core.module";
import { EventsComponent } from "./events/events.component";
import { UsersComponent } from "./users/users.component";
import {
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    MatRadioModule, MatSlideToggleModule
} from '@angular/material';
import { RouterModule } from "@angular/router";
import { NewComponent } from "./users/new/new.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FlexModule } from "@angular/flex-layout";
import { VouchersComponent } from "./vouchers/vouchers.component";
import { ArmbandComponent } from "./armband/armband.component";
import {
	NgxPermissionsModule,
	NgxPermissionsRestrictStubModule
} from "ngx-permissions";
import { RegisterComponent } from "./register/register.component";
import { InvitedComponent } from "./invited/invited.component";
import { UserPermisComponent } from "./users/user-permis/user-permis.component";
import { PassesComponent } from "./passes/passes.component";
import { PrivacypolicyComponent } from "./privacypolicy/privacypolicy.component";
import { ProfileComponent } from "./profile/profile.component";
import { EventsDataComponent } from "./events/events-data/events-data.component";
import { RedemptionComponent } from "./redemption/redemption.component";
import { DetailsComponent } from "./redemption/details/details.component";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { ParkingComponent } from "./parking/parking.component";
import { ActivateComponent } from "./invited/activate/activate.component";
import { ImageCropperModule } from "ngx-image-cropper";
import { AboutusComponent } from "./aboutus/aboutus.component";
import { InformationComponent } from "./information/information.component";
import { CropperDialogComponent } from "./cropper-dialog/cropper-dialog.component";
import { InformationDataComponent } from "./information/information-data/information-data.component";
import { HelperComponent } from './helper/helper.component';
import {NgxEditorModule} from 'ngx-editor';
import {TranslateModule} from '@ngx-translate/core';
import { UserImportComponent } from './user-import/user-import.component';
import { OrganisationComponent } from './organisation/organisation.component';
import {AuthImagePipe} from './basic.pipe';
import { PassGroupComponent } from './events/pass-group/pass-group.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import { WhiteComponent } from './white/white/white.component';
import { InactiveUsersComponent } from './inactive-users/inactive-users.component';

@NgModule({
    declarations: [
        EventsComponent,
        UsersComponent,
        NewComponent,
        VouchersComponent,
        ArmbandComponent,
        RegisterComponent,
        InvitedComponent,
        UserPermisComponent,
        PassesComponent,
        PrivacypolicyComponent,
        ProfileComponent,
        EventsDataComponent,
        RedemptionComponent,
        DetailsComponent,
        ParkingComponent,
        ActivateComponent,
        AboutusComponent,
        InformationComponent,
        CropperDialogComponent,
        InformationDataComponent,
        HelperComponent,
        UserImportComponent,
        OrganisationComponent,
        AuthImagePipe,
        WhiteComponent,
        InactiveUsersComponent,
    ],
	exports: [],
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        CoreModule,
        PartialsModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatTableModule,
        MatCheckboxModule,
        MatFormFieldModule,
        RouterModule,
        MatButtonModule,
        MatInputModule,
        MatSortModule,
        MatTooltipModule,
        MatPaginatorModule,
        MatSelectModule,
        ReactiveFormsModule,
        NgbModule,
        MatRadioModule,
        FlexModule,
        ImageCropperModule,
        MatProgressSpinnerModule,
        NgxPermissionsModule.forChild(),
        NgxPermissionsRestrictStubModule,
        MatCardModule,
        MatDatepickerModule,
        NgxMaterialTimepickerModule,
        MatListModule,
        MatRippleModule,
        NgxEditorModule,
        TranslateModule,
        MatSlideToggleModule,
        NgxSpinnerModule
    ],
	providers: [],
	entryComponents: [CropperDialogComponent]
})
export class PagesModule {}
