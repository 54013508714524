import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef, MatListOption, MatSelectionList, MatTableDataSource} from '@angular/material';
import {SelectionModel} from '@angular/cdk/collections';
import {InvitedService} from '../invited.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {BehaviorSubject} from 'rxjs';
import {EventsService} from '../../events/events.service';
import {TranslateService} from '@ngx-translate/core';

export interface Section {
	type: string;
	id: number;
	name: string;

}

@Component({
	selector: 'kt-activate-modal',
	templateUrl: './activate-modal.component.html',
	styleUrls: ['./activate-modal.component.scss']
})
export class ActivateModalComponent implements OnInit {
	@Input() id;
	@ViewChild(MatSelectionList, null) selectionList: MatSelectionList;
	selectedEventId;
	available$ = new BehaviorSubject([]);
	passes: Section[];
	bracelets: Section[];
	parkId;
	parkings = new MatTableDataSource<any>();
	form = this.fb.group({
		event: ['']
	});
	displayedColumns = ['checked', 'name',];
	selectedOptions = [];
	selectedOption;
	activateButton: boolean = false;
	eventParkings: any = {};
	constructor(private translateService: TranslateService,private fb: FormBuilder, private toastr: ToastrService, private service: InvitedService, private eventService: EventsService, public dialogRef: MatDialogRef<ActivateModalComponent>) {
	}

	ngOnInit() {
		this.getAvailable();
		this.selectionList.selectedOptions = new SelectionModel<MatListOption>(false);
	}
	isSelectedParkings(row) {
		return this.eventParkings.parkings && this.eventParkings.parkings.find(x => {
			return x === row.id;
		});
	}
	changeEvent(event) {
		if (event) {
			this.selectedEventId = event;
			this.post();
			this.activateButton = true;
		} else {
			this.activateButton = false;
		}
	}

	getAvailable() {
		this.eventService.getActivableEvents(this.id).subscribe((res) => {
			this.available$.next(res);
		});
	}


	post() {
		const formData = {
			userId: this.id,
			eventId: this.selectedEventId
		};
		this.service.postFake(formData).subscribe((res) => {
			this.passes = res.body.passes;
			this.bracelets = res.body.bracelets;
			this.parkings = res.body.parkingSlots;
		}, error => {
			this.toastr.error(error.error.message, '');
		});
	}

	checkParkings($event, element) {
		this.parkId = element.id;

	}

	submit() {
		const formd = {
			userId: this.id,
			itemId: this.selectedOption[0].id,
			eventId: this.form.controls['event'].value,
			type: this.selectedOption[0].type,
			parkingSlotId: this.parkId
		};
		this.service.activateUser(formd).subscribe((res) => {
			if (res.status == 200) {
				this.toastr.success(this.translateService.instant('MESSAGES.SUCCESSACTIVEUSER'), '');
				this.onClose();
			} else {
				this.toastr.error(this.translateService.instant('MESSAGES.ERRORACTIVATE'), '');
			}
		}, error => {
			this.toastr.error(error.error.message, '');
		});
	}

	onNgModelChange(event) {
		this.selectedOption = event;
	}

	onClose(): void {
		this.dialogRef.close(true);
	}
}
