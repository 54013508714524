import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {ParkingService} from '../parking.service';
import { imageEndPoint } from '../../url.model';

@Component({
	selector: 'kt-new-parking',
	templateUrl: './new-parking.component.html',
	styleUrls: ['./new-parking.component.scss']
})
export class NewParkingComponent implements OnInit {
	@Input() public id;
	colorParking;
	imageEndPoint = imageEndPoint
	form = this.fb.group({
		name: ['', Validators.required],
		description: ['', Validators.required],
		color: ['', Validators.required],
		password: ['']
	});
	editorConfig = {
		editable: true,
		spellcheck: false,
		translate: 'yes',
		height: '10rem',
		minHeight: '5rem',
		placeholder: 'Kérem adja meg a leírást!',
		// toolbar: [
		// 	['bold', 'italic', 'underline'],
		// 	['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'indent', 'outdent'],
		// ]
		toolbar: [
			['bold', 'italic', 'underline', 'strikeThrough', 'superscript', 'subscript'],
			['fontSize', 'color'],
			['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'indent', 'outdent'],
			['cut', 'copy', 'delete', 'removeFormat', 'undo', 'redo'],
			['paragraph', 'blockquote', 'removeBlockquote', 'horizontalLine', 'orderedList', 'unorderedList'],
			['link', 'unlink', 'image', 'video']
		]
	};
	constructor(private service: ParkingService,public activeModal: NgbActiveModal, private toastr: ToastrService, private fb: FormBuilder) {
	}

	setColorParking(event) {
		this.form.controls['color'].setValue(event);
	}

	ngOnInit() {
		if (this.id) {
			this.service.getParking(this.id).subscribe(resp => {
				this.form.setValue({
					name: resp.name,
					description: resp.description,
					color: resp.color,
					password: resp.password
				});
				this.colorParking = resp.color;
			});
		}
	}

	save() {
		if (!this.id) {
			this.service.newParking(this.form.getRawValue()).subscribe((res) => {
				if (res.status == 200) {
					this.toastr.success('A parkolót sikeresen hozzáadta', '');
					this.activeModal.close(true);
				} else {
					this.toastr.error('Hiba létrehozás közben', 'Hiba');
				}
			}, (error) => {
				this.toastr.error(error.error.message, 'Hiba');
			});
		} else {
			this.service.modifyParking(this.id, this.form.getRawValue()).subscribe((res) => {
					if (res.status == 200) {
						this.toastr.success('A parkolót sikeresen frissítette', '');
						this.activeModal.close(true);
					} else {
						this.toastr.error('Hiba történt!', '');
					}
				}, (error => {
					this.toastr.error(error.error.message, 'Hiba');
				})
			);
		}
	}
}
