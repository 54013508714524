import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MatTableDataSource} from '@angular/material';
import {ActivatedRoute} from '@angular/router';
import {UserPermisService} from './user-permis.service';
import {HttpParams} from '@angular/common/http';
import {VouchersService} from '../../vouchers/vouchers.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
	selector: 'kt-user-permis',
	templateUrl: './user-permis.component.html',
	styleUrls: ['./user-permis.component.scss']
})
export class UserPermisComponent implements OnInit {
	@Input() public userID;
	subCategoryList = new MatTableDataSource<any>();
	eventList;
	selectedRow;
	voucherList;
	displayedColumns: string[] = [
		'eventname',
		'vouchername',
		'functions'];


	constructor(private translateService: TranslateService, public activeModal: NgbActiveModal, private toastr: ToastrService, private modalService: NgbModal, private service: UserPermisService, private route: ActivatedRoute, private voucherService: VouchersService) {
	}


	ngOnInit() {
		console.log(this.userID);
		this.service.getAttachedVouchers(this.userID).subscribe((res) => {
			console.log(res);
			this.subCategoryList.data = res;
		});
		this.getVoucher();
		this.getEvents();
	}


	getVoucher() {
		this.voucherService.getVouchersAvaible(null).subscribe((res) => {
			console.log(res);
			this.voucherList = res;
		});
		// this.voucherService.getVouchers(null).subscribe((res) => {
		//
		// 	this.voucherList = res.elemek;
		// });
	}

	getEvents() {
		let params = new HttpParams();
		params = params.append('id', this.userID);
		this.service.getUserEvents(params).subscribe((res) => {
			this.eventList = res;
		});
	}

	addSubcateg() {


		if (this.subCategoryList.data) {
			this.subCategoryList.data.push({
				eventId: '',
				voucherId: '',
			});
		} else {
			this.subCategoryList.data = [];
		}
		this.subCategoryList.data = this.subCategoryList.data;
	}

	removeSubCateg(item) {

		const index = this.subCategoryList.data.indexOf(item.id);
		this.subCategoryList.data.splice(item, 1);
		this.subCategoryList._updateChangeSubscription();

	}

	mentes() {
		const data = {
			userId: this.userID,
			voucherOnEvents: this.subCategoryList.data.map(row => {
				delete row.hovered;
				return row;
			})
		};
		this.service.saveVoucherstoEvent(data).subscribe((res) => {
			if (res.status == 200) {
				this.toastr.success(this.translateService.instant('MESSAGES.PERMISSIONSET'), '');
			} else {
				this.toastr.error(this.translateService.instant('MESSAGES.SETTINGERROR'), '');
			}
		}, error => {
			this.toastr.error(error.error.message, '');
		});
	}

	changeVoucher(event, i) {
		this.subCategoryList.data[i].eventId = event.id;
	}

	changeEvent(event, i) {
		this.subCategoryList.data[i].voucherId = event.id;
	}
}
