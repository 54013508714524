import {Component, Input, OnInit} from '@angular/core';

@Component({
	selector: 'kt-error-modal',
	templateUrl: './error-modal.component.html',
	styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent implements OnInit {
	@Input() public id;

	constructor() {
	}

	ngOnInit() {
	}

}
