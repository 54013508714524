import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {baseUrl} from '../url.model';
import {Observable} from 'rxjs';
import {FormBuilder, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';

@Injectable({providedIn: 'root'})
export class PrivacypolicyService {


	constructor(private http: HttpClient) {
	}

	getPrivacyPolicy(organisation): Observable<any> {
		return this.http.get<any>(baseUrl + 'privacyPolicy?orgId='+organisation);
	}

	newPrivacyPolicy(formdata,organisation): Observable<any> {
		return this.http.post<any>(baseUrl + 'privacyPolicy?orgId='+organisation, formdata, {observe: 'response'});
	}
	// organisationPrivacy

	newPrivacyPolicywithToken(formdata,organisation): Observable<any> {
		return this.http.post<any>(baseUrl + 'organisationPrivacy', formdata, {observe: 'response'});
	}
}
