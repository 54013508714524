import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material';
import {FormBuilder, FormControl} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationDialog} from '../confirmation-dialog/confirmation-dialog.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../core/reducers';
import {NgxPermissionsService} from 'ngx-permissions';
import {HttpParams} from '@angular/common/http';
import {RedemptionService} from './redemption.service';
import {debounceTime} from 'rxjs/operators';

@Component({
	selector: 'kt-redemption',
	templateUrl: './redemption.component.html',
	styleUrls: ['./redemption.component.scss']
})
export class RedemptionComponent implements OnInit {
	dataSource = new MatTableDataSource<any>();
	displayedColumns = ['firstname', 'lastname', 'email', 'actions'];
	search = new FormControl();
	pageSize = 10;
	page = 1;
	nev = '';
	collection: number;
	rendezesirany = 'asc';

	constructor(
		private fb: FormBuilder,
		private activatedRoute: ActivatedRoute,
		private cdr: ChangeDetectorRef,
		private modalService: NgbModal,
		private router: Router,
		private toastr: ToastrService,
		private confirmationDialogService: ConfirmationDialog,
		private store: Store<AppState>,
		private service: RedemptionService,
		private ngxPermissionService: NgxPermissionsService) {
	}

	ngOnInit() {
		this.search.valueChanges.pipe(debounceTime(500)).subscribe(str =>
			this.getData());
	}

	ngAfterViewChecked() {
		this.cdr.detectChanges();
	}
	lapozas(event) {
		this.page = event;
		this.getData();
	}

	getData() {
		let params = new HttpParams()
			.append('oldal', `${this.page - 1}`).append('meret', `${this.pageSize}`);
		if (this.search.value && this.search.value.length > 2) {
			params = params.append('szoveg', this.search.value);
		}
		this.service.getData(params).subscribe((res) => {
				this.collection = res.osszes;
				this.dataSource = res.elemek;
			}
		);
	}
}
