import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {baseUrl} from '../url.model';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class InactiveUsersService {
	constructor(private http: HttpClient) {

	}

	getInactiveUsers(params) {
		return this.http.get<any>(baseUrl + 'users/inactives', {params: params});
	}
	// basehost/users/deleteUsers -> Delete típusú végpont bodyban "ids" listában várom -> nem törlök most még szóval nyugodtan küldj be

	deleteInactiveUser(ids): Observable<any> {
		const options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
			}),
			body: {
				ids: ids
			},
		};
		return this.http.delete<any>(baseUrl + 'users/deleteUsers',options);
	}
}
