
//FRADI url model
// export const baseUrl =  'https://fradi.herokuapp.com/';
// export const imageEndPoint = 'https://fradi.herokuapp.com/images/voucherImages';


//MLSZ url model
export const baseUrl =  'https://mlsz.herokuapp.com/';
export const imageEndPoint = 'https://mlsz.herokuapp.com/images/voucherImages';

//TESZT
// export const baseUrl = 'http://localhost:8080/';
