import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {baseUrl} from '../../url.model';

@Injectable({providedIn: 'root'})
export class EventAttService {
	constructor(private http: HttpClient) {
	}

	attachableEvents(userId): Observable<any> {
		return this.http.get<any>(baseUrl + `users/${userId}/attachableEvents`);
	}

	attachUserToEvent(formdata):Observable<any>{
		return this.http.post<any>(baseUrl+ 'users/attachUserToEvent', formdata);
	}

}
