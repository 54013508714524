import {Component, Input, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PassesService} from '../../passes/passes.service';
import {FormBuilder} from '@angular/forms';
import {MyLinksService} from '../../my-links/my-links.service';
import {MatTableDataSource} from '@angular/material';
import {Events} from '../../users/new/new.component';
import {HttpParams} from '@angular/common/http';
import {EventsService} from '../events.service';

@Component({
	selector: 'kt-pass-group',
	templateUrl: './pass-group.component.html',
	styleUrls: ['./pass-group.component.scss']
})
export class PassGroupComponent implements OnInit {
	passes = new MatTableDataSource<any>();
	displayedColumns = ['checked', 'name'];
	eventPasses: any = {};
	collectionPasses: number;
	@Input() public eventID;
	constructor(private eventService: EventsService, private toastr: ToastrService, private translateService: TranslateService, private modal: NgbModal, private fb: FormBuilder, public activeModal: NgbActiveModal) {
	}

	ngOnInit() {
		this.getPasses();
	}
	checkPass($event, element) {
		if (!this.eventPasses.passes) {
			this.eventPasses.passes = [];
		}
		if ($event.checked) {
			this.eventPasses.passes.push(element.id);
		} else {
			this.eventPasses.passes = this.eventPasses.passes.filter(event => {
				return element.id !== event;
			});
		}
	}
	isSelectedPass(row) {

	}

	getPasses() {
		let params = new HttpParams();
		this.eventService.activePasses(this.eventID).subscribe(res => {
			this.collectionPasses = res.osszes;
			this.passes.data = res;
		});
	}

	send(){
		const formData={
			passIds: this.eventPasses.passes,
			eventId: parseInt(this.eventID)
		}

		this.eventService.choosenpassGenerate(formData).subscribe((res)=>{
			if(res.status==200){
				this.toastr.success(this.translateService.instant('ANONIMPASS.SUCCESS'),'');
				this.activeModal.close(true);
			}
			else{
				this.toastr.error(this.translateService.instant('ANONIMPASS.ERROR'),'');
			}
		})
	}
}
