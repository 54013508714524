// Angular
import { Component, OnInit } from '@angular/core';
// Layout
import { LayoutConfigService, ToggleOptions } from '../../../../core/_base/layout';
import {BehaviorSubject} from 'rxjs';
import {FormBuilder} from '@angular/forms';
import {OrganisationService} from '../../../pages/organisation/organisation.service';

@Component({
	selector: 'kt-header-mobile',
	templateUrl: './header-mobile.component.html',
	styleUrls: ['./header-mobile.component.scss']
})
export class HeaderMobileComponent implements OnInit {
	// Public properties
	headerLogo: string;
	asideDisplay: boolean;

	toggleOptions: ToggleOptions = {
		target: 'body',
		targetState: 'kt-header__topbar--mobile-on',
		togglerState: 'kt-header-mobile__toolbar-topbar-toggler--active'
	};


	available$ = new BehaviorSubject([]);
	myForm = this.fb.group({
		organisation: ['']
	});
	selectedOrganisation;

	constructor(private service: OrganisationService,	private organService: OrganisationService,public fb: FormBuilder,private layoutConfigService: LayoutConfigService) {
	}

	changeFruit(e) {
		const formData = {
			orgId: e.target.value
		};
		localStorage.setItem('organisation', e.target.value);

		this.organService.setCurrentOrganisation(formData).subscribe((res)=>{
			window.location.reload();
		})
	}
	getAvailableOrgs() {
		this.service.getavailableOrgs().subscribe(res => {
			this.available$.next(res);
		});
		this.service.getCurrentOrganisation().subscribe(res=>{
			localStorage.setItem('organisation', res);
			this.selectedOrganisation = res;
			if(this.selectedOrganisation){
				this.myForm.setValue({
					organisation: this.selectedOrganisation
				});
			}
		})
	}


	ngOnInit() {
		this.headerLogo = this.layoutConfigService.getStickyLogo();
		this.asideDisplay = this.layoutConfigService.getConfig('aside.self.display');
		this.getAvailableOrgs();
	}
}
