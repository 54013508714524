// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS
import { Observable, of, forkJoin } from 'rxjs';
import { map, catchError, mergeMap, tap } from 'rxjs/operators';
// Lodash
import { filter, some, find, each } from 'lodash';
// Environment
import { environment } from '../../../../environments/environment';
// CRUD
import { QueryParamsModel, QueryResultsModel, HttpUtilsService } from '../../_base/crud';
// Models
import { User } from '../_models/user.model';
import { Permission } from '../_models/permission.model';
import { Role } from '../_models/role.model';
import {Router} from '@angular/router';
import {baseUrl} from '../../../views/pages/url.model';


@Injectable({ providedIn : 'root'})
export class AuthService {
	private user = null;
    constructor(private http: HttpClient,
        private router: Router,
        private httpUtils: HttpUtilsService) { }

	login(formdata){
		return this.http.post<any>(baseUrl+'login', formdata);
	}

	newPassword(formdata){
		// basehost/newPassword
		return this.http.post<any>(baseUrl+'newPassword', formdata);
	}

	getUser() {
		return JSON.parse(localStorage.getItem('user'));
	}
	getFirstname() {
		return JSON.parse(localStorage.getItem('firstname'));
	}
	getLastname() {
		return JSON.parse(localStorage.getItem('lastname'));
	}
	setFirstname(firstname){
		localStorage.setItem('firstname', firstname);
	}
	setLastname(lastname){
		localStorage.setItem('lastname', lastname);
	}
	setUser(user) {
		localStorage.setItem('user', JSON.stringify(user));
	}

    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead
            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }

	logout(){
		this.user = null;
		localStorage.clear();
		this.router.navigate(['/auth/login']);

	}
}
