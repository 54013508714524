// Magyar
export const locale = {
	lang: 'en',
	data: {
		inviter_has_no_parking:'No parking assigned to the invitating person.',
		parking_slot_limit_reached:'There are not enough parking of this type.',
		parking_limit_only_for_supervisor_and_subsupervisor:'Parking can only be assigned to a supervisor, Sub-Supervisor.',
		event_pass_redundant_error:'The same event - pass limit cannot be saved twice.',
		inviter_has_no_pass_of_this_type:'No such pass assigned to the superior.',
		pass_limit_reached:'There are not enough passes of this type.',
		pass_limit_only_for_supervisor_and_subsupervisor:'You can only add a pass to a Supervisor / Sub-supervisor.',
		user_voucher_redundant_error:'Only one voucher per person can be assigned to an event.',
		wrong_old_password:'Incorrect old password.',
		invited_role_error:'You can not invite a user of this rank.',
		inviter_has_no_voucher_to_event:'There is no voucher for this event.',
		only_for_admin_and_supervisor_and_subsupervisor:'Only Admin, Supervisor and Sub-Supervisor can access this feature.',
		user_is_not_on_event:'User not on this event.',
		your_accreditation_is_arrived:'Your accreditation request for this event has been already made.',
		invitation_is_not_exist:'There is no such invitation in the system.',
		you_have_no_right_to_change_the_user_events:'You do not have permission to assign a user to another event.',
		user_has_no_entry_item:'This user has no entries (pass or wristband) for this event.',
		you_can_not_change_your__entry_item:'Own login can not be modified.',
		server_error:'Server error',
		unknown_entry_type:'Unknown access type.',
		parking_limit_end:'You have not enough limit for this parking.',
		unknown_youtube_id:'There is no such youtube help.',
		limit_not_exist:'There is no such limit.',
		parking_limit_is_not_enough:'There is no such parking limit.',
		parking_limit_error:'You have no limit from this parking.',
		user_is_exist_on_this_event:'The user is already registered for the event.',
		bracelet_limit_reached:'You have not enough wristband limit.',
		parking_limit_reached:'You have not enough parking limit.',
		tutelary_name_required:'Tutelary name required.',
		fullname_required:'Full name required.',
		birthplace_required:'Place of birth required.',
		birthdate_required:'Date of birth required.',
		date_format_error:'Date of birth format error.',
		nationality_required:'Nationality required.',
		idtype_required:'Personal document type required.',
		idnumber_required:'Document ID number required.',
		wrong_credentials:'Wrong username or password',
		server_side_error:'Server side error',
		new_password_time_out:'Expired email token',
		password_strength_error:'The password must contain a minimum of 8 characters, uppercase and lowercase letters, and or numbers.',
		only_a_developer_can_change_the_about_us: 'Only developer has permission.',
		unknown_bracelet_id: 'There is no such wristband.',
		wrong_password: 'Incorrect password.',
		password_required: 'Password protected content.',
		delete_unsuccessfull: 'Delete error.',
		email_unsuccessfull: 'Failed to send email.',
		pass_generate_error: 'Error generating.',
		unknown_pass_type: 'Unknown pass type.',
		token_error: 'A security error has occurred. Please log in again.',
		only_for_developer: 'This feature is only available to developer.',
		only_for_developer_and_admin: 'This feature is only available to developer and admin.',
		only_for_developer_and_admin_and_supervisor: 'This feature is only available to developer, admin, and supervisor.',
		only_for_developer_and_admin_and_cassa: 'This feature is only available to developer, admin, and cassa.',
		only_for_developer_and_admin_and_supervisor_subsupervisor: 'This feature is only available to developer, admin, supervisor, and subsupervisor.',
		only_for_cassa: 'This feature is only available to cassa.',
		only_developer_can_change_the_privacy_policy: 'Only Developer can modify the Terms and Conditions.',
		active_org_missing: 'No organization selected for this user.',
		unknown_parking_id: 'Unknown parking.',
		unknown_pass_id: 'Unknown pass.',
		unknown_event_id: 'There is no such event.',
		unknown_user_id: 'Unknown user.',
		unattached_pass_id: 'This pass ID doesn\'t belong to the event:',
		you_have_no_voucher_to_this_event: 'You have no voucher for this event',
		pass_limit_error: 'There is no amount of pass available for this event.',
		group_upload_pass_limit_error_1: 'There are not enough passes available. You have',
		group_upload_pass_limit_error_2: 'and you would like to upload:',
		amazon_connection_error: 'An error occurred while accessing the amazon server.',
		unknown_org_id: 'There is no such organization.',
		user_has_no_parking_slot: 'This user has no parking for this event.',
		pass_name_duplicated_error: 'You can\'t create multiple passes with the same name.',
		upload_error: 'File upload failed.',
		unknown_role_id: 'There is no such role.',
		already_used_bracelet_error: 'Already redeemed wristband.',
		already_used_pass_error: 'Already redeemed pass.',
		unknown_type: 'Unknown redemption type.',
		used_email_error: 'You already have a user with this email address.',
		only_admin_supvisor_andsubsupvisor_has_limit: 'Only admin, supervisor, and sub-supervisor have limits.',
		unknown_voucher_id: 'Unknown voucher ID.',
		download_error: 'Download failed.',
		not_available_for_user: 'User can\'t access this feature.',
		event_id_required: 'Event ID is required.',
		not_available_for_90_days_old_event: 'You are not eligible to search for events older than 90 days.',
		you_cant_change_your_own_limit: 'You can\'tt change your own limit.',
		user_has_no_pass_to_event: 'This user has no pass to the event.',
		user_not_invited: 'This user is not invited to this event.',
		bracelet_limit_end: 'There is not enough available wristband.',
		only_the_inviter_can_validate: 'Only the invitation person can confirm the invitee.',
		no_limit_for_this_event: 'There is no limit for this event.',
		pass_limit_end: 'There is not enough available pass.',
		parking_slot_limit_end: 'There is not enough available parking.',
		limit_required: 'Limit is required.',
		redundant_bracelet_limit_error: 'The same event - wristband limit can\'t be saved twice.',
		inviter_has_no_bracelet_limit: 'No such armband is assigned to the superior.',
		not_enough_bracelet: 'There is not enough wristband of this type:',
		redundant_parking_slot_error: 'The same event - parking limit can\'t be saved twice.',
		ERROR: {
			active_org_missing: 'Teszt itt kapom vissza hogy active_org_missing',
		},
		NEWPASSWORDMODAL: {
			SUCCESS: 'Password successfully changed.',
			TITLE: 'Set a new password',
			SEND: 'Send',
			CLOSE: 'Close',
			PLACEHOLDER: 'New password',
			LONGTEXT: 'Please set your new password'
		},
		PASSGROUP: {
			TITLE: 'Download groups of passes',
			SEND: 'Send',
			CLOSE: 'Close',
			SUCCESS: 'Ide kell szöveg',
			ERROR: 'Valami hiba történt'
		},
		ANONIMPASS: {
			SUCCESS: 'The download link sent to your e-mail address.',
			ERROR: 'és ide?'
		},
		LANGUAGESELECTOR: {
			HUN: 'Hungarian',
			ENG: 'English'
		},
		REGISTER: {
			BADLINK: 'Incorrect link',
			NATIONAL: 'Nationality',
			IDENTITYTYPE: 'Personal document type',
			ID: 'ID',
			NUMBER: 'Document ID number',
			PASSPORT: 'Passport',
			TITLE: 'Registration',
			ALREADY: 'I already have an account!',
			INFOTEXT: 'A beírt adatokat kizárólag\n' +
				'                              az\n' +
				'                              adott szolgáltatáshoz használjuk fel, bizalmasan kezeljük,\n' +
				'                              és\n' +
				'                              nem adjuk ki! A Regisztráció gombra kattintva elfogadod\n' +
				'                              Felhasználási feltételeinket, Adatkezelési szabályzatunkat\n' +
				'                              és a\n' +
				'                              cookie-k használatára vonatkozó szabályzatunkat.',
			REQUIRED: '* fields are required.',
			PERSONALDATA: 'Personal data:',
			FIRSTNAME: 'First Name',
			TUTELARY: 'Tutelary e-mail address is required',
			LASTNAME: 'Last Name',
			FIRSTNAMEERROR: 'The first name can not contain special characters, only hyphens, and minimum of 3 and a maximum of 15 characters long.',
			LASTNAMEERROR: 'The last name can not contain special characters, only hyphens, and minimum of 3 and a maximum of 20 characters long!',
			MOTHERNAME: 'Mothers name',
			BIRTHPLACE: 'Place of Birth',
			BIRTHDATE: 'Date of Birth',
			CLICKHEREPLACEHOLDER: 'Please click here to set your date of birth',
			DATATOACCESS: 'Login required:',
			EMAIL: 'E-mail address',
			EMAILRE: 'E-mail address (Confirm)',
			EMAILNOTMATCH: 'The two e-mails do not match!',
			EMAILERROR: 'The e-mail address can not contain accents or capital letters, minimum of 3 and a maximum of 15 characters long! Please enter a real and valid e-mail address!',
			PASSWORD: 'Password ',
			PASSWORDRE: 'Password (Confirm)',
			PASSWORDNOTMATCH: 'The two passwords do not match!',
			PASSWORDSTRENGTH: 'The password must be at least 8 characters long and must contain uppercase and lowercase letters and number.',
			REQUIREDIMAGE: 'Upload image (required*)',
			IMAGETOOLTIP: 'Please select the image!',
			//I declare that I have read and understood the Data Privacy Policy. You can read the Data Privacy Policy by clicking HERE.”
			PRIVACYPOLICYFIRST: 'I declare that I have read and understood the Data Privacy Policy.',
			PRIVACYPOLICYSECOND: 'You can read the Data Privacy Policy by clicking HERE.',
			PRIVACYPOLICYLOGIN: 'I declare that I have read and understood the Data Privacy Policy.',
			REGISTRATION: 'Register',
			PLEASELOGIN: 'Please login with your username and password:',
			ALREADYEMAIL: 'E-mail address',
			ALREADYEMAILERROR: 'The e-mail address may not contain accents or capital letters, minimum of 3 and a maximum of 15 characters long! Please enter a real and valid e-mail address!',
			LOGIN: 'Login',
			BACK: 'Back',
			TUTELARYEMAIL: 'Tutelary e-mail address'
		},
		AUTH: {
			INPUT: {
				EMAIL: 'E-mail',
				PASSWORD: 'Password'
			}
		},
		ABOUTUS: {
			SAVEABOUTUS: 'Save introductory text',
			ABOUTUS: 'About us',
			MODIFYSAVE: 'Save changes'
		},
		ARMBAND: {
			QUICKSEARCH: 'Quick search:',
			TITLE: 'Wristbands',
			BUTTONTOOLTIP: 'Add new wristband',
			BUTTONTITLE: 'Add',
			DATATABLECLEAR: 'No record found!',
			ARMBANDNAME: 'Wristband name',
			COLOR: 'Color',
			ACTION: 'Action',
			EDIT: 'Edit',
			DELETECOLOR: 'Delete colour'
		},
		NEWANDEDITARMBAND: {
			EDIT: 'Edit wristband',
			ADD: 'Add a new wristband',
			NAME: 'Wristband name: ',
			COLOR: 'Color: ',
			PLACEHOLDER: 'Click here to select the color',
			CANCEL: 'Cancel',
			PASSWORD: 'Password: ',
			CLOSE: 'Close',
			SAVE: 'Save'
		},
		LOGIN: {
			LOGIN: 'LOGIN',
			PLEASELOGIN: 'Please log in by entering your user details!',
			EMAIL: 'E-mail address',
			LANGUAGEDESC: 'Please select a language',
			HUN: 'Hungary',
			ENG: 'English',
			REQUIRED: 'Email address is required',
			WRONGEMAIL: 'Invalid email address format',
			PASSWORD: 'Password',
			PASSWORDREQUIRED: 'Password is required',
			FORGOTTENPASSWORD: 'Forgotten password',
			PRIVACYPOLICY: 'Data Management and Privacy Policy',
			ABOUTUS: 'About us',
			BUTTON: 'LOGIN'
		},
		FORGOTTENPASSWORD: {
			FORGOTTEN: 'Forgotten password',
			FORGOTTENPASS: 'Did you forgot your password?',
			LONGTEXT: 'Please enter your registered e-mail address and we will send you more information by e-mail!',
			NOTMATCH: 'We have sent the necessary informations to your e-mail address!',
			PLACEHOLDER: 'New e-mail address',
			NEWPASSWORD: 'Request new password'
		},
		LOGINABOUTUS: {
			ABOUTUS: 'About us',
			CLOSE: 'Close'
		},
		LOGINPRIVACY: {
			PRIVACY: 'Data Management and Privacy Policy',
			CLOSE: 'Close'
		},
		CONFIRMDIALOG: {
			NEEDPASSWORD: 'Password required to delete: ',
			CANCEL: 'Cancel'
		},
		CROPPERDIALOG: {
			TITLE: 'Crop image',
			ROTATE: 'Rotate',
			CANCEL: 'Cancel',
			CROP: 'Crop'
		},
		EVENTS: {
			QUICKSEARCH: 'Quick search:',
			EVENTS: 'Events',
			NEWEVENTS: 'Add a new event',
			ADD: 'Add',
			DATATABLECLEAR: 'No record found!',
			EVENTSNAME: 'Event name',
			EVENTSSTART: 'Start of event',
			EVENTSEND: 'End of event',
			ACTION: 'Action',
			EVENTSDATATABLEEDIT: 'Edit event data',
			DELETEEVENTS: 'Delete event',
			INVITELINK: 'Invitation links',
			CIRCLEEMAIL: 'Send a circular e-mail',
			DOWNLOADVOUCHER: 'Download voucher',
			INVITEVOUCHERDOWNLOAD: 'Download invitation voucher',
			INVITEPARKINGDOWNLOAD: 'Download parking voucher'
		},
		EVENTSDATA: {
			PASSGROUP: 'Download groups of passes',
			PARKINGVOUCHERDOWNLOAD: 'Download parking voucher',
			ANONIMPASS: 'Anonim pass',
			EDITEVENTS: 'Edit event',
			ALLPASSDOWNLOAD: 'Download all passes',
			EXPORT: 'Export',
			INVITELINK: 'Invitation links',
			RESET: 'Cancel',
			SAVE: 'Save',
			EVENTNAME: 'Event name: ',
			STARTDATE: 'Start date:: ',
			ENDDATE: 'End date: ',
			STARTTIME: 'Start time: ',
			ENDTIME: 'End time: ',
			PASSES: 'Passes',
			NARROW: 'Filter',
			DATATABLECLEAR: 'No record found!',
			ADDED: 'Added',
			PASS: 'Pass',
			ARMBANDS: 'Wristbands',
			ARMBAND: 'Wristband',
			PARKINGS: 'Parkings',
			PARKING: 'Parking',
			DOWNLOAD:'Download'
		},
		NEWEVENT: {
			EDITEVENT: 'Edit event',
			NEWEVENT: 'Add new event',
			EVENTNAME: 'Event name: ',
			STARTDATE: 'Start date: ',
			ENDDATE: 'End date: ',
			STARTTIME: 'Start time: ',
			ENDTIME: 'End time: ',
			ALLPASSDOWNLOAD: 'Download all passes',
			INVITESLINK: 'Invitation links',
			CLOSE: 'Close',
			MAKE: 'Create',
			SAVE: 'Save'
		},
		SCOPEMAIL: {
			CIRCLETITLE: 'Send circular e-mail! Event name:',
			SUBJECT: 'Subject: ',
			DESC: 'Body: ',
			CLOSE: 'Close',
			SEND: 'Send'
		},
		HELPER: {
			HELPER: 'Helper',
			NEWHELPERPLACEHOLDER: 'Add new help',
			ADD: 'Add',
			DATATABLECLEAR: 'No record found!',
			HELPERNAME: 'Name of manual',
			DESC: 'Description',
			ACTION: 'Action',
			EDIT: 'Edit',
			DELETEHELPER: 'Delete manual',
			PLAYHELPER: 'Play the guide'
		},
		NEWHELPER: {
			EDIT: 'Edit manual',
			NEWHELPER: 'Add a new manual',
			HELPERNAME: 'Name of manual: ',
			DESC: 'Description: ',
			URL: 'URL: ',
			CLOSE: 'Close',
			SAVE: 'Save'
		},
		PLAYHELPER: {
			HELPER: 'Helper'
		},
		INFORMATION: {
			TITLE: 'Information',
			ROWCLICK: 'Click on a row to get more information about the event.'
		},
		INFORMATIONDATA: {
			EVENTSNAME: 'Event name:',
			START: 'Start:',
			END: 'End:',
			ALLPASSTYPE: 'All pass types',
			ALLARMBANDTYPE: 'All wristband types',
			ALLPARKINGTYPE: 'All parking types',
			PASSES: 'Passes',
			ADDEDPERREDEMPTION: 'Issued / redeemed:',
			ARMBANDS: 'Wristbands',
			PARKINGS: 'Parkings'
		},
		INVITED: {
			QUICKSEARCH: 'Quick search:',
			INVITEDLIST: 'Invited list',
			DATATABLECLEAR: 'No record found!',
			USERNAME: 'Username',
			ACTIVATE: 'Activate selected',
			FIRSTNAME: 'First name',
			LASTNAME: 'Last name',
			EVENTSNAME: 'Event name',
			NICKNAME: 'Nickname',
			INVITERPERSON: 'Inviting person',
			ACTION: 'Action',
			ACTIVATETOOLTIP: 'Activation',
			DISCARD: 'Rejection',
			SETUPNICKNAME: 'Nickname setting'
		},
		INVITEDACTIVATE: {
			ACTIVATEUSER: 'activate user',
			ACTIVATE: 'Batch activation of users',
			LONGTEXT: 'Please select from the drop-down menu for which event the user will activate and the wristband or pass!',
			NOCHOOSE: 'Not selected',
			ARMBAND: 'Wristband',
			PASS: 'Pass',
			PARKING: 'Parking',
			NOTPARKING: 'No parking required',
			UPLOADEDIMAGE: 'Uploaded profile picture',
			WRONGIMAGEREPORT: 'Report image',
			ACTIVATEBUTTON: 'Activation'
		},
		ACTIVATEMODAL: {
			ACTIVATE: 'Activation',
			PLEASEADD: 'Please select the armband or pass!',
			EVENT: 'Event',
			NOCHOOSE: 'Not selected',
			PASS: 'Pass',
			ARMBAND: 'Wristband',
			PARKING: 'Parking',
			DATATABLECLEAR: 'No record found!',
			ADDED: 'Hozzáadott',
			ACTIVATEBUTTON: 'Activation'
		},
		NICKNAME: {
			EDIT: 'Edit nickname',
			ADD: 'Add a nickname',
			NICKNAME: 'Nickname ',
			CLOSE: 'Close',
			SAVE: 'Save'
		},
		MAILTEMPLATE: {
			PASSGENERATOR: 'Pass generator template setting',
			WRONGPROFILE: 'Wrong profile image template setting',
			DENIEDUSER: 'Reject email template setting',
			TEMPLATESETUP: 'Email template setting',
			REGEMAILTEDIT: 'Edit registration e-mail template',
			SUBJECT: 'Subject: ',
			BODY: 'Body: ',
			REEMAIL: 'Edit confirmation e-mail template',
			SAVE: 'Save',
			ALREADYMEMBER: 'Edit "I\'m already a user" e-mail template',
			PARKINGTEMPLATE: 'Edit parking e-mail template',
			CLOSE: 'Close',
			FORGOTTENPASSWORD: 'Edit forgotten password template'
		},
		MYLINKS: {
			TITLE: 'Links',
			NOTFOUND: 'This user has no voucher assigned to their event!',
			CLOSE: 'Close'
		},
		PARKING: {
			QUICKSEARCH: 'Quick search:',
			PARKINGS: 'Parkings',
			VOUCHERADDPLACEHOLDER: 'Add new voucher',
			ADD: 'Add',
			DATATABLECLEAR: 'No record found!',
			PARKINGNAME: 'Parking voucher name:',
			DESCRIPTION: 'Description',
			ACTIONS: 'Action',
			EDIT: 'Edit',
			DELETEPARKING: 'Cancel parking voucher',
			DOWNLOADPARKING: 'Download parking voucher'
		},
		NEWPARKING: {
			EDIT: 'Edit parking voucher',
			ADD: 'Add new parking voucher',
			PARKINGNAME: 'Parking voucher name: ',
			COLOR: 'Colour',
			CLICKHERE: 'Click here to select the color',
			CANCEL: 'Cancel',
			PASSWORD: 'Password: ',
			CLOSE: 'Close',
			SAVE: 'Save'
		},
		PASSES: {
			QUICKSEARCH: 'Quick search:',
			NEWPASS: 'Add new pass',
			TITLE: 'Passes',
			ADD: 'Add',
			DATATABLECLEAR: 'No record found!',
			NAME: 'Name',
			COLOR: 'Color',
			INFORMATION: 'Information',
			ACTIONS: 'Action',
			EDIT: 'Edit',
			PASSREVIEW: 'Pass preview',
			PASSDELETE: 'Delete pass'
		},
		NEWANDEDITPASS: {
			CODE: 'Code:',
			PASSEDIT: 'Edit pass',
			NEWPASSADD: 'Add new pass',
			PASSNAME: 'Pass name',
			COLOR: 'Colour',
			CLICKHERE: 'Click here to select the color',
			CANCEL: 'Cancel',
			TYPE: 'Type',
			TRADITIONAL: 'Traditional',
			SEKTOR: 'Sector',
			REQUIREDTITLE: 'At least one sector must be marked!',
			INFORMATION: 'Information',
			SECINFORMATION: 'Information (2)',
			BACKGROUND: 'Background',
			TOOLTIP: 'Please select the image!',
			PASSTEXTCOLOR: 'Pass text color: ',
			PROFILECOLOR: 'Profile field text color: ',
			CAPITALIZED: 'Capital text?: ',
			ORGANISATIONSEC: 'Organization name first line: ',
			ORGANISATIONSECOND: 'Organization name second line: ',
			ORGANISATIONTHREE: 'Organization name third line: ',
			ORGANISATIONFOUR: 'Organization name fourth line: ',
			PASSWORD: 'Password: ',
			CLOSE: 'Close',
			CREATE: 'Create',
			SAVE: 'Save'
		},
		PRIVACYPOLICYMENU: {
			TITLE: 'Data Management and Privacy Policy',
			SAVE: 'Save policy',
			MODIFYSAVE: 'Save changes'
		},
		PROFILE: {
			IDTYPE: 'Personal document type',
			IDNUMBER: 'Document ID number',
			NATIONALITY: 'Nationality',
			MODIFY: 'Change datas',
			OWNDATA: 'Datas:',
			FIRSTNAME: 'First name',
			FIRSTNAMERROR: 'The first name can not contain special characters, only hyphens, and minimum of 3 and a maximum of 15 characters',
			LASTNAME: 'Last name',
			LASTNAMEERROR: 'The last name can not contain special characters, only hyphens, and minimum of 3 and a maximum of 20 characters',
			MOTHERNAME: 'Mother\'s name',
			TUTELARY: 'Tutelary\'s name',
			BIRTHPLACE: 'Place of birth',
			BIRTHDATE: 'Date of birth',
			BIRTHTEXT: 'Please click time to set the date of birth',
			PROFILEIMAGE: 'Profile picture:',
			DELETEPROFILE: 'Delete profile',
			SAVEDATA: 'Save',
			PASSWORDMODIFY: 'Change password:',
			OLDPASSWORD: 'Old Password: *',
			NEWPASSWORD: 'New password: *',
			NEWPASSWORDAGAIN: 'New password again: *',
			NOTMATCH: 'The two passwords do not match!',
			PASSERROR: 'The password must be at least 8 characters long and must contain uppercase and lowercase characters as well as a number.',
			SAVEPASS: 'Change password'
		},
		REDEMPTION: {
			SEARCH: 'Please enter your search criterion:',
			REDEMPTION: 'Redemption',
			FIRSTNAME: 'First name',
			DATATABLECLEAR: 'No record found!',
			LASTNAME: 'Last name',
			EMAIL: 'E-mail',
			ACTIONS: 'Action'
		},
		REDEMPTIONDETAILS: {
			IDTYPE: 'Personal document type',
			IDNUMBER: 'Document ID number',
			NATIONALITY: 'Nationality',
			DATA: 'Datas',
			FIRSTNAME: 'First name ',
			LASTNAME: 'Last name',
			MOTHERNAME: 'Mother\'s name',
			BIRTHPLACE: 'Place of birth',
			BIRTHDATE: 'Date of birth',
			BIRTHTEXT: 'Please click time to set the date of birth',
			PASSESARMANDS: 'Available passes and wristbands',
			DATATABLECLEAR: 'No record found!',
			IMAGECHANGE: 'Image change',
			EVENTNAME: 'Event name',
			TYPE: 'Típus',
			ARMBANDSCOLOR: 'Wristband color',
			ACTION: 'Action',
			PASSDOWNLOAD: 'Download pass',
			REDEMPTION: 'Redemption'
		},
		REDEMPTIONCHECKDATA: {
			TITLE: 'Please check the datas',
			FIRSTNAME: 'First name',
			FIRSTNAMEERROR: 'The first name can not contain special characters, only hyphens, and minimum of 3 and a maximum of 15 characters',
			LASTNAME: 'Last name',
			LASTNAMEERROR: 'The last name can not contain special characters, only hyphens, and minimum of 3 and a maximum of 20 characters',
			MOTHERNAME: 'Mother\'s name',
			BIRTHPLACE: 'Place of birth',
			BIRTHDATE: 'Date of birth',
			BIRTHTEXT: 'Please click time to set the date of birth',
			CLOSE: 'Close',
			SAVEANDDOWNLOAD: 'Datas ready, download!'
		},
		USERIMPORT: {
			TITLE: 'Group registration',
			CHOOSEEVENT: 'Select event ',
			CHOOSETYPE: 'Select type: ',
			PASS: 'Pass',
			ARMBANDS: 'Wristband',
			CHOOSEPASS: 'Select pass',
			UNCHOOSED: 'Not selected',
			CHOOSEARMBANDS: 'Select wristband',
			CHOOSEPARKING: 'Select parking voucher',
			CHOOSEFILE: 'Select file',
			SEND: 'Send'
		},
		USERS: {
			RADIOBUTTONPASSINFORMATION: 'Pass information',
			BEVALTVA: 'Redeemed:',
			RADIOBUTTONYES: 'Yes',
			RADIOBUTTONNO: 'No',
			RADIOREQUIRED: 'Required field',
			RADIOBUTTONBRACELETINFORMATION: 'Bracelet information',
			DEVELOPER: 'Developer',
			ADMIN: 'Admin',
			SUPERVISOR: 'Supervisor',
			USERROLE: 'User',
			CASHIER: 'Cashier',
			ALSUPERVISOR: 'Sub-Supervisor',
			QUICKSEARCH: 'Quick search:',
			CHOOSEEVENT: 'Select event ',
			ALLEVENT: 'All events',
			ALLPERSON: 'All persons',
			EVENTERROR: 'Event is required',
			INVITERPERSON:'Select the person to invite',
			PASSSEARCH:'Select the pass',
			BRACELETSEARCH:'Select the armband',
			PARKINGSEARCH:'Select parking',
			PASSUSED:'Pass redeemed',
			BRACELETUSED:'Armband redeemed',
			USERS: 'Users',
			CREATE: 'Create',
			DATATABLECLEAR: 'No record found!',
			USER: 'User',
			FIRSTNAME: 'First name',
			LASTNAME: 'Last name',
			ROLE: 'Permission',
			ACTION: 'Action',
			USEREDIT: 'Edit user',
			DELETEUSER: 'Delete user'
		},
		EVENTATT: {
			TITLE: 'Add to event',
			PLEASECHOOSE: 'Please select wristband or pass!',
			EVENT: 'Event',
			UNCHOOSED: 'Not selected',
			SEARCHPLACEHOLDER: 'Találatok szűkítése',
			PASS: 'Pass',
			ARMBANDS: 'Wristband',
			CLOSE: 'Close',
			BUTTONTITLE: 'Add to event'
		},
		INVITESF: {
			INFILE: 'Send invitations from a file',
			CLOSE: 'Close',
			SEND: 'Send invitations'
		},
		USERSNEW: {
			INVITERPERSON: 'Inviting person',
			IDTYPE: 'Personal document type',
			IDNUMBER: 'Document ID number',
			NATIONALITY: 'Nationality',
			SUCCESSDENIED: 'The user was successfully banned from the event',
			BLOCK: 'Block!',
			CANCEL: 'Cancel',
			EDIT: 'Edit user',
			ACTIONS: 'Action',
			ADDNEW: 'Add new user',
			EVENTBUTTON: 'Add to event',
			PARKINGBUTTON: 'Parking',
			PASSBUTTON: 'PASS',
			PARKINGSBUTTON: 'Wristband',
			VOUCHERBUTTON: 'Voucher',
			SAVE: 'Save',
			CREATE: 'Create',
			USERNAME: 'Username',
			USERNAMEERROR: 'Please enter your e-mail address! The email address can not contain accents or uppercase characters, it can be a minimum of 3 and a maximum of 15 characters long! Please enter a real e-mail address!',
			PASSWORD: 'Password',
			ROLE: 'Permission',
			FIRSTNAME: 'First name',
			FIRSTNAMEERROR: 'The first name can not contain special characters, only hyphens, and minimum of 3 and a maximum of 15 characters',
			LASTNAME: 'Last name',
			LASTNAMEERROR: 'The last name cannot contain special characters, only hyphens, and minimum of 3 and a maximum of 20 characters',
			MOTHERNAME: 'Mother\'s name',
			BIRTHPLACE: 'Place of birth',
			BIRTHDATE: 'Date of birth',
			TUTELARY: 'Tutelary\'s name',
			BIRTHTEXT: 'Please click time to set the date of birth',
			NICKNAME: 'Nickname',
			IMAGE: 'Image',
			REQUIREDFIELD: 'Marked fields (*) are required.',
			DATATABLECLEAR: 'No record found!',
			EVENTNAME: 'Event name',
			TICKET: 'Ticket',
			MODIFYTICKET: 'Change ticket'
		},
		BITEM: {
			TITLE: 'Change ticket',
			MODIFY: 'User currently has:',
			PASS: 'Pass',
			ARMBAND: 'Wristband',
			PARKINGS: 'Parking',
			DELETEPARKING: 'Cancel parking voucher',
			CLOSE: 'Close',
			MODIFYBUTTON: 'Modify'
		},
		USERBRACELETS: {
			TITLE: 'User wristbands',
			EVENTNAME: 'Event name',
			NOTCHOOSE: 'Not selected',
			ARMBANDS: 'Wristband',
			QUANTITY: 'Quantity',
			FUNCTIONS: 'Functions',
			CLOSE: 'Close',
			SAVE: 'Save'
		},
		USERPARKING: {
			TITLE: 'User parking vouchers',
			EVENTNAME: 'Event name',
			NOTCHOOSE: 'Not selected',
			PARKINGS: 'Parking',
			QUANTITY: 'Quantity',
			FUNCTIONS: 'Functions',
			CLOSE: 'Close',
			SAVE: 'Save'
		},
		USERPASS: {
			TITLE: 'User passes',
			EVENTNAME: 'Event name',
			NOTCHOOSE: 'Not selected',
			PASSES: 'Pass-ok',
			QUANTITY: 'Quantity',
			FUNCTIONS: 'Functions',
			CLOSE: 'Close',
			SAVE: 'Save'
		},
		USERPERMISSION: {
			TITLE: 'Set permission',
			EVENTNAME: 'Event name',
			NOTCHOOSE: 'Not selected',
			VOUCHER: 'Voucher',
			FUNCTIONS: 'Functions',
			SAVEPERMI: 'Save permissions'
		},
		SETPERMIS: {
			NOTCHOOSE: 'Not selected',
		},
		VOUCHERS: {
			QUICKSEARCH: 'Quick search:',
			TITLE: 'Vouchers',
			NEWVOUCHERPLACE: 'Add a new voucher',
			ADD: 'Add',
			DATATABLECLEAR: 'No record found!',
			VOUCHERNAME: 'Voucher name',
			DESCRIPT: 'Description',
			ACTION: 'Action',
			EDIT: 'Edit',
			VOUCHERDELETE: 'Delete voucher',
			VOUCHERDOWNLOAD: 'Download voucher'
		},
		NEWVOUCHER: {
			EDIT: 'Edit voucher',
			NEW: 'Add new voucher',
			VOUCHERNAME: 'Voucher name: ',
			PASSWORD: 'Password: ',
			CLOSE: 'Close',
			SAVE: 'Save',
		},
		ORGANISATION: {
			QUICKSEARCH: 'Quick search:',
			TITLE: 'Organisation',
			MATTABLETITLE: 'Organisation name',
			ACTIONS: 'Action',
			ADDTOOLBAR: 'Add new Organisation',
			ADD: 'Add',
			DATATABLECLEAR: 'No record found!',
			ORGANISATIONDATATABLEEDIT: 'Edit Organisation',
			DELETEORGANISATION: 'Delete Organisation',
		},
		NEWORGANISATION: {
			NEWORGANISATION: 'Add new Organisation',
			EDITORGANISATION: 'Edit Organisation',
			ORGANISATIONNAME: 'Organisation name: ',
			CLOSE: 'Close',
			MAKE: 'Create',
			SAVE: 'Save',
		},
		ANONYMPASS: {
			TITLE: 'Anonim pass',
			CLOSE: 'Close',
			SEND: 'Send',
			LABEL: 'Passes',
			NAME: 'Name',
			QUANTITY: 'Quantity'
		},
		PARKINGVOUCHER: {
			TITLE: 'Download parking voucher',
			CLOSE: 'Close'
		},
		MENUCONFIG: {
			ORGANISATION: 'Organisations',
			INFORMATION: 'Informations',
			INACTIVEUSERS: 'Inactive users',
			EVENTS: 'Events',
			USERS: 'Users',
			VOUCHERS: 'Vouchers',
			PASSES: 'Passes',
			ARMBANDS: 'Wristbands',
			INVITED: 'Invited persons',
			PARKING: 'Parkings',
			ABOUTUS: 'About us',
			PRIVACYPOL: 'Data Management and Privacy Policy',
			FAQ: 'FAQ',
			GROUPREG: 'Group registration',
			REDEMPTION: 'Redemption'
		},
		MESSAGES: {
			CONFIRMDOWNLOAD:'Confirm download',
			ALLPASSDOWNLOAD:'Are you sure you want to download all passes?',
			DISSMISSDOWNLOAD:'Dissmiss download',
			ABOUTUSSAVE: 'You have successfully saved a description of about us',
			CONFIGPLACEHOLDER: 'Please enter a description!',
			DELETECONFIRM: 'Confirm deletion!',
			DELETEDESC: 'Are you sure you want to delete the selected record?',
			DISSMISS: 'Delete rejected',
			DELETEVOUCHER: 'Successfully deleted wristband',
			DELETEVENT: 'Successfully deleted event',
			DEFAULTERROR: 'An error occurred!',
			PDFGENERATE: 'PDF generation has started, if ready we inform you by e-mail',
			UPDATEEVENT: 'Event successfully updated!',
			ERROR: 'Error',
			CREATEEVENT: 'Event successfully created',
			CREATEERROR: 'Creating error',
			CREATEHELPER: 'Manual added successfully',
			UPDATEHELPER: 'Manual has been successfully updated',
			CONFIRMREJECTION: 'Confirm rejection!',
			REJECTUSER: 'Are you sure you want to reject the user?',
			SUCCESSREJECT: 'Successfully rejected user',
			INVITATIONSMUSTBE: 'All selected invitations must be for the same event!',
			REPORTIMAGESUCCESS: 'You have successfully reported the image!',
			REPORTSENDINGERROR: 'Report sendig error',
			SUCCESSACTIVEUSER: 'You have successfully activated the user',
			ERRORACTIVATE: 'Error activating',
			NICKNAMESUCCESSCREATE: 'Nickname successfully created',
			REGTEMPLATE: 'Registration template saved successfully',
			ERRORSAVING: 'Error in saving',
			CONFIRMTEMPLATE: 'Confirmation template saved successfully',
			SUCCESSDELETEORGANISATION: 'Successfully deleted Organisation',
			SUCCESSUPDATEORGANISATION: 'Organisation successfully updated!',
			SUCCESSDELETEPARKING: 'You have successfully deleted the parking voucher',
			SUCCESSCREATORGAN: 'Organisation successfully created',
			SUCCESSDELETEPASS: 'Successfully deleted pass',
			PASSADDEDSUCCESS: 'Pass added successfully',
			ADDINGPASSERROR: 'Adding pass error',
			UPDATEPASSERROR: 'Pass has been successfully updated',
			NOTSECTOR: 'Please select sector!',
			PLEASESPECIFYDATA: 'Please specify Data Management and Privacy Policy!',
			SUCCESSSAVEPOLICY: 'You have successfully saved the policy',
			CONFIRMPROFILEDELETE: 'Are you sure you want to delete your profile?',
			DATASUCCESSAVE: 'Data saved successfully',
			SUCCESSUPDATEIMAGE: 'You have successfully updated the image',
			IMAGEREQUIRED: 'Image is required!',
			SUCCESSREGI: 'Successfully registration',
			ERRORONREGI: 'Error at registering',
			ACTIVATEANDLOGIN: 'Successful login and invitation activation',
			SUCCESSUPLOADFILE: 'Successful file upload!',
			SUCCESSADDEDEVENT: 'You have successfully added to event',
			USERSUCADDED: 'User successfully added',
			USERUPDATESUCCES: 'User details updated successfully!',
			TICKETMODIFY: 'You have successfully updated the ticket',
			SUCDELPARKING: 'You have successfully deleted the parking voucher',
			SUCSAVE: 'Successfully saved',
			SETTINGERROR: 'Setting error',
			PERMISSIONSET: 'You have successfully updated the permission(s)',
			SUCDELETEVOUCHER: 'You have successfully deleted your voucher',
			VOUCHERPLACEHOLDER: 'Please enter the voucher description!',
			VOUCHERSUCCESSADDED: 'Voucher was successfully added',
			SUCCESSUPDATEVOUHCER: 'Voucher has been successfully updated'

		},
		USERPROFILE: {
			MYPROFILE: 'My profile',
			DATAMODIFY: 'Profile information and account settings',
			EMAIL: 'E-mail',
			EMAILTEMPLATEMODIFY: 'Email template setting',
			LOGOUT: 'Logout'
		},
		FOOTER: {
			FEEDBACK: 'System operation feedback: '
		},
		SPLASH: {
			LOADING: 'Please wait...'
		},
		MENUHORIZONTAL: {
			TITLE: 'Organisation: ',
			SELECTPLACEHOLDER: 'Please select organisation'
		},
		cookie: {
			header: "Cookies used on the website!",
			message: "This website uses cookies to ensure you get the best experience on our website.",
			link: "a",
			dismiss: "Accept"
		},
		INACTIVEUSERS:{
			TITLE: 'Inactive users',
			NAME: 'Name',
			EMAIL: 'Email',
			DELETE: 'Delete'
		}
	}
};
