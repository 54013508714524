import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {OrganisationService} from '../organisation.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
	selector: 'kt-new-organisation',
	templateUrl: './new-organisation.component.html',
	styleUrls: ['./new-organisation.component.scss']
})
export class NewOrganisationComponent implements OnInit {
	@Input() public id;
	form = this.fb.group({
		name: ['', Validators.required],
	});

	constructor(private translateService: TranslateService,private toastr: ToastrService, public activeModal: NgbActiveModal, private fb: FormBuilder, private modalService: NgbModal, private service: OrganisationService) {
	}

	ngOnInit() {
		if (this.id) {
			this.service.getOrganisation(this.id).subscribe(resp => {
				this.form.setValue({
					name: resp.name,
				});
			});
		}
	}

	save() {
		if (!this.id) {
			this.service.newOrganisation(this.form.getRawValue()).subscribe((res) => {
				if (res) {
					this.toastr.success(this.translateService.instant('MESSAGES.SUCCESSCREATORGAN'), '');
					this.activeModal.close(true);
				} else {
					this.toastr.error(this.translateService.instant('MESSAGES.DEFAULTERROR'), '');
				}
			}, (error) => {
				this.toastr.error(error.error.message, this.translateService.instant('MESSAGES.ERROR'));
			});
		}else{
			this.service.modifyOrganisation(this.id, this.form.getRawValue()).subscribe((res) => {
					if (res.status == 200) {
						this.toastr.success(this.translateService.instant('MESSAGES.SUCCESSUPDATEORGANISATION'), '');
						this.activeModal.close(true);
					} else {
						this.toastr.error(this.translateService.instant('MESSAGES.DEFAULTERROR'), '');
					}
				}, (error => {
					this.toastr.error(error.error.message, this.translateService.instant('MESSAGES.ERROR'));
				})
			);
		}
	}

}
