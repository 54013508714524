import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {HelperService} from '../helper.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
	selector: 'kt-new-helper',
	templateUrl: './new-helper.component.html',
	styleUrls: ['./new-helper.component.scss']
})
export class NewHelperComponent implements OnInit {
	@Input() public id;
	form = this.fb.group({
		name: ['', Validators.required],
		description: ['', Validators.required],
		link: ['', Validators.required],
		visibleByAdmin: [],
		visibleBySupervisor: [],
		visibleBySubSupervisor: [],
		visibleByUser: [],
		visibleByCassa: [],
	});

	constructor(private translateService: TranslateService,private service: HelperService, public activeModal: NgbActiveModal, private toastr: ToastrService, private fb: FormBuilder) {
	}

	ngOnInit() {
		if (this.id) {
			this.service.getHelp(this.id).subscribe(resp => {
				this.form.setValue({
					name: resp.name,
					description: resp.description,
					link: resp.link,
					visibleByAdmin: resp.visibleByAdmin,
					visibleBySupervisor: resp.visibleBySupervisor,
					visibleBySubSupervisor: resp.visibleBySubSupervisor,
					visibleByUser: resp.visibleByUser,
					visibleByCassa: resp.visibleByCassa,
				});
			});
		}
	}


	save() {
		if (!this.id) {
			this.service.newHelper(this.form.getRawValue()).subscribe((res) => {
				if (res.status == 200) {
					this.toastr.success(this.translateService.instant('MESSAGES.CREATEHELPER'), '');
					this.activeModal.close(true);
				} else {
					this.toastr.error(this.translateService.instant('MESSAGES.DEFAULTERROR'), '');
				}
			}, (error) => {
				this.toastr.error(error.error.message, this.translateService.instant('MESSAGES.ERROR'));
			});
		} else {
			this.service.modifyHelper(this.id, this.form.getRawValue()).subscribe((res) => {
					if (res.status == 200) {
						this.toastr.success(this.translateService.instant('MESSAGES.UPDATEHELPER'), '');
						this.activeModal.close(true);
					} else {
						this.toastr.error(this.translateService.instant('MESSAGES.DEFAULTERROR'), '');
					}
				}, (error => {
					this.toastr.error(error.error.message,  this.translateService.instant('MESSAGES.ERROR'));
				})
			);
		}
	}
}
