import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {MyLinksService} from '../../my-links/my-links.service';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CropperDialogComponent, dataURLtoFile} from '../../cropper-dialog/cropper-dialog.component';
import {BehaviorSubject} from 'rxjs';
import {HttpParams} from '@angular/common/http';
import {PassesService} from '../../passes/passes.service';
import {EventsService} from '../events.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

const url = '/assets/media/add_photo.png';

@Component({
	selector: 'kt-anonym-pass',
	templateUrl: './anonym-pass.component.html',
	styleUrls: ['./anonym-pass.component.scss']
})

export class AnonymPassComponent implements OnInit {
	@Input() public eventID;
	url = url;
	form = this.fb.group({
		quantity: ['', Validators.required],
		name: ['', Validators.required]
	});
	available$ = new BehaviorSubject([]);
	file: string | File = null;
	selectedPass;

	constructor(private eventsService: EventsService,private toastr: ToastrService,private translateService: TranslateService, private modal: NgbModal, private passService: PassesService, private fb: FormBuilder, private myLinkService: MyLinksService, public activeModal: NgbActiveModal) {
	}

	ngOnInit() {
		this.getPasses();
	}

	getPasses() {
		let params = new HttpParams();
		// this.passService.getPasses(params).subscribe(res => {
		// 	this.available$.next(res.elemek);
		// });
		this.passService.getPassesNew(this.eventID).subscribe(res => {
			this.available$.next(res);
		});
	}

	openCropModal(event) {
		const ref = this.modal.open(CropperDialogComponent, {
			ariaLabelledBy: 'modal-basic-title',
			size: 'lg',
			backdrop: 'static',
			keyboard: false
		});
		ref.componentInstance.source = event;
		ref.result.then(result => {
			if (result) {
				this.url = result;
				this.file = dataURLtoFile(result, 'file.png');
			}
		});
	}

	save() {
		const formData = new FormData();
		formData.append('eventId', this.eventID);
		formData.append('image', this.file);
		formData.append('passId', this.selectedPass.id);
		formData.append('count', this.form.controls['quantity'].value);
		formData.append('name', this.form.controls['name'].value);
		this.eventsService.anonymPass(formData).subscribe((res) => {
			if(res.status==200){
				this.toastr.success(this.translateService.instant('ANONIMPASS.SUCCESS'),'');
				this.activeModal.close(true);
			}
			else{
				this.toastr.error(this.translateService.instant('ANONIMPASS.ERROR'),'');
			}
		});
	}
}
